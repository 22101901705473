import React, { useEffect, useState } from "react";
import { Button, Form, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import {
  getAdminWalletStart,
  sellTokenStart,
} from "../store/actions/BuyAction";
import CommonCenterLoader from "../components/helper/CommonCenterLoader";
import { useDropzone } from "react-dropzone";
import { setTokenSellData } from "../store/actions/BuyAction";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getErrorNotificationMessage } from "../components/helper/ToastNotification";
import { updateSwapData } from "../store/actions/UserAction";
import { getBankAccountStart } from "../store/actions/BankAccountAction";

const SwapWalletAddressSec = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("buy_index");
  const tokenSellData = useSelector((state) => state.buy.tokenSellData);
  const adminWallet = useSelector((state) => state.buy.adminWallet);
  const bankAccountList = useSelector((state) => state.bankAccount.bankAccount);
  const updateTokenSellData = (data) => dispatch(setTokenSellData(data));
  const tokenSell = useSelector((state) => state.buy.tokenSell);
  const swapData = useSelector(state => state.user.swapData);
  const setSwapData = data => dispatch(updateSwapData(data));
  const currencies = useSelector(state => state.buy.currencies);

  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    user_bank_account_id: Yup.string().required(t("bank_account.required")),
    transaction_hash: Yup.string().required(t("transaction.required"))
      .min(3, t("transaction.invalid")),
  });

  const validationAddressSchema = Yup.object().shape({
    user_wallet_address: Yup.string().required(t("user_wallet_address.required")),
    transaction_hash: Yup.string().required(t("transaction.required"))
      .min(3, t("transaction.invalid")),
  });

  useEffect(() => {
    dispatch(getAdminWalletStart());
    dispatch(getBankAccountStart());

  }, []);

  useEffect(() => {
    if (adminWallet.data && Object.keys(adminWallet.data).length > 0) {
      setSwapData({
        ...swapData,
        admin_crypto_account_id: adminWallet.data.admin_crypto_account.admin_crypto_account_id,
      });
      updateTokenSellData({
        ...tokenSellData,
        admin_bank_account_id: adminWallet.data.admin_crypto_account.admin_crypto_account_id,
      })
    }
  }, [adminWallet]);

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const images = files.map((file, key) => (
    <div className="preview-added-image" key={key}>
      <Button
        variant="link"
        className="preview-cancel"
        onClick={() => setFiles([])}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#fff"
          viewBox="0 0 24 24"
        >
          <path d="M18 6a1 1 0 00-1.414 0L12 10.586 7.414 6A1 1 0 006 6a1 1 0 000 1.414L10.586 12 6 16.586A1 1 0 006 18a1 1 0 001.414 0L12 13.414 16.586 18A1 1 0 0018 18a1 1 0 000-1.414L13.414 12 18 7.414A1 1 0 0018 6z"></path>
        </svg>
      </Button>
      <Image
        className="product-preview"
        key={file.name}
        src={file.preview}
        alt="image-preview"
      />
    </div>
  ));

  const onSubmit = (values) => {
    if (files.length > 0) {
      if (currencies.data.currencies.find((item) => item.currency_code == tokenSellData.to_currency).currency_type == "forex") {
        dispatch(sellTokenStart({
          ...tokenSellData,
          transaction_hash: values.transaction_hash,
          user_bank_account_id: values.user_bank_account_id,
          file: files[0],
        }))
      } else {
        dispatch(sellTokenStart({
          ...tokenSellData,
          transaction_hash: values.transaction_hash,
          user_wallet_address: values.user_wallet_address,
          file: files[0],
        }))
      }

    }
    else {
      getErrorNotificationMessage(t("please_upload_payment_screenshot"))
    }
  };

  useEffect(() => {
    if (!skipRender && !tokenSell.loading && Object.keys(tokenSell.data).length > 0) {
      updateTokenSellData({
        ...tokenSellData,
        step: 3,
      });
    }
    setSkipRender(false)
  }, [tokenSell]);


  return (
    <>

      {adminWallet.loading ? (
        <CommonCenterLoader />
      ) : Object.keys(adminWallet.data).length > 0 ? (
        <div className="bank-account-details-sec">
          <div className="bank-account-details">
            <div className="back-arrow-btn-sec" onClick={() => updateTokenSellData({ ...tokenSellData, step: 1 })}>
              <Image
                src={window.location.origin + "/img/exchange/back-arrow-icon.svg"}
                className="back-arrow-btn"
              />
              <h4>{t("sell_crypto")} </h4>
            </div>
          </div>
          <hr />
         {adminWallet.data.admin_crypto_account.qr_code ? <div className="admin-wallet-qr-frame">
          <Image
                src={adminWallet.data.admin_crypto_account.qr_code}
                className="qr-code"
              />
          </div> : ""}
          <Formik
            initialValues={currencies.data.currencies.find((item) => item.currency_code == tokenSellData.to_currency).currency_type == "forex" ? {
              transaction_hash: "",
              user_bank_account_id: "",
            }
              :
              {
                transaction_hash: "",
                user_wallet_address: "",
              }
            }
            validationSchema={currencies.data.currencies.find((item) => item.currency_code == tokenSellData.to_currency).currency_type == "forex" ?
              validationSchema
              :
              validationAddressSchema
            }
            onSubmit={(values) => onSubmit(values)}
          >
            {({ touched, errors, setFieldValue, values }) => (
              <FORM className="exchange-form">
                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("admin_wallet_address")}
                  </Form.Label>
                  <Field
                    className="form-control account-address"
                    type="text"
                    value={adminWallet.data.admin_crypto_account.wallet_address}
                    disabled
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{t("transaction.label")}</Form.Label>
                  <Field
                    className="form-control"
                    type="text"
                    placeholder={t("transaction.placeholder")}
                    name="transaction_hash"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="transaction_hash"
                    className="text-danger text-right"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{t("payment_picture")}</Form.Label>
                  <div
                    {...getRootProps()}
                    style={{ border: "1px dashed #f7f7f7" }}
                  >
                    <input {...getInputProps()} />
                    {files.length <= 0 && (
                      <div className="add-product-upload-box">
                        <Image
                          src={window.location.origin + "/img/upload.png"}
                          className="upload-icon"
                        />
                        <p>
                          {t("upload_your_payment_screenshot_here")}
                          <br /> <span> {t("accept_only_jpeg_jpg_png")}</span>
                        </p>
                      </div>
                    )}
                    <div>{images}</div>
                  </div>
                </Form.Group>
                {currencies.data.currencies.find((item) => item.currency_code == tokenSellData.to_currency).currency_type == "forex" ?
                  <Form.Group controlId="formBasicEmail" className="mb-3">
                    <Form.Label>
                      {t("bank_account.label")}
                      <span>*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="user_bank_account_id"
                      onChange={(e) =>
                        setFieldValue(
                          "user_bank_account_id",
                          e.target.value
                        )
                      }
                    >
                      <option value="">{t("bank_account.placeholder")}</option>
                      {bankAccountList.loading ?
                        ""
                        : Object.keys(bankAccountList.data).length > 0 ?
                          bankAccountList.data.bank_accounts.map(
                            (account, i) => (
                              <option key={i} value={account.id}>
                                {account.account_holder_name} -{" "}
                                {account.account_number}
                              </option>
                            )
                          )
                          :
                          null
                      }
                    </Form.Select>
                    <ErrorMessage
                      component={"div"}
                      name="user_bank_account_id"
                      className="text-danger text-right"
                    />
                  </Form.Group>
                  :
                  <Form.Group className="mb-3" >
                    <Form.Label>{t("your_wallet_address")}</Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("your_wallet_address")}
                      name="user_wallet_address"
                    />
                    <ErrorMessage
                      component={"div"}
                      name="user_wallet_address"
                      className="text-danger text-right"
                    />
                  </Form.Group>
                }
                <div className="exchange-btn-sec">
                  <Button
                    className="default-btn"
                    type="submit"
                    disabled={tokenSell.buttonDisable}
                  >
                    {tokenSell.buttonDisable ? t("loading") : t("continue")}
                  </Button>
                  
                </div>
              </FORM>
            )}
          </Formik>
        </div>
      ) : (
        <div className="no-bank-details-sec exchange-btn-sec">
          <Image
            src={window.location.origin + "/img/no-bank-found.png"}
            className="no-data-found-img"
          />
          <p>{t("no_wallet_details_found")}</p>
          <Button
            className="default-btn w-100"
            onClick={() => dispatch(getAdminWalletStart())}
          >
            {t("retry")}
          </Button>
        </div>
      )}
    </>
  );
};

export default SwapWalletAddressSec;
