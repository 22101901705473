import React, { useEffect, useState } from "react";
import {
  Container,
  Tab,
  Tabs,
  Image,
  Button,
  Nav,
  Row,
  Col,
} from "react-bootstrap";
import "../Wallet/Wallet.css";
import { connect } from "react-redux";
import WithDrawWalletModal from "./WithDrawWalletModal";
import WithDrawHistory from "../WithDraw/WithDrawHistory";
import WalletTransaction from "./WalletTransaction";
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { fetchSingleWalletViewStart } from "../../store/actions/WalletAction";
import { useTranslation } from "react-multi-lang";
import DepositModal from "./DepositModal";
import SwapModalNew from "./SwapModalNew";
import SwapModal from "./SwapModal";
import WithdrawCryptoModal from "./WithdrawCryptoModal";
import { useSearchParams } from "react-router-dom";
import CustomLazyLoad from "../helper/CustomLazyLoad";

const WalletIndex = (props) => {
  const t = useTranslation("wallet_index");
  const [activeTab, setActiveTab] = useState("transactions");
  const params = useParams();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [cryptoModalShow, setCryptoModalShow] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams("");
  const [depositModal, setDepositModal] = useState(false);
  const [swapModal, setSwapModal] = useState(false);
  const [tokenType, setTokenType] = useState("");
  useEffect(() => {
    props.dispatch(
      fetchSingleWalletViewStart({
        user_wallet_id: params.user_wallet_id,
      })
    );
  }, [params.user_wallet_id]);

  const [swapModalNew, setSwapModalNew] = useState(false);

  return (
    <>
      <div className="wallet-wrapped">
        <Container>
          {props.wallet.loading ? (
            <>
              <div className="feature-wallter-back-frame mb-2">
                <Skeleton count={1} width={150} height={24} />
              </div>
              <div className="feature-wallet-grid">
                {[...Array(3)].map((i, key) => (
                  <Skeleton key={key} width={370} height={243} />
                ))}
              </div>
            </>
          ) : Object.keys(props.wallet.data).length > 0 ? (
            <div className="new-feature-wallet-wrapped">
              <div className="feature-wallet-card-frame">
                <div className="feature-wallter-back-frame">
                  <Button
                    className="back-btn"
                    type="submit"
                    onClick={() => navigate(-1)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#333"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M5 12l14 0" />
                      <path d="M5 12l6 6" />
                      <path d="M5 12l6 -6" />
                    </svg>
                  </Button>{" "}
                  <h2>
                    {" "}
                    {props.wallet.data.user_wallet.currency_type == "crypto"
                      ? t("crypto_currency")
                      : t("fiat_currency")}
                  </h2>
                </div>
                <div className="feature-wallet-grid">
                  <div className="feature-wallet-card theme-back-avater">
                    <div className="feature-wallet-card-icon">
                      <CustomLazyLoad
                        src={window.location.origin + "/img/icon/new/coin.png"}
                      />
                    </div>
                    <div className="feature-wallet-card-info">
                      <p>
                        {t("total")}{" "}
                        {`${
                          props.wallet.data.user_wallet.currency_type ==
                          "crypto"
                            ? t("token_balance")
                            : t("amount_balance")
                        }`}
                      </p>
                      <h3>{props.wallet.data.user_wallet.total}</h3>
                    </div>
                    <div className="feature-wallet-card-action">
                      {props.wallet.data.user_wallet.currency_type ==
                      "crypto" ? (
                        <>
                          <Button
                            onClick={() => setCryptoModalShow(true)}
                            disabled={
                              props.wallet.data.user_wallet
                                .remaining_original == 0 &&
                              props.profile.data.kyc_verified == 0
                            }
                            className="new-primary-btn"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#fff"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M17 7l-10 10" />
                              <path d="M8 7l9 0l0 9" />
                            </svg>
                            <span>{t("withdraw")}</span>
                          </Button>
                          <Button
                            onClick={() => {
                              setTokenType(
                                props.wallet.data.user_wallet.currency_code
                              );
                              setDepositModal(props.wallet.data.user_wallet);
                            }}
                            className="new-secondary-btn"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#000"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M17 7l-10 10" />
                              <path d="M16 17l-9 0l0 -9" />
                            </svg>
                            <span>{t("deposit")}</span>
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            onClick={() => setModalShow(true)}
                            disabled={
                              props.wallet.data.user_wallet
                                .remaining_original == 0
                            }
                            className="new-primary-btn"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#fff"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M17 7l-10 10" />
                              <path d="M8 7l9 0l0 9" />
                            </svg>
                            <span>{t("withdraw")}</span>
                          </Button>
                          <Button
                            // onClick={() => navigate("/buy")}
                            onClick={() => {
                              setTokenType(
                                props.wallet.data.user_wallet.currency_code
                              );
                              setDepositModal(props.wallet.data.user_wallet);
                            }}
                            className="new-secondary-btn"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#000"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M17 7l-10 10" />
                              <path d="M16 17l-9 0l0 -9" />
                            </svg>
                            <span>{t("deposit")}</span>
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="feature-wallet-card white-back-avater">
                    <div className="feature-wallet-card-icon">
                      <CustomLazyLoad
                        src={window.location.origin + "/img/icon/new/coin.png"}
                      />
                    </div>
                    <div className="feature-wallet-card-info">
                      <p>
                        {t("remaining")}{" "}
                        {`${
                          props.wallet.data.user_wallet.currency_type ==
                          "crypto"
                            ? t("tokens")
                            : t("amount")
                        }`}
                      </p>
                      <h3>{props.wallet.data.user_wallet.remaining}</h3>
                    </div>
                    <div className="feature-wallet-card-action">
                      {props.wallet.data.user_wallet.currency_type ==
                      "crypto" ? (
                        <Button
                          onClick={() => setSwapModalNew(true)}
                          disabled={
                            props.wallet.data.user_wallet.remaining_original ==
                            0
                          }
                          className="new-primary-btn"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#fff"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M3 17h5l1.67 -2.386m3.66 -5.227l1.67 -2.387h6" />
                            <path d="M18 4l3 3l-3 3" />
                            <path d="M3 7h5l7 10h6" />
                            <path d="M18 20l3 -3l-3 -3" />
                          </svg>
                          <span>{t("swap")}</span>
                        </Button>
                      ) : (
                        <Button
                          onClick={() => setSwapModalNew(true)}
                          disabled={
                            props.wallet.data.user_wallet.remaining_original ==
                            0
                          }
                          className="new-primary-btn"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#fff"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M3 17h5l1.67 -2.386m3.66 -5.227l1.67 -2.387h6" />
                            <path d="M18 4l3 3l-3 3" />
                            <path d="M3 7h5l7 10h6" />
                            <path d="M18 20l3 -3l-3 -3" />
                          </svg>
                          <span>{t("swap")}</span>
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="feature-wallet-card white-back-avater">
                    <div className="feature-wallet-card-icon">
                      <CustomLazyLoad
                        src={window.location.origin + "/img/icon/new/coin.png"}
                      />
                    </div>
                    <div className="feature-wallet-card-info">
                      <p>
                        {t("used")}{" "}
                        {`${
                          props.wallet.data.user_wallet.currency_type ==
                          "crypto"
                            ? t("tokens")
                            : t("amount")
                        }`}
                      </p>
                      <h3>{props.wallet.data.user_wallet.used}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </Container>

        <div className="wallet-full-transaction-frame">
          <Container>
            <div className="wallet-full-transaction-table">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col sm={12} className="">
                    <Nav
                      onClick={() => {
                        setSearchParams({});
                      }}
                      variant="pills"
                    >
                      <Nav.Item>
                        <Nav.Link
                          eventKey="first"
                          onClick={() => setActiveTab("transactions")}
                        >
                          {t("transactions")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="second"
                          onClick={() => setActiveTab("withdrawals")}
                        >
                          {t("withdraw_history")}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={12}>
                    {activeTab == "transactions" && (
                      <div className="payment-tabs-content">
                        <WalletTransaction
                          activeTab={activeTab}
                          currency_type={
                            !props.wallet.loading &&
                            Object.keys(props.wallet.data).length > 0
                              ? props.wallet.data.user_wallet.currency_type ==
                                "crypto"
                                ? "crypto"
                                : "forex"
                              : "forex"
                          }
                        />
                      </div>
                    )}
                    {activeTab === "withdrawals" && (
                      <div className="payment-tabs-content">
                        <WithDrawHistory
                          activeTab={activeTab}
                          currency_type={
                            !props.wallet.loading &&
                            Object.keys(props.wallet.data).length > 0
                              ? props.wallet.data.user_wallet.currency_type ==
                                "crypto"
                                ? "crypto"
                                : "forex"
                              : "forex"
                          }
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </Container>
        </div>
      </div>

      {/* modals  */}
      {modalShow && (
        <WithDrawWalletModal
          show={modalShow}
          user_wallet_id={params.user_wallet_id}
          currency={props.wallet.data.user_wallet.currency_code}
          onHide={() => setModalShow(false)}
        />
      )}
      {depositModal && (
        <DepositModal
          show={depositModal}
          token={props.wallet.data?.user_wallet?.currency_code}
          tokenType={tokenType}
          user_wallet_id={params.user_wallet_id}
          onHide={() => setDepositModal(false)}
        />
      )}
      {swapModal && (
        <SwapModal
          show={swapModal}
          user_wallet_id={params.user_wallet_id}
          onHide={() => setSwapModal(false)}
        />
      )}
      {cryptoModalShow && (
        <WithdrawCryptoModal
          show={cryptoModalShow}
          user_wallet_id={params.user_wallet_id}
          onHide={() => setCryptoModalShow(false)}
        />
      )}
      {swapModalNew && (
        <SwapModalNew
          show={swapModalNew}
          onHide={() => setSwapModalNew(false)}
          user_wallet_id={params.user_wallet_id}
        />
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  wallet: state.wallet.singleWallet,
  profile: state.user.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(mapStateToPros, mapDispatchToProps)(WalletIndex);
