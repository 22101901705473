import React, { useState } from "react";
import {Container, Row, Col, Accordion, Image } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";

const CryptoLiveData = (props) => {
  const t = useTranslation("landing_page")

  return (
    <>
      <div className="crypto-live-data-sec sm-padding">
        <Container>
            <Row className="justify-content-md-center">
                <Col md={8} lg={9} className="text-center">
                    <div className="section-title">
                        <h2 data-aos="fade-up">{t("crypto_live_data")}</h2>
                    </div>
                </Col>
            </Row>
            <div className="crypto-live-data-box" data-aos="fade-up">
            <Row className="align-items-center">
                <Col md={12} xl={12}>
                <iframe
            src="https://www.tradingview-widget.com/embed-widget/crypto-mkt-screener/?locale=in#%7B%22width%22%3A1000%2C%22height%22%3A490%2C%22defaultColumn%22%3A%22overview%22%2C%22screener_type%22%3A%22crypto_mkt%22%2C%22displayCurrency%22%3A%22USD%22%2C%22colorTheme%22%3A%22dark%22%2C%22market%22%3A%22crypto%22%2C%22enableScrolling%22%3Atrue%2C%22utm_source%22%3A%22in.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22cryptomktscreener%22%2C%22page-uri%22%3A%22in.tradingview.com%2Fwidget%2Fcrypto-mkt-screener%2F%22%7D"
            style={{width: "100%", height: "100%", margin: "0 !important", padding: "0 !important"}} frameborder="0"
            allowtransparency="true" scrolling="no"></iframe>
                </Col>
            </Row>
            </div>
        </Container>
      </div>
    </>
  );
};

export default CryptoLiveData;
