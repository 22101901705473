import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutStart } from "../../../store/actions/BuyAction";
const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tokenBuy = useSelector((state) => state.buy.logout);
  const logout = () => {
    dispatch(logoutStart());
  };

  return { logout };
};

export default useLogout;
