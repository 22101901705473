import React, { useState, useEffect } from "react";
import { Image, Row, Col, Tab, Nav, Button } from "react-bootstrap";
import "./Exchange.css";
import { Link } from "react-router-dom";
import CounterTimer from "../helper/CounterTimer";
import { useTranslation } from "react-multi-lang";
const BuyProcessing = (props) => {
const t = useTranslation("buy_processing")

  return (
    <>
      <div className="buy-crypto-sec">
        <Row className="justify-content-md-center">
          <Col md={5}>
            <div className="exchange-box">
              <Tab.Container id="left-tabs-example" defaultActiveKey="buy-crypto">
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="buy-crypto">{t("buy")}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="swap-crypto">{t("swap")}</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Tab.Container>
              <div className="buy-processing-img-sec">
                <Image
                  src={window.location.origin + "/img/buy/payment-processing.gif"}
                  className="buy-processing-img"
                />
              </div>
              <div className="payment-processing-countdown-card">
                <div className="payment-text">
                {t("payment")}
                </div>
                <CounterTimer
                  time={480000}
                />
              </div>
              <div className="exchange-btn-sec">
                <Button className="default-btn" onClick={() => props.setStep(5)}>
                {t("continue")}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BuyProcessing;
