import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { singleRecipientStart } from "../../store/actions/RecipientAction";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../helper/NoDataFound";
import { countriesData } from "./CountriesList";
import { useTranslation } from "react-multi-lang";

const ViewRecipientDetail = () => {
  const t = useTranslation("recipient.recipient_view");
  const dispatch = useDispatch();
  const singleRecipient = useSelector(
    (state) => state.recipient.singleRecipient
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [recipient, setRecipient] = useState([]);

  useEffect(() => {
    dispatch(singleRecipientStart({ stylopay_recipient_id: location.state }));
  }, []);

  useEffect(() => {
    if (
      !singleRecipient.loading &&
      Object.keys(singleRecipient.data).length > 0
    ) {
      setRecipient([singleRecipient.data.stylopay_recipient]);
    }
  }, [singleRecipient]);

  return (
    <>
      <div className="wallet-full-transaction-table">
        <Container>
          <Row>
            <Col md={12}>
              {singleRecipient.loading ? (
                <>
                  <div className="create-recipient-from-back">
                    <Skeleton height={30} width={200} borderRadius={5} />
                  </div>
                  <div className="recipient-detail-box">
                    <Skeleton height={400} borderRadius={10} />
                    <Skeleton height={400} borderRadius={10} />
                  </div>
                </>
              ) : Object.keys(singleRecipient.data).length > 0 ? (
                <>
                  <div className="create-recipient-from-back">
                    <Link onClick={() => navigate(-1)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="17"
                        fill="none"
                        viewBox="0 0 17 16"
                      >
                        <path
                          fill="#000"
                          d="M.293 7.293a1 1 0 000 1.414l6.364 6.364a1 1 0 001.414-1.414L2.414 8l5.657-5.657A1 1 0 006.657.93L.293 7.293zM16 9a1 1 0 100-2v2zM1 9h15V7H1v2z"
                        ></path>
                      </svg>
                    </Link>
                    <h2>Bank Account Details</h2>
                  </div>
                  {recipient.map((recipient, index) => (
                    <div className="recipient-detail-box">
                      <div className="recipient-detail-card">
                        <div className="recipient-detail-info">
                          <p>{t("first_name")}</p>
                          <h4>{recipient.first_name}</h4>
                        </div>
                        <div className="recipient-detail-info">
                          <p>{t("last_name")}</p>
                          <h4>{recipient.last_name}</h4>
                        </div>
                        <div className="recipient-detail-info">
                          <p>{t("user_id")}</p>
                          <h4>{recipient.unique_id}</h4>
                        </div>
                        <div className="recipient-detail-info">
                          <p>{t("email")}</p>
                          <h4>{recipient.email}</h4>
                        </div>
                        <div className="recipient-detail-info">
                          <p>{t("phone_number")}</p>
                          <h4>{recipient.mobile}</h4>
                        </div>
                        <div className="recipient-detail-info">
                          <p>{t("country")} </p>
                          <h4>
                            {
                              countriesData.find(
                                (country) => country.code === recipient.country
                              ).name
                            }
                          </h4>
                        </div>
                        <div className="recipient-detail-info">
                          <p>{t("state")}</p>
                          <h4>{recipient.state}</h4>
                        </div>
                        <div className="recipient-detail-info">
                          <p>{t("city")} </p>
                          <h4>{recipient.city}</h4>
                        </div>
                        <div className="recipient-detail-info">
                          <p>{t("zip_code")}</p>
                          <h4>{recipient.postal_code}</h4>
                        </div>
                        <div className="recipient-detail-info">
                          <p>{t("full_address")}</p>
                          <h4>
                            {recipient.address_line_1},{" "}
                            {recipient.address_line_2},{recipient.city},{" "}
                            {recipient.state}, {recipient.postal_code}
                          </h4>
                        </div>
                      </div>
                      <div className="recipient-detail-card">
                        <div className="recipient-detail-info">
                          <p>{t("bank_name")}</p>
                          <h4> {recipient.bank_id}</h4>
                        </div>
                        <div className="recipient-detail-info">
                          <p>{t("account_type")} </p>
                          <h4> {recipient.account_type}</h4>
                        </div>
                        <div className="recipient-detail-info">
                          <p>{t("receiving_currency")} </p>
                          <h4>{recipient.currency}</h4>
                        </div>
                        <div className="recipient-detail-info">
                          <p>{t("account_number")}</p>
                          <h4> {recipient.account_number}</h4>
                        </div>
                        <div className="recipient-detail-info">
                          <p>
                            {recipient.ifsc_code == null
                              ? t("iban_code")
                              : t("ifsc_code")}{" "}
                          </p>
                          <h4>
                            {recipient.ifsc_code == null
                              ? recipient.iban
                              : recipient.ifsc_code}
                          </h4>
                        </div>
                        <div className="recipient-detail-info">
                          {/* <p>Bank Address </p>
                          <h4>
                            57 13th Cross, Baldwins Road, Koramangala,
                            Bengaluru, Karnataka 560030
                          </h4> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <NoDataFound />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ViewRecipientDetail;
