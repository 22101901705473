import React, { useEffect, useState } from 'react';
import { Container, Image, Modal, Button } from "react-bootstrap";
import '../Wallet/Wallet.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang';
import { connect } from "react-redux";
import Skeleton from 'react-loading-skeleton';
import NoDataFound from '../helper/NoDataFound';
import { fetchWalletDetailsStart } from '../../store/actions/WalletAction';
import CustomLazyLoad from '../helper/CustomLazyLoad';

const WalletMain = (props) => {

  const t = useTranslation("wallet_main");

  useEffect(() => {
    props.dispatch(fetchWalletDetailsStart());
  }, []);


  return (
    <>
      <div className="wallet-wrapped">
        <Container>

          <div className="wallet-currency-list-wrapped">
            <div className="wallet-currency-title">
              <h2>{t("heading")}</h2>
            </div>

            <div className="wallet-currency-list-area">
              {props.wallet.loading ? (
                [...Array(4)].map((i, key) => <Skeleton key={key} height={135} width={250} />)
              ) : Object.keys(props.wallet.data).length > 0 &&
                props.wallet.data.user_wallets.length > 0 ?
                props.wallet.data.user_wallets.map((wallet, i) => (
                  wallet.currency_type == "crypto" &&
                  <div className="wallet-currency-list-card">
                    <div className="wallet-currency-list-top">
                      <div className="wallet-currency-list-info">
                        <h3>
                          {wallet.currency_code}
                        </h3>
                      </div>
                      <div className="wallet-currency-list-icon">
                        <CustomLazyLoad
                          src={wallet.currency_picture ||
                            window.location.origin + "/img/currency-icons/usdt.svg"}
                        />
                      </div>
                    </div>
                    <div className="wallet-currency-list-middle">
                      <h3>{wallet.remaining}</h3>
                      {/* <p>$324.56 USD</p> */}
                    </div>
                    <div className="wallet-currency-list-bottom">
                      <Link to={`/wallet/${wallet.id}`} className='list-link' >
                        {t("view_more")}
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 14 11"
                            className='list-arrow'
                          >
                            <path
                              fill="#E89900"
                              d="M1.57 5.848l11.261.094-11.26-.094zm11.261.094l-4.258 4.187 4.258-4.187zm0 0L8.644 1.684l4.187 4.258z"
                            ></path>
                            <path
                              stroke="#E89900"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="M1.57 5.848l11.261.094m0 0l-4.258 4.187m4.258-4.187L8.644 1.684"
                            ></path>
                          </svg>
                        </span>
                      </Link>
                    </div>
                  </div>
                ))
                :
                <NoDataFound />
              }
            </div>
          </div>

          <div className="wallet-currency-list-wrapped">
            <div className="wallet-currency-title">
              <h2>{t("fiat_wallets")}</h2>
            </div>
            <div className="wallet-currency-list-area">
              {props.wallet.loading ? (
                [...Array(4)].map((i, key) => <Skeleton key={key} height={220} />)
              ) : Object.keys(props.wallet.data).length > 0 &&
                props.wallet.data.user_wallets.length > 0 ?
                props.wallet.data.user_wallets.map((wallet, i) => (
                  wallet.currency_type == "forex" &&
                  <div className="wallet-currency-list-card">
                    <div className="wallet-currency-list-top">
                      <div className="wallet-currency-list-info">
                        <h3>
                          {wallet.currency_code}
                        </h3>
                      </div>
                      <div className="wallet-currency-list-icon">
                        <CustomLazyLoad
                          src={wallet.currency_picture ||
                             window.location.origin + "/img/currency-icons/aed.svg"}
                        />
                      </div>
                    </div>
                    <div className="wallet-currency-list-middle">
                      <h3>{wallet.remaining}</h3>
                    </div>
                    <div className="wallet-currency-list-bottom">
                      <Link to={`/wallet/${wallet.id}`} className='list-link' >
                        {t("view_more")}
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 14 11"
                            className='list-arrow'
                          >
                            <path
                              fill="#E89900"
                              d="M1.57 5.848l11.261.094-11.26-.094zm11.261.094l-4.258 4.187 4.258-4.187zm0 0L8.644 1.684l4.187 4.258z"
                            ></path>
                            <path
                              stroke="#E89900"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="M1.57 5.848l11.261.094m0 0l-4.258 4.187m4.258-4.187L8.644 1.684"
                            ></path>
                          </svg>
                        </span>
                      </Link>
                    </div>
                  </div>
                ))
                :
                <NoDataFound />
              }
            </div>
          </div>
        </Container>
      </div>
    </>
  )
};


const mapStateToPros = (state) => ({
  wallet: state.wallet.walletData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(mapStateToPros, mapDispatchToProps)(WalletMain);
