import React, { useCallback, useEffect, useState, useRef } from "react";
import { Modal, Button, Form, Image, Row, Col } from "react-bootstrap";
import "../Wallet/Wallet.css";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CommonCenterLoader from "../helper/CommonCenterLoader";
import { useDropzone } from "react-dropzone";
import {
  depositTokenStart,
  depositTokensViaBankStart,
  getAdminBankAccountStart,
  getAdminCryptoAccountStart,
} from "../../store/actions/BuyAction";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/ToastNotification";
import { useTranslation } from "react-multi-lang";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import {
  fetchAllTransactionStart,
  fetchSingleWalletViewStart,
} from "../../store/actions/WalletAction";
import { Link, useParams } from "react-router-dom";

const DepositModal = (props) => {
  const params = useParams();
  const t = useTranslation("deposit_modal");
  const formRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [step, setStep] = useState(1);
  const wallet = useSelector((state) => state.wallet.walletData);
  const currencies = useSelector((state) => state.buy.currencies);
  const [minAmount, setMinAmount] = useState(0.0000001);
  const [paymentType, setPaymentType] = useState(
    props.show.currency_type == "crypto" ? "card" : "bank"
  );
  const [cardNumber, setCardNumber] = useState("");
  const adminBankAccount = useSelector((state) => state.buy.adminBankAccount);
  const depositTokensViaBank = useSelector(
    (state) => state.buy.depositTokensViaBank
  );

  const validationSchema = () => {
    switch (step) {
      case 1:
        return Yup.object().shape({
          tokens: Yup.number()
            .required(t("required"))
            .min(
              minAmount,
              t("tokens.invalid", { value: minAmount.toFixed(7) })
            ),
          transaction_hash: Yup.string().when("is_crypto", {
            is: "1",
            then: Yup.string()
              .required(t("required"))
              .min(3, t("transaction_id.invalid")),
          }),
          // payment_file: Yup.string()
          //   .required(t("please_upload_payment_screenshot"))
        });

      case 2:
        return Yup.object().shape({
          card_number: Yup.string()
            .required("Card number is required")
            .test(
              "len",
              "Card number must be a 16-digit number",
              (val) => String(val).replace(/\s+/g, "").length === 16
            ),
          cvv: Yup.string()
            .required("CVV is required")
            .matches(/^\d{3,5}$/, "CVV must be a 3-digit number"),
          expiry_month: Yup.string()
            .required("Expiry month is required")
            .matches(
              /^(0[1-9]|1[0-2])$/,
              "Expiry month must be a two-digit number between 01 and 12"
            ),
          expiry_year: Yup.number()
            .required("Expiry year is required")
            .min(
              new Date().getFullYear(),
              `Expiry year must be greater than or equal to ${new Date().getFullYear()}`
            )
            .typeError("Expiry year must be a four-digit number")
            .integer("Expiry year must be a four-digit number")
            .test(
              "len",
              "Expiry year must be a four-digit number",
              (val) => String(val).length === 4
            ),
        });
    }
  };

  const dispatch = useDispatch();
  const adminCryptoAccount = useSelector(
    (state) => state.buy.adminCryptoAccount
  );
  const [skipRender, setSkipRender] = useState(true);
  const depositToken = useSelector((state) => state.buy.depositToken);

  const [files, setFiles] = useState([]);

  const onDropRejected = useCallback((rejectedFiles) => {
    if (rejectedFiles.length > 1) {
      setErrorMessage(t("please_upload_only_one_file_at_a_time"));
    } else {
      const invalidFiles = rejectedFiles.filter(
        (file) => !["image/jpeg", "image/png", "image/jpg"].includes(file.type)
      );
      if (invalidFiles.length > 0) {
        setErrorMessage(t("invalid_file_format"));
      }
    }
  }, []);

  const onDropAccepted = () => {
    setErrorMessage("");
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropRejected,
    onDropAccepted,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      formRef.current.setFieldValue("payment_file", acceptedFiles[0]);
    },
  });

  const images = files.map((file, key) => (
    <div className="preview-added-image" key={key}>
      <Button
        variant="link"
        className="preview-cancel"
        onClick={() => {
          formRef.current.setFieldValue("payment_file", "");
          setFiles([]);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#fff"
          viewBox="0 0 24 24"
        >
          <path d="M18 6a1 1 0 00-1.414 0L12 10.586 7.414 6A1 1 0 006 6a1 1 0 000 1.414L10.586 12 6 16.586A1 1 0 006 18a1 1 0 001.414 0L12 13.414 16.586 18A1 1 0 0018 18a1 1 0 000-1.414L13.414 12 18 7.414A1 1 0 0018 6z"></path>
        </svg>
      </Button>
      <CustomLazyLoad
        className="product-preview"
        key={file.name}
        src={file.preview}
        alt="image-preview"
      />
    </div>
  ));

  const handleSubmit = (values) => {
    step !== 2 && values.is_crypto !== "1" && setStep(step + 1);
    // if (files.length > 0) {
    if (step == 2 || props.show.currency_type == "crypto") {
      if (values.is_crypto == 0) {
        const { transaction_hash, payment_file, ...rest } = values;
        dispatch(
          depositTokenStart({
            ...rest,
            user_wallet_id: props.user_wallet_id,
            // payment_file: files[0] ? files[0] : "",
            admin_crypto_account_id:
              adminCryptoAccount.data.admin_crypto_account
                .admin_crypto_account_id,
            token_type: props.tokenType,
            card_number: values.card_number.replace(/\s+/g, ""),
          })
        );
      } else {
        const {
          card_number,
          cvv,
          expiry_month,
          expiry_year,
          payment_file,
          ...rest
        } = values;
        dispatch(
          depositTokenStart({
            ...rest,
            user_wallet_id: props.user_wallet_id,
            // payment_file: files[0] ? files[0] : "",
            admin_crypto_account_id:
              adminCryptoAccount.data.admin_crypto_account
                .admin_crypto_account_id,
            token_type: props.tokenType,
          })
        );
      }
    }

    // } else {
    //   getErrorNotificationMessage(t("please_upload_payment_screenshot"));
    // }
  };

  useEffect(() => {
    dispatch(getAdminCryptoAccountStart());
  }, []);

  useEffect(() => {
    if (
      !skipRender &&
      !depositToken.loading &&
      Object.keys(depositToken.data).length > 0
    ) {
      props.onHide();
      dispatch(
        fetchSingleWalletViewStart({
          user_wallet_id: params.user_wallet_id,
        })
      );
      dispatch(
        fetchAllTransactionStart({
          user_wallet_id: params.user_wallet_id,
          skip: 0,
          take: 12,
        })
      );
    }
    setSkipRender(false);
  }, [depositToken]);

  function handleKeyPress(event) {
    if (!/^[0-9.]$/.test(event.key) && event.key == "e") {
      event.preventDefault();
    }
  }

  const bankSchema = Yup.object().shape({
    payment_id: Yup.string()
      .required(t("payment_id.required"))
      .matches(/^(?=.*[a-zA-Z-0-9])/, t("invalid"))
      .min(3, t("payment_id.invalid")),
    payment_file: Yup.string().required(t("please_upload_payment_screenshot")),
    tokens: Yup.number()
      .required(t("payment_id.required"))
      .min(minAmount, t("tokens.invalid", { value: minAmount.toFixed(7) })),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getAdminBankAccountStart({
        currency: props.token,
      })
    );
  }, []);

  useEffect(() => {
    if (
      adminBankAccount.data &&
      Object.keys(adminBankAccount.data).length > 0
    ) {
      formRef.current.setFieldValue(
        "admin_bank_account_id",
        adminBankAccount.data.admin_bank_account.admin_bank_account_id
      );
    }
  }, [adminBankAccount, paymentType]);

  const onSubmit = (values) => {
    // if (files.length > 0) {

    const { card_number, cvv, expiry_month, expiry_year, ...rest } = values;
    dispatch(
      depositTokensViaBankStart({
        ...rest,
        user_wallet_id: props.user_wallet_id,
        token_type: props.tokenType,
      })
    );

    // } else {
    //   getErrorNotificationMessage(t("please_upload_payment_screenshot"));
    // }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !depositTokensViaBank.loading &&
      Object.keys(depositTokensViaBank.data).length > 0
    ) {
      props.onHide();
      dispatch(
        fetchSingleWalletViewStart({
          user_wallet_id: params.user_wallet_id,
        })
      );
      dispatch(
        fetchAllTransactionStart({
          user_wallet_id: params.user_wallet_id,
          skip: 0,
          take: 12,
        })
      );
    }
    setSkipRender(false);
  }, [depositTokensViaBank]);

  const handleChange = (e) => {
    const { value } = e.target;
    const unformattedValue = value.replace(/\s+/g, "");
    if (unformattedValue.length > 16) {
      return;
    }
    const formattedValue = formatNumber(value);
    setCardNumber(formattedValue);
    if (formRef.current) {
      formRef.current.setFieldValue("card_number", formattedValue);
    }
  };

  const formatNumber = (number) => {
    return number
      .replace(/\s+/g, "")
      .split("")
      .reduce((seed, next, index) => {
        if (index !== 0 && index % 4 === 0) {
          seed += " ";
        }
        return seed + next;
      }, "");
  };

  useEffect(() => {
    if (
      !skipRender &&
      !currencies.loading &&
      Object.keys(currencies.data).length > 0
    ) {
      setMinAmount(
        parseInt(
          currencies.data?.forex_currencies.filter(
            (data) =>
              wallet.data.user_wallets.find(
                (item) => item.id == props.user_wallet_id
              ).currency_code == data.currency_code
          )[0]?.min_deposit_amount
        ).toFixed(7)
      );
    }
    setSkipRender(false);
  }, [currencies]);

  useEffect(() => {
    if (minAmount.toFixed(7) == 0) {
      setMinAmount(0.000001);
    }
  }, [minAmount.toFixed(7)]);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="new-modal-custome"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4> {t("heading")}</h4>
          <div className="mb-3 exchange-form">
            {props.show.currency_type !== "crypto" && (
              <Form.Label>{t("payment_type")}</Form.Label>
            )}
            <Form.Group
              className="mb-3"
              controlId="formBasicEmail"
              style={{ display: "flex", gap: "1em" }}
            >
              {props.show.currency_type !== "crypto" && (
                <>
                  <Form.Check
                    type={"radio"}
                    label={"Bank"}
                    checked={paymentType == "bank"}
                    onChange={() => setPaymentType("bank")}
                    disabled={!Object.keys(adminBankAccount.data).length > 0}
                  />

                  <Form.Check
                    type={"radio"}
                    label={"Card"}
                    checked={paymentType == "card"}
                    onChange={() => setPaymentType("card")}
                  />
                </>
              )}
              <div></div>
            </Form.Group>
          </div>
          {paymentType == "bank" ? (
            adminBankAccount.loading ? (
              <CommonCenterLoader />
            ) : Object.keys(adminBankAccount.data).length > 0 ? (
              <React.Fragment>
                {props?.token == "INR" ? (
                  <div className="bank-details-full-card">
                    <div className="bank-details-full-card-top">
                      <div className="bank-details-card-top-img">
                        <CustomLazyLoad
                          src={
                            window.location.origin + "/img/bank/hdfc-bank.svg"
                          }
                          className="bank-logo-frame"
                        />
                      </div>
                    </div>
                    <div className="bank-details-card-top-info">
                      <p>
                        {" "}
                        {adminBankAccount.data.admin_bank_account.bank_name}
                      </p>
                    </div>
                    <div className="bank-details-full-card-middle">
                      <h4>
                        Account holder name: &nbsp;{" "}
                        {
                          adminBankAccount.data.admin_bank_account
                            .account_holder_name
                        }
                      </h4>
                      <h4>
                        {t("account_number")}: &nbsp;
                        {
                          adminBankAccount.data.admin_bank_account
                            .account_number
                        }
                      </h4>
                      <h4>
                        {t("ifsc_code")}: &nbsp;
                        {adminBankAccount.data.admin_bank_account.ifsc_code}
                      </h4>
                    </div>
                    <div className="bank-details-full-card-bottom">
                      <div className="bank-details-card-top-ifsc-code-sec">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          enableBackground="new 0 0 512 512"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M24 23a1 1 0 01-1 1H1a1 1 0 010-2h22a1 1 0 011 1zM.291 8.552a2.443 2.443 0 01.153-2.566 4.716 4.716 0 011.668-1.5L9.613.582a5.174 5.174 0 014.774 0l7.5 3.907a4.716 4.716 0 011.668 1.5 2.443 2.443 0 01.153 2.566A2.713 2.713 0 0121.292 10H21v8h1a1 1 0 010 2H2a1 1 0 010-2h1v-8h-.292A2.713 2.713 0 01.291 8.552zM5 18h3v-8H5zm5-8v8h4v-8zm9 0h-3v8h3zM2.063 7.625A.717.717 0 002.708 8h18.584a.717.717 0 00.645-.375.452.452 0 00-.024-.5 2.7 2.7 0 00-.949-.864l-7.5-3.907a3.176 3.176 0 00-2.926 0l-7.5 3.907a2.712 2.712 0 00-.949.865.452.452 0 00-.026.499z"
                            data-original="#000000"
                          ></path>
                        </svg>
                        <span>
                          {" "}
                          {adminBankAccount.data.admin_bank_account.bank_name}
                        </span>
                      </div>
                      <div className="bank-details-card-top-ifsc-code-sec">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 16 17"
                        >
                          <path
                            stroke="#252525"
                            strokeLinecap="round"
                            strokeWidth="2"
                            d="M1.333 15.813V15a4.875 4.875 0 014.875-4.875h3.25A4.875 4.875 0 0114.333 15v.813M7.833 7.688a3.25 3.25 0 110-6.5 3.25 3.25 0 010 6.5z"
                          ></path>
                        </svg>
                        <span>{t("savings_account")}</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="bank-details-full-card">
                    <div className="bank-details-full-card-top">
                      {/* <div className="bank-details-card-top-img">
                        <CustomLazyLoad
                          src={
                            window.location.origin + "/img/bank/hdfc-bank.svg"
                          }
                          className="bank-logo-frame"
                        />
                      </div> */}
                      <div className="bank-details-card-top-info">
                        <p>
                          {" "}
                          {adminBankAccount.data.admin_bank_account.bank_name}
                        </p>
                      </div>
                    </div>
                    <div className="bank-details-full-card-middle">
                      <h4>
                        Account holder name:{" "}
                        {
                          adminBankAccount.data.admin_bank_account
                            .account_holder_name
                        }
                      </h4>
                      <h4>
                        {t("account_number")}: &nbsp;
                        {
                          adminBankAccount.data.admin_bank_account
                            .account_number
                        }
                      </h4>
                      <h4>
                        {t("iban_no")}: &nbsp;
                        {adminBankAccount.data.admin_bank_account.iban_no}
                      </h4>
                      <h4>
                        {t("swift_code_beneficiary_bank")}: &nbsp;
                        {
                          adminBankAccount.data.admin_bank_account
                            .swift_code_beneficiary_bank
                        }
                      </h4>
                      <h4>
                        {t("swift_code_correspondent_bank")}: &nbsp;
                        {
                          adminBankAccount.data.admin_bank_account
                            .swift_code_correspondent_bank
                        }
                      </h4>
                      <h4>
                        {t("correspondent_account_no")}: &nbsp;
                        {
                          adminBankAccount.data.admin_bank_account
                            .correspondent_account_no
                        }
                      </h4>
                      <h4>
                        {t("bank_address")}: &nbsp;
                        {adminBankAccount.data.admin_bank_account.bank_address}
                      </h4>
                    </div>
                    <div className="bank-details-full-card-bottom">
                      <div className="bank-details-card-top-ifsc-code-sec">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          enableBackground="new 0 0 512 512"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M24 23a1 1 0 01-1 1H1a1 1 0 010-2h22a1 1 0 011 1zM.291 8.552a2.443 2.443 0 01.153-2.566 4.716 4.716 0 011.668-1.5L9.613.582a5.174 5.174 0 014.774 0l7.5 3.907a4.716 4.716 0 011.668 1.5 2.443 2.443 0 01.153 2.566A2.713 2.713 0 0121.292 10H21v8h1a1 1 0 010 2H2a1 1 0 010-2h1v-8h-.292A2.713 2.713 0 01.291 8.552zM5 18h3v-8H5zm5-8v8h4v-8zm9 0h-3v8h3zM2.063 7.625A.717.717 0 002.708 8h18.584a.717.717 0 00.645-.375.452.452 0 00-.024-.5 2.7 2.7 0 00-.949-.864l-7.5-3.907a3.176 3.176 0 00-2.926 0l-7.5 3.907a2.712 2.712 0 00-.949.865.452.452 0 00-.026.499z"
                            data-original="#000000"
                          ></path>
                        </svg>
                        <span>
                          {" "}
                          {adminBankAccount.data.admin_bank_account.bank_name}
                        </span>
                      </div>
                      <div className="bank-details-card-top-ifsc-code-sec">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 16 17"
                        >
                          <path
                            stroke="#252525"
                            strokeLinecap="round"
                            strokeWidth="2"
                            d="M1.333 15.813V15a4.875 4.875 0 014.875-4.875h3.25A4.875 4.875 0 0114.333 15v.813M7.833 7.688a3.25 3.25 0 110-6.5 3.25 3.25 0 010 6.5z"
                          ></path>
                        </svg>
                        <span>{t("savings_account")}</span>
                      </div>
                    </div>
                  </div>
                )}
                <hr />
                <Formik
                  initialValues={{
                    payment_id: "",
                    payment_file: "",
                    admin_bank_account_id: "",
                    tokens: "",
                  }}
                  validationSchema={bankSchema}
                  innerRef={formRef}
                  onSubmit={(values) => onSubmit(values)}
                >
                  {({ values, touched, errors, setFieldValue }) => (
                    <FORM className="exchange-form">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{t("payment_id.label")}</Form.Label>{" "}
                        <span>*</span>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder={t("payment_id.placeholder")}
                          name="payment_id"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="payment_id"
                          className="text-danger text-right"
                        />
                      </Form.Group>
                      <Form.Group controlId="formBasicEmail" className="mb-3">
                        <Form.Label>
                          Amount in {props?.token}
                          <span>*</span>
                        </Form.Label>
                        <Field
                          className="form-control"
                          type="number"
                          placeholder={t("tokens.placeholder")}
                          name="tokens"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="tokens"
                          className="text-danger text-right"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{t("payment_picture")}</Form.Label>{" "}
                        <span>*</span>
                        <div {...getRootProps()}>
                          <input name="payment_file" {...getInputProps()} />
                          {files.length <= 0 && (
                            <div className="add-product-upload-box">
                              <CustomLazyLoad
                                src={window.location.origin + "/img/upload.png"}
                                className="upload-icon"
                              />
                              <p>
                                {t("upload_your_payment_screenshot_here")}
                                <br />{" "}
                                <span> {t("accept_only_jpeg_jpg_png")}</span>
                              </p>
                            </div>
                          )}
                          <div>{images}</div>
                        </div>
                        <ErrorMessage
                          component={"div"}
                          name="payment_file"
                          className="text-danger text-right"
                        />
                      </Form.Group>
                      {errorMessage && (
                        <p className="error-msg text-danger select-date">
                          {errorMessage}
                        </p>
                      )}
                      <div className="exchange-btn-sec">
                        <Button
                          className="default-btn"
                          type="submit"
                          disabled={depositTokensViaBank.buttonDisable}
                        >
                          {depositTokensViaBank.buttonDisable
                            ? t("loading")
                            : t("deposit")}
                        </Button>
                        <p>
                          {t("by_continuing_you_agree_to_our")}{" "}
                          <Link
                            className="cookie-txt"
                            to="/page/privacy-agreement"
                            target="_blank"
                          >
                            {t("cookie_policy")}.
                          </Link>
                        </p>
                      </div>
                    </FORM>
                  )}
                </Formik>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="no-bank-details-sec exchange-btn-sec">
                  <CustomLazyLoad
                    src={window.location.origin + "/img/no-bank-found.png"}
                    className="no-data-found-img"
                  />
                  <p>{t("no_bank_details_found")}</p>
                  <Button
                    className="default-btn w-100"
                    onClick={() => dispatch(getAdminBankAccountStart())}
                  >
                    {t("retry")}
                  </Button>
                </div>
              </React.Fragment>
            )
          ) : adminCryptoAccount.loading ? (
            <CommonCenterLoader />
          ) : Object.keys(adminCryptoAccount.data).length > 0 ? (
            <>
              <Formik
                initialValues={{
                  tokens: "",
                  transaction_hash: "",
                  payment_file: "",
                  is_crypto: props.show.currency_type == "crypto" ? "1" : "0",
                  card_number: "",
                  cvv: "",
                  expiry_month: "",
                  expiry_year: "",
                }}
                validationSchema={validationSchema}
                innerRef={formRef}
                onSubmit={handleSubmit}
              >
                {({ touched, errors, setFieldValue, values }) => (
                  <FORM className="new-feature-form">
                    {step == 1 ? (
                      <>
                        {values.is_crypto == 1 && (
                          <div className="admin-crypto-details-box">
                            <p className="token-need">
                              {" "}
                              {t("selected_token")} :{" "}
                              <span> {props.tokenType} </span>
                            </p>
                            {adminCryptoAccount.data.admin_crypto_account
                              .qr_code ? (
                              <div className="admin-wallet-qr-frame">
                                <CustomLazyLoad
                                  src={
                                    adminCryptoAccount.data.admin_crypto_account
                                      .qr_code
                                  }
                                  className="qr-code"
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="admin-crypto-details-sec">
                              <p>
                                {adminCryptoAccount.data.admin_crypto_account.wallet_address.slice(
                                  0,
                                  15
                                )}
                                ...
                                {adminCryptoAccount.data.admin_crypto_account.wallet_address.slice(
                                  -15
                                )}
                              </p>
                              <CopyToClipboard
                                text={
                                  adminCryptoAccount.data.admin_crypto_account
                                    .wallet_address
                                }
                                onCopy={() =>
                                  getSuccessNotificationMessage(
                                    t("wallet_address_copied")
                                  )
                                }
                              >
                                <Button className="new-action-btn">
                                  <span>{t("copy")}</span>
                                </Button>
                              </CopyToClipboard>
                            </div>
                          </div>
                        )}
                        <hr />
                        <div className="auth-form-box">
                          <Row>
                            <Col>
                              <Form.Group
                                controlId="formBasicEmail"
                                className="mb-3"
                              >
                                <Form.Label>
                                  Amount in {props?.token}
                                  <span>*</span>
                                </Form.Label>
                                <Field
                                  type="number"
                                  name="tokens"
                                  placeholder={t("tokens.placeholder")}
                                  className="form-control"
                                  onKeyPress={handleKeyPress}
                                  onPaste={(event) => {
                                    event.preventDefault();
                                  }}
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name="tokens"
                                  className="text-danger text-right"
                                />
                              </Form.Group>
                            </Col>
                            <Col>
                              {values.is_crypto == 1 && (
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("transaction_id.label")}
                                    <span>*</span>
                                  </Form.Label>
                                  <Field
                                    type="text"
                                    name="transaction_hash"
                                    placeholder={t("transaction_id.label")}
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="transaction_hash"
                                    className="text-danger text-right"
                                  />
                                </Form.Group>
                              )}
                            </Col>
                          </Row>
                          {/* {values?.is_crypto == 1 && (
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>
                                {t("payment_picture")}
                                <span>*</span>
                              </Form.Label>
                              <div {...getRootProps()}>
                                <input
                                  name="payment_file"
                                  {...getInputProps()}
                                />
                                {files.length <= 0 && (
                                  <div className="add-product-upload-box">
                                    <CustomLazyLoad
                                      src={
                                        window.location.origin +
                                        "/img/upload.svg"
                                      }
                                      className="upload-icon"
                                    />
                                    <p>
                                      {t("upload_your_payment_screenshot_here")}
                                      <br />{" "}
                                      <span>
                                        {" "}
                                        {t("accepted_types_jpg_png")}
                                      </span>
                                    </p>
                                  </div>
                                )}
                                <div>{images}</div>
                              </div>
                              <ErrorMessage
                                component={"div"}
                                name="payment_file"
                                className="text-danger text-right"
                              />
                            </Form.Group>
                          )} */}
                          {errorMessage && (
                            <p className="error-msg text-danger select-date">
                              {errorMessage}
                            </p>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Card Number</Form.Label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder={"Enter Card number"}
                            name="card_number"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="card_number"
                            onChange={handleChange}
                            className="text-danger text-right"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCVV">
                          <Form.Label>CVV</Form.Label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder="Enter CVV"
                            name="cvv"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="cvv"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                        <div
                          style={{ display: "flex", gap: "1em", width: "100%" }}
                        >
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicExpiryMonth"
                            style={{ width: "100%" }}
                          >
                            <Form.Label>Expiry Month</Form.Label>
                            <Field
                              className="form-control"
                              type="text"
                              placeholder="Enter expiry month"
                              name="expiry_month"
                            />
                            <ErrorMessage
                              component={"div"}
                              name="expiry_month"
                              className="text-danger text-right"
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicExpiryYear"
                            style={{ width: "100%" }}
                          >
                            <Form.Label>Expiry Year</Form.Label>
                            <Field
                              className="form-control"
                              type="text"
                              placeholder="Enter expiry year"
                              name="expiry_year"
                            />
                            <ErrorMessage
                              component={"div"}
                              name="expiry_year"
                              className="text-danger text-right"
                            />
                          </Form.Group>
                        </div>
                      </>
                    )}
                    <div className="new-action-frame layout-element">
                      {step == 1 ? (
                        values.is_crypto == "1" ? (
                          <Button
                            type="submit"
                            className="new-primary-btn"
                            disabled={depositToken.buttonDisable}
                          >
                            {depositToken.buttonDisable
                              ? t("loading")
                              : t("deposit")}
                          </Button>
                        ) : (
                          <Button type="submit" className="new-primary-btn">
                            Next
                          </Button>
                        )
                      ) : (
                        <>
                          <Button
                            className="new-overlay-btn"
                            onClick={props.onHide}
                            disabled={depositToken.buttonDisable}
                          >
                            {t("cancel")}
                          </Button>
                          <Button
                            className="new-overlay-btn"
                            onClick={() => setStep(step - 1)}
                          >
                            back
                          </Button>
                          <Button
                            type="submit"
                            className="new-primary-btn"
                            disabled={depositToken.buttonDisable}
                          >
                            {depositToken.buttonDisable
                              ? t("loading")
                              : t("deposit")}
                          </Button>
                        </>
                      )}
                    </div>
                  </FORM>
                )}
              </Formik>
            </>
          ) : (
            <>
              <div className="no-data-frame">
                <div className="admin-crypto-details-box">
                  <CustomLazyLoad
                    src={window.location.origin + "/img/no-data-found.png"}
                  />
                  <p style={{ fontSize: "1em", fontWeight: "600" }}>
                    {t("admin_crypto_details_not_found")}
                  </p>
                </div>
              </div>
              <div className="new-action-frame layout-element">
                <Button className="new-primary-btn" onClick={props.onHide}>
                  {t("close")}
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DepositModal;
