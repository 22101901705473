import React, { useEffect, useState } from 'react';
import { Form, Button, Modal } from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import '../Wallet/Wallet.css';
import { useTranslation } from "react-multi-lang";
import { connect } from 'react-redux';
import { changePasswordStart } from '../../store/actions/UserAction';

const ChangePasswordModal = (props) => {

  const t = useTranslation("change_password");

  const validationSchema = Yup.object().shape({
    old_password: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("password.invalid")).required(t("password.required")),
    password: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("new_password.invalid"))
      .required(t("new_password.required"))
      .when("old_password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().notOneOf(
          [Yup.ref("old_password")],
          t("new_password.new_password_invalid")
        ),
      }),
    password_confirmation: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("confirm_password.invalid"))
      .required(t("confirm_password.required"))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t("confirm_password.same_password_invalid")
        ),
      }),
  });

  const handleSubmit = (values) => {
    props.dispatch(changePasswordStart(values));
  };

  return (
    <>
   <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="new-modal-custome"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4> {t("heading")}</h4>
        <Formik
            initialValues={{
              old_password: "",
              password: "",
              password_confirmation: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <FORM className="new-feature-form">
                <Form.Group className="mb-3" >
                  <Form.Label>{t("password.label")}</Form.Label>
                  <Field
                    name="old_password"
                    className="form-control"
                    type="password"
                    autoFocus={true}
                    placeholder={t("password.placeholder")}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="old_password"
                    className="errorMsg"
                  />
                </Form.Group>
                <Form.Group className="mb-3" >
                  <Form.Label>{t("new_password.label")}</Form.Label>
                  <Field
                    name="password"
                    className="form-control"
                    type="password"
                    placeholder={t("new_password.placeholder")}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="password"
                    className="errorMsg"
                  />
                </Form.Group>
                <Form.Group className="mb-3" >
                  <Form.Label>{t("confirm_password.label")}</Form.Label>
                  <Field
                    name="password_confirmation"
                    className="form-control"
                    type="password"
                    placeholder={t("confirm_password.placeholder")}

                  />
                  <ErrorMessage
                    component={"div"}
                    name="password_confirmation"
                    className="errorMsg"
                  />
                </Form.Group>
                <div className="new-action-frame layout-element">
                  <Button className="new-overlay-btn"
                    onClick={props.onHide}
                    disabled={
                      props.changePassword.buttonDisable
                    }
                  >{t("cancel")}
                  </Button>
                  <Button className="new-primary-btn"
                    type="submit"
                    disabled={
                      props.changePassword.buttonDisable
                    }
                  >
                    {props.changePassword.buttonDisable
                      ? t("save changes_btn.loading")
                      : t("save changes_btn.text")}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
      </Modal.Body>
    </Modal>
    </>
  )
};

const mapStateToPros = (state) => ({
  changePassword: state.user.changePassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(
  mapStateToPros,
  mapDispatchToProps
)(ChangePasswordModal);