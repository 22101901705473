import React, { useEffect, useState, useRef } from "react";
import { Form, InputGroup, Image, Table, Button } from "react-bootstrap";
import Select from "react-select";
import CopyToClipboard from "react-copy-to-clipboard";
import Skeleton from "react-loading-skeleton";
import { connect, useDispatch, useSelector } from "react-redux";
import NoDataFound from "../helper/NoDataFound";
import { fetchWithDrawalsStart } from "../../store/actions/WalletAction";
import * as Yup from "yup";
import { Field, Form as FORM, Formik } from "formik";
import {
  userWithdrawalsExportStart,
  withdrawalStatusStart,
} from "../../store/actions/BuyAction";
import { useParams } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "../helper/Pagination";
import CancelWithdrawalModal from "./CancelWithdrawalModal";
import { useTranslation } from "react-multi-lang";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../helper/ToastNotification";
import DatePicker from "react-multi-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

const WithDrawHistory = (props) => {
  const params = useParams();
  const formRef = useRef(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const t = useTranslation("wallet_index");

  const options = [
    { value: "0", label: t("withdraw_initiated") },
    { value: "1", label: t("withdraw_paid") },
    { value: "3", label: t("withdraw_rejected") },
  ];

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      width: "180px",
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      width: "180px",
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#111",
      fontSize: "0.85em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#32089F",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#32089F!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#32089F!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#111",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  const [modalShow, setModalShow] = useState(false);
  const [withdrawalId, setWithdrawalId] = useState(null);
  const dispatch = useDispatch();
  const withDrawals = useSelector((state) => state.wallet.withDrawals);
  const withdrawalStatus = useSelector((state) => state.buy.withdrawalStatus);
  const [value, setValue] = useState([null, null]);
  const [skipRender, setSkipRender] = useState(true);
  const [filterStatus, setFilterStatus] = useState(false);
  const [filter, setFilter] = useState({
    search_key: searchParams.get("search_key")
      ? searchParams.get("search_key")
      : "",
    status: "",
  });
  const [close, setClose] = useState(false);
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const calenderRefs = useRef(null);
  const [dateValues, setDateValues] = useState({
    from_date: "",
    to_date: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      fetchWithDrawalsStart({
        ...filter,
        user_wallet_id: params.user_wallet_id,
        skip: 0,
        take: 12,
      })
    );
  }, [filter, page]);

  const handleDateChange = (values) => {
    if (values) {
      setValue(values);
      formRef?.current?.setFieldValue(
        "from_date",
        values && values.length > 0
          ? JSON.stringify(new Date(values[0])).slice(1, 11)
          : ""
      );
      formRef?.current?.setFieldValue(
        "to_date",
        values && values.length > 1
          ? JSON.stringify(new Date(values[1])).slice(1, 11)
          : ""
      );
      setDateValues({
        from_date:
          values.length > 0
            ? JSON.stringify(new Date(values[0])).slice(1, 11)
            : "",
        to_date:
          values.length > 1
            ? JSON.stringify(new Date(values[1])).slice(1, 11)
            : "",
      });
    } else {
      setDateValues({
        from_date: "",
        to_date: "",
      });
      formRef?.current?.setFieldValue("from_date", "");
      formRef?.current?.setFieldValue("to_date", "");
      calenderRefs.current.closeCalendar();
      setFilter({
        ...filter,
        skip: 12 * (page - 1),
        take: 12,
        from_date: "",
        to_date: "",
      });
    }
  };

  const handleExport = () => {
    dispatch(
      userWithdrawalsExportStart({
        ...filter,
        from_date: dateValues.from_date,
        to_date: dateValues.to_date,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !withdrawalStatus.loading &&
      Object.keys(withdrawalStatus.data).length > 0
    ) {
      dispatch(
        fetchWithDrawalsStart({
          ...filter,
          user_wallet_id: params.user_wallet_id,
          skip: 0,
          take: 12,
        })
      );
    }
  }, [withdrawalStatus]);

  const handlePageClick = ({ selected }) => {
    let param = searchParams.get("search_key")
      ? `search_key=${searchParams.get("search_key")}&`
      : "";
    navigate(`/wallet/${params.user_wallet_id}?${param}page=${selected + 1}`);
  };

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
      setFilter({
        ...filter,
        search_key: searchParams.get("search_key")
          ? searchParams.get("search_key")
          : "",
        from_date: dateValues.from_date,
        to_date: dateValues.to_date,
      });
    }
    setSkipRender(false);
  }, [searchParams.get("page"), searchParams.get("search_key")]);

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
    }
    setSkipRender(false);
  }, [searchParams.get("page")]);

  useEffect(() => {
    if (
      !skipRender &&
      !props.userWithdrawalsExport.buttonDisable &&
      Object.keys(props.userWithdrawalsExport.data).length > 0
    ) {
      window.open(props.userWithdrawalsExport.data.url, "_blank", "noreferrer");
    }
    setSkipRender(false);
  }, [props.userWithdrawalsExport]);

  const validationSchema = Yup.object().shape({
    search_key: Yup.string()
      .required(t("required"))
      .matches(/^(?=\S*$)/, t("white_space")),
  });

  const cancelWithdrawal = (accout_id) => {
    setWithdrawalId(accout_id);
    setModalShow(true);
  };

  const handleRefresh = (values) => {
    setWithdrawalId(values.id);
    dispatch(
      withdrawalStatusStart({ user_withdrawal_id: values.user_withdrawal_id })
    );
  };

  return (
    <>
      <div className="feature-transaction-sort-wrapped">
        <Formik
          initialValues={{
            search_key: searchParams.get("search_key")
              ? searchParams.get("search_key")
              : "",
            sort_by: searchParams.get("sort_by")
              ? searchParams.get("sort_by")
              : filter.sort_by,
            from_date: "",
            to_date: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(val) => {
            setFilter({
              ...filter,
              search_key: val.search_key,
              from_date: dateValues.from_date,
              to_date: dateValues.to_date,
            });

            navigate(
              searchParams.get("sort_by")
                ? `/wallet/${params.user_wallet_id}?search_key=${
                    val.search_key
                  }&sort_by=${searchParams.get("sort_by")}`
                : `/wallet/${params.user_wallet_id}?search_key=${val.search_key}`,
              { state: { value: value } }
            );
          }}
          innerRef={formRef}
        >
          {({ values, setFieldValue, resetForm }) => (
            <FORM
              className="form transaction-form"
              style={{ display: "flex", gap: "0.5em" }}
            >
              <div className="feature-transaction-left-frame">
                <div className="new-feature-form">
                  <InputGroup className="mb-0">
                    <Field
                      name="search_key"
                      placeholder={t("search")}
                      className="form-control"
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          formRef.current.handleSubmit();
                        }
                      }}
                    />
                    <InputGroup.Text id="basic-addon2">
                      {searchParams.get("search_key") ? (
                        <Button
                          type="button"
                          onClick={() => {
                            setFieldValue("search_key", "");
                            setFilter({
                              ...filter,
                              search_key: "",
                              from_date: dateValues.from_date,
                              to_date: dateValues.to_date,
                            });
                            navigate(
                              searchParams.get("sort_by")
                                ? `/wallet/${
                                    params.user_wallet_id
                                  }?sort_by=${searchParams.get("sort_by")}`
                                : `/wallet/${params.user_wallet_id}`
                            );
                          }}
                        >
                          <svg
                            height="24"
                            width="24"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                            focusable="false"
                            fill="#333"
                          >
                            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                          </svg>
                        </Button>
                      ) : (
                        <Button type="submit">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#333"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                            <path d="M21 21l-6 -6" />
                          </svg>
                        </Button>
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                </div>
              </div>
              <div className="feature-transaction-right-frame">
                <div className="new-feature-form">
                  <Form.Group
                    className={`d-flex date-sec ${close ? "date-close" : ""}`}
                  >
                    <InputGroup className="mb-0">
                      <DatePicker
                        value={value}
                        style={{ padding: "1em" }}
                        placeholder="Select Date Range"
                        onChange={(date) => {
                          handleDateChange(date);
                        }}
                        range
                        numberOfMonths={2}
                        ref={calenderRefs}
                        maxDate={new Date(new Date().setHours(23, 59, 59, 999))}
                        editable={false}
                        onOpenPickNewDate={false}
                      >
                        <div
                          className="d-flex justify-content-end"
                          style={{ padding: "1em", gap: "0.5em" }}
                        >
                          <Button
                            className="new-overlay-btn"
                            onClick={() => {
                              calenderRefs.current.closeCalendar();
                              setValue([null, null]);
                            }}
                          >
                            {t("close")}
                          </Button>
                          <Button
                            className="new-primary-btn"
                            onClick={() => {
                              if (values.from_date || values.to_date) {
                                setFilter({
                                  ...filter,
                                  skip: 12 * (page - 1),
                                  take: 12,
                                  from_date: dateValues.from_date,
                                  to_date: dateValues.to_date,
                                });
                                calenderRefs.current.closeCalendar();
                                setClose(true);
                              } else
                                getErrorNotificationMessage(
                                  t("please_select_date")
                                );
                            }}
                          >
                            {t("apply")}
                          </Button>
                        </div>
                      </DatePicker>
                      {close ? (
                        <>
                          <button
                            type="button"
                            className=" date-cancel"
                            onClick={() => {
                              setDateValues({
                                from_date: "",
                                to_date: "",
                              });
                              setValue([null, null]);
                              setClose(false);
                              setFilter({
                                ...filter,
                                from_date: "",
                                to_date: "",
                              });
                            }}
                          >
                            <svg
                              height="20"
                              width="20"
                              viewBox="0 0 20 20"
                              aria-hidden="true"
                              focusable="false"
                              class="css-tj5bde-Svg"
                            >
                              <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                            </svg>
                          </button>
                        </>
                      ) : null}
                      <InputGroup.Text id="basic-addon2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#333"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                          <path d="M16 3l0 4" />
                          <path d="M8 3l0 4" />
                          <path d="M4 11l16 0" />
                          <path d="M8 15h2v2h-2z" />
                        </svg>
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </div>
                <Select
                  className="fillert-drop"
                  options={options}
                  isClearable
                  styles={customStyles}
                  isSearchable={false}
                  value={filterStatus || null}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setFilter({
                        ...filter,
                        status: selectedOption.value,
                      });
                      setFilterStatus(selectedOption);
                      navigate(
                        filter.search_key
                          ? `/wallet/${params.user_wallet_id}?search_key=${filter.search_key}&status=${selectedOption.value}`
                          : `/wallet/${params.user_wallet_id}?status=${selectedOption.value}`
                      );
                    } else {
                      const newFilter = { ...filter };
                      delete newFilter.status;
                      setFilter(newFilter);

                      navigate(
                        filter.search_key
                          ? `/wallet/${params.user_wallet_id}?search_key=${filter.search_key}`
                          : `/wallet/${params.user_wallet_id}`
                      );
                      setFilterStatus(null);
                    }
                  }}
                  placeholder={
                    <div className="placeholder-flex">{t("filter")}</div>
                  }
                />
                <Button
                  type="submit"
                  className="new-action-btn"
                  onClick={handleExport}
                  disabled={
                    props.userWithdrawalsExport.buttonDisable ||
                    withDrawals.loading ||
                    !Object.keys(withDrawals.data).length > 0 ||
                    !withDrawals.data.user_withdrawals.length > 0
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#333"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-download"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                    <path d="M7 11l5 5l5 -5" />
                    <path d="M12 4l0 12" />
                  </svg>
                  <span>{t("buy_transaction.export")}</span>
                </Button>
              </div>
            </FORM>
          )}
        </Formik>
      </div>
      {withDrawals.loading ? (
        <Skeleton count={4} height={80} />
      ) : Object.keys(withDrawals.data).length > 0 &&
        withDrawals.data.user_withdrawals.length > 0 ? (
        <React.Fragment>
          <div className="new-table-frame">
            <Table responsive className="new-feature-table">
              <thead>
                <tr>
                  <th>{t("withdraw_table.request_id")}</th>
                  <th>{t("withdraw_table.requested_date")}</th>
                  <th>{t("withdraw_table.user_amount")}</th>
                  <th>
                    {props.currency_type == "crypto"
                      ? t("withdraw_table.wallet_address")
                      : t("withdraw_table.billing_account")}
                  </th>
                  <th>{t("withdraw_table.status")}</th>
                  <th>{t("withdraw_table.action")}</th>
                </tr>
              </thead>
              <tbody>
                {withDrawals.data.user_withdrawals.map((withdrawals, key) => (
                  <tr key={key}>
                    <td>
                      <div className="histroy-id-frame table-bold-text">
                        {withdrawals.unique_id ? (
                          <>
                            {withdrawals.unique_id.slice(0, 5) +
                              "......" +
                              withdrawals.unique_id.slice(-5)}
                            <CopyToClipboard
                              text={withdrawals.unique_id}
                              onCopy={() =>
                                getSuccessNotificationMessage(
                                  t(
                                    "withdraw_table.request_id_copied_to_clipboard"
                                  )
                                )
                              }
                            >
                              <Button className="new-action-btn">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#333"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                  <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                                </svg>
                                <span>{t("copy")}</span>
                              </Button>
                            </CopyToClipboard>
                          </>
                        ) : (
                          t("n_a")
                        )}
                      </div>
                    </td>
                    <td>{withdrawals.created_at}</td>
                    <td>{withdrawals.requested_amount_formatted}</td>
                    <td>
                      {props.currency_type == "crypto" ? (
                        <>
                          <div className="histroy-id-frame table-bold-text">
                            {withdrawals.wallet_address ? (
                              <>
                                {withdrawals.wallet_address.slice(0, 5) +
                                  "......" +
                                  withdrawals.wallet_address.slice(-5)}
                                <CopyToClipboard
                                  text={withdrawals.wallet_address}
                                  onCopy={() =>
                                    getSuccessNotificationMessage(
                                      t(
                                        "withdraw_table.request_id_copied_to_clipboard"
                                      )
                                    )
                                  }
                                >
                                  <Button className="new-action-btn">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="#333"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      />
                                      <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                      <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                                    </svg>
                                    <span>{t("copy")}</span>
                                  </Button>
                                </CopyToClipboard>
                              </>
                            ) : (
                              t("withdraw_table.n_a")
                            )}
                          </div>
                        </>
                      ) : (
                        <div className="histroy-id-frame table-bold-text">
                          {withdrawals.account_number ? (
                            <>
                              {withdrawals.account_number.slice(0, 5) +
                                "......" +
                                withdrawals.account_number.slice(-5)}

                              <CopyToClipboard
                                text={withdrawals.account_number}
                                onCopy={() =>
                                  getSuccessNotificationMessage(
                                    t(
                                      "withdraw_table.account_number_copied_to_clipboard"
                                    )
                                  )
                                }
                              >
                                <Button className="new-action-btn">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#333"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    />
                                    <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                    <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                                  </svg>
                                  <span>{t("copy")}</span>
                                </Button>
                              </CopyToClipboard>
                            </>
                          ) : (
                            t("withdraw_table.n_a")
                          )}
                        </div>
                      )}
                    </td>
                    <td>
                      {withdrawals.status == 3 ? (
                        <div className="wallet-payment-status">
                          <p className="processing">
                            {withdrawals.status_formatted}
                          </p>
                        </div>
                      ) : (
                        <div className="wallet-payment-status">
                          <p className="paid">
                            {" "}
                            {withdrawals.status_formatted}
                          </p>
                        </div>
                      )}
                    </td>
                    <td>
                      <div className="withdraw-history-btn">
                        {withdrawals.payment_file ? (
                          <a href="payment_file" target="_blank">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              enableBackground="new 0 0 512 512"
                              viewBox="0 0 24 24"
                            >
                              <g fill="#5d35b3">
                                <path
                                  d="M19 0H5a5.006 5.006 0 00-5 5v14a5.006 5.006 0 005 5h14a5.006 5.006 0 005-5V5a5.006 5.006 0 00-5-5zM5 2h14a3 3 0 013 3v14a2.951 2.951 0 01-.3 1.285l-9.163-9.163a5 5 0 00-7.072 0L2 14.586V5a3 3 0 013-3zm0 20a3 3 0 01-3-3v-1.586l4.878-4.878a3 3 0 014.244 0l9.163 9.164A2.951 2.951 0 0119 22z"
                                  data-original="#000000"
                                ></path>
                                <path
                                  d="M16 10.5A3.5 3.5 0 1012.5 7a3.5 3.5 0 003.5 3.5zm0-5A1.5 1.5 0 1114.5 7 1.5 1.5 0 0116 5.5z"
                                  data-original="#000000"
                                ></path>
                              </g>
                            </svg>
                          </a>
                        ) : null}
                        {/* {withdrawals.status == 0 ? (
                          <Button
                            className="cancel-btn"
                            onClick={() => cancelWithdrawal(withdrawals.id)}
                          >
                            {t("withdraw_table.cancel")}
                          </Button>
                        ) : withdrawals.status == 1 ? (
                          <div className="completed-btn">
                            {t("withdraw_table.completed")}
                          </div>
                        ) : (
                          <div className="rejected-btn ">
                            {t("withdraw_table.rejected")}
                          </div>
                        )} */}
                        <Button
                          className="cancel-btn"
                          onClick={() => handleRefresh(withdrawals)}
                        >
                          {withdrawalStatus.buttonDisable &&
                          withdrawals.id == withdrawalId
                            ? "Loading"
                            : "Refresh"}
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {withDrawals.data.total_user_withdrawals > 12 ? (
            <div className="new-billings-pagination-sec">
              <Pagination
                page={page}
                pageCount={Math.ceil(
                  withDrawals.data.total_user_withdrawals / 12
                )}
                handlePageClick={handlePageClick}
              />
            </div>
          ) : null}
        </React.Fragment>
      ) : (
        <NoDataFound />
      )}
      {modalShow && (
        <CancelWithdrawalModal
          withdrawalId={withdrawalId}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  wallet: state.wallet.singleWallet,
  transaction: state.wallet.allTransaction,
  userWithdrawalsExport: state.buy.userWithdrawalsExport,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(mapStateToPros, mapDispatchToProps)(WithDrawHistory);
