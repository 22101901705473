import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import configuration from "react-global-configuration";
import CustomLazyLoad from "../helper/CustomLazyLoad";

const HowItsWork = (props) => {

  const t = useTranslation("how_its_work")

  return (
    <>
      <div className="how-its-work-sec sm-padding">
        <Container>
          <div className="how-its-work-box" data-aos="fade-up">
            <div className="how-its-work-card" data-aos="fade-up">
              <div className="how-its-work-icon-sec">
                <CustomLazyLoad
                  className="how-its-work-icon"
                  src={
                    window.location.origin + "/img/landing/how-its-work/earn.svg"
                  }
                  type="image/png"
                />
              </div>
              <div className="how-its-work-info">
                <h4>{t("earn")}</h4>
                <p>{t("amplify_your_portfolio_para")} {configuration.get("configData.site_name")}'{t("earning ecosystem_para")}</p>
                <div className="learn-more-btn-sec">
                  <Link to="#" className="learn-more-btn">
                    {t("learn_more")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 23 22"
                    >
                      <path
                        stroke="#E89900"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M4.701 11.246h12.951m-4.55 4.701l4.692-4.701-4.693-4.701"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
            <div className="how-its-work-card" data-aos="fade-up">
              <div className="how-its-work-icon-sec">
                <CustomLazyLoad
                  className="how-its-work-icon"
                  src={
                    window.location.origin + "/img/landing/how-its-work/finance.svg"
                  }
                  type="image/png"
                />
              </div>
              <div className="how-its-work-info">
                <h4>{t("finance")}</h4>
                <p>{t("streamline_your_financial")} {configuration.get("configData.site_name")}'
                  {t("intuitive_suite_para")} {configuration.get("configData.site_name")} {t("ensures_your_transactions_para")}</p>
                <div className="learn-more-btn-sec">
                  <Link to="#" className="learn-more-btn">
                    {t("learn_more")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 23 22"
                    >
                      <path
                        stroke="#E89900"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M4.701 11.246h12.951m-4.55 4.701l4.692-4.701-4.693-4.701"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
            <div className="how-its-work-card" data-aos="fade-up">
              <div className="how-its-work-icon-sec">
                <CustomLazyLoad
                  className="how-its-work-icon"
                  src={
                    window.location.origin + "/img/landing/how-its-work/invest.svg"
                  }
                  type="image/png"
                />
              </div>
              <div className="how-its-work-info">
                <h4>{t("invest")}</h4>
                <p>{t("venture_into_the_vast_para")} {configuration.get("configData.site_name")}.
                  {t("engage_with_a_spectrum_of_assets_para")}</p>
                <div className="learn-more-btn-sec">
                  <Link to="#" className="learn-more-btn">
                    {t("learn_more")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 23 22"
                    >
                      <path
                        stroke="#E89900"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M4.701 11.246h12.951m-4.55 4.701l4.692-4.701-4.693-4.701"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default HowItsWork;
