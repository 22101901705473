import React, { useEffect, useState } from "react";
import { Container, Image } from "react-bootstrap";
import ExchangeRateSec from "./ExchangeRateSec";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateBuyData } from "../../store/actions/UserAction";
import "./Buy.css";
import CardIndex from "../Card/CardIndex";
import { useTranslation } from "react-multi-lang";
import TokenBuy from "./TokenBuySec";
import BuySuccess from "./BuySuccess";
import BuyCryptoForm from "../NewLanding/BuyCryptoForm";

const BuyIndex = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("buy_index");
  const buyData = useSelector((state) => state.user.buyData);
  const tokenBuyData = useSelector((state) => state.buy.tokenBuyData);
  const login = useSelector((state) => state.user.login);
  const setBuyData = (data) => dispatch(updateBuyData(data));
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("token"));
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (buyData.step == 6) {
      setBuyData({
        ...buyData,
        step: 1,
      });
    }
  }, []);

  useEffect(() => {
    if (!skipRender && !login.loading && Object.keys(login.data).length > 0) {
      setIsLoggedIn(localStorage.getItem("token"));
    }
    setSkipRender(false);
  }, [login]);

  useEffect(() => {
    setIsLoggedIn(localStorage.getItem("token"));
  }, [localStorage.getItem("token")]);

  return (
    <>
      <div className="exchange-sec">
        <Container>
          <div className="buy-crypto-sec">
            <Row className="justify-content-md-center">
              <Col md={10} xl={6} lg={7}>
                {/* <div className="exchange-box landing-page"> */}
                <div className="buy-flow-product-sec">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="swap-crypto"
                  ></Tab.Container>
                  {tokenBuyData.step == 1 ? (
                    <div className="step-1">
                      <BuyCryptoForm />
                    </div>
                  ) : tokenBuyData.step == 2 ? (
                    <div className="step-2">
                      <TokenBuy />
                    </div>
                  ) : tokenBuyData.step == 3 ? (
                    <div className="step-3">
                      <BuySuccess />
                    </div>
                  ) : tokenBuyData.step >= 4 ? (
                    <div className="step-4">
                      <CardIndex />
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default BuyIndex;
