import React from "react";

import { Image } from "react-bootstrap";
 
const PageLoader = ({ main = false }) => {
  return (
    <div
      className="main-wrapper"
      style={{
        width: "100%",
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <Bars
        height="200"
        width="200"
        color="#E89900"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        /> */}
           <Image
                    className="loader-spinner"
                    src={
                      window.location.origin + "/img/gif/loader.svg"
                    }
                    type="image/png"
        //             wrapperStyle={{}}
        // wrapperClass=""
        // visible={true}
                  />
    </div>
  );
};

export default PageLoader;