import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
} from "react-bootstrap";
import {
  tokenBuyTransactionsListStart,
  moreTokenBuyTransactionsListStart,
} from "../../store/actions/BuyAction";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../../components/helper/ToastNotification";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../helper/NoDataFound";
import { useTranslation } from "react-multi-lang";

const TokenTransactionsIndex = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("transaction_list");
  const tokenBuyTransactionsList = useSelector((state) => state.buy.tokenBuyTransactionsList);

  useEffect(() => {
    dispatch(
        tokenBuyTransactionsListStart({
        skip: 0,
        take: 12,
      })
    );
  }, []);

  const fetchMoreOffers = () => {
    dispatch(
      moreTokenBuyTransactionsListStart({
        skip: tokenBuyTransactionsList.data.token_transactions.length,
        take: 12,
      })
    );
  };

  const onCopy = (event, type) => {
    getSuccessNotificationMessage(`${type} t("as_copied_to_clipboard)`);
  };

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return t("transaction_initiated");
      case 1:
        return t("transaction_success");
      case 2:
        return t("transaction_failed");
      default:
        return t("transaction_initiated");
    }
  };

  return (
    <>
      <div className="login-sec">
        <div className="login-box-1">
          <Container>
            <Row className="justify-content-md-center">
              <Col md={12}>
                {tokenBuyTransactionsList.loading ? (
                  <div className="exchange-box">
                    <div className="exchange-card exchange-history-list-card">
                      <Skeleton count={5} height={100} />
                    </div>
                  </div>
                ) : Object.keys(tokenBuyTransactionsList.data).length > 0 &&
                tokenBuyTransactionsList.data.token_transactions.length > 0 ? (
                  <div className="exchange-box">
                    <div className="exchange-card exchange-history-list-card">
                      <InfiniteScroll
                        dataLength={tokenBuyTransactionsList.data.token_transactions.length}
                        next={fetchMoreOffers}
                        hasMore={
                            tokenBuyTransactionsList.data.token_transactions.length <
                            tokenBuyTransactionsList.data.total_token_transactions
                        }
                        loader={
                          <div className="d-flex justify-content-center mb-5">
                            {/* <CenterLoader /> */}
                          </div>
                        }
                      >
                        <Table borderedless responsive>
                          <thead>
                            <tr>
                              <th>{t("payment_id")}</th>
                              <th>{t("amount")}</th>
                              <th>{t("wallet_address")}</th>
                              <th>{t("txt_hash")}</th>
                              <th>{t("status")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tokenBuyTransactionsList.data.token_transactions.map(
                              (transaction, i) => (
                                <tr>
                                  <td>
                                    <div className="exchange-amount-sec">
                                      <h5>#{transaction.order_id}</h5>
                                      <div
                                        className={`text-capitalize ${getStatus(
                                          transaction.type == "buy" ? 1 : 0
                                        )}`}
                                      >
                                        {transaction.type}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="exchange-amount-sec">
                                      <h5>
                                        {transaction.tokens}{" "}
                                        {transaction.token_type}
                                      </h5>
                                      <h5>
                                        {transaction.amount}{" "}
                                        {transaction.amount_type}
                                      </h5>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="exchnage-wallet-address-sec">
                                      <div className="exchange-wallet-address-card">
                                        <h5>
                                          From
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 24 24"
                                          >
                                            <path d="M13.1 19.5a1.5 1.5 0 01-1.061-2.561l4.586-4.585a.5.5 0 000-.708l-4.582-4.585a1.5 1.5 0 012.121-2.122l4.586 4.586a3.505 3.505 0 010 4.95l-4.586 4.586a1.5 1.5 0 01-1.064.439z"></path>
                                            <path d="M6.1 19.5a1.5 1.5 0 01-1.061-2.561L9.982 12 5.043 7.061a1.5 1.5 0 012.121-2.122l6 6a1.5 1.5 0 010 2.122l-6 6A1.5 1.5 0 016.1 19.5z"></path>
                                          </svg>
                                          {transaction.from_wallet_address
                                            ? `${transaction.from_wallet_address.substr(
                                              0,
                                              5
                                            )}...${transaction.from_wallet_address.substr(
                                              transaction.from_wallet_address
                                                .length - 4
                                            )}`
                                            : t("n_a")}
                                        </h5>
                                        {transaction.from_wallet_address && (
                                          <CopyToClipboard
                                            text={
                                              transaction.from_wallet_address
                                            }
                                            onCopy={(e) =>
                                              onCopy(e, t("from_wallet_address"))
                                            }
                                          >
                                            <Button className="copy-btn">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M21.155 3.272L18.871.913A3.02 3.02 0 0016.715 0H12a5.009 5.009 0 00-4.9 4H7a5.006 5.006 0 00-5 5v10a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5v-.1a5.009 5.009 0 004-4.9V5.36a2.988 2.988 0 00-.845-2.088zM13 22H7a3 3 0 01-3-3V9a3 3 0 013-3v8a5.006 5.006 0 005 5h4a3 3 0 01-3 3zm4-5h-5a3 3 0 01-3-3V5a3 3 0 013-3h4v2a2 2 0 002 2h2v8a3 3 0 01-3 3z"></path>
                                              </svg>
                                            </Button>
                                          </CopyToClipboard>
                                        )}
                                      </div>

                                      <div className="exchange-wallet-address-card">
                                        <h5>
                                          {t("to")}
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 24 24"
                                          >
                                            <path d="M13.1 19.5a1.5 1.5 0 01-1.061-2.561l4.586-4.585a.5.5 0 000-.708l-4.582-4.585a1.5 1.5 0 012.121-2.122l4.586 4.586a3.505 3.505 0 010 4.95l-4.586 4.586a1.5 1.5 0 01-1.064.439z"></path>
                                            <path d="M6.1 19.5a1.5 1.5 0 01-1.061-2.561L9.982 12 5.043 7.061a1.5 1.5 0 012.121-2.122l6 6a1.5 1.5 0 010 2.122l-6 6A1.5 1.5 0 016.1 19.5z"></path>
                                          </svg>
                                          {transaction.receiver_wallet_address
                                            ? `${transaction.receiver_wallet_address.substr(
                                              0,
                                              5
                                            )}...${transaction.receiver_wallet_address.substr(
                                              transaction
                                                .receiver_wallet_address
                                                .length - 4
                                            )}`
                                            : t("n_a")}
                                        </h5>
                                        {transaction.receiver_wallet_address && (
                                          <CopyToClipboard
                                            text={
                                              transaction.receiver_wallet_address
                                            }
                                            onCopy={(e) =>
                                              onCopy(
                                                e,
                                                t("receiver_wallet_address")
                                              )
                                            }
                                          >
                                            <Button className="copy-btn">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M21.155 3.272L18.871.913A3.02 3.02 0 0016.715 0H12a5.009 5.009 0 00-4.9 4H7a5.006 5.006 0 00-5 5v10a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5v-.1a5.009 5.009 0 004-4.9V5.36a2.988 2.988 0 00-.845-2.088zM13 22H7a3 3 0 01-3-3V9a3 3 0 013-3v8a5.006 5.006 0 005 5h4a3 3 0 01-3 3zm4-5h-5a3 3 0 01-3-3V5a3 3 0 013-3h4v2a2 2 0 002 2h2v8a3 3 0 01-3 3z"></path>
                                              </svg>
                                            </Button>
                                          </CopyToClipboard>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="exchange-transaction-hash-sec">
                                      <div className="exchange-wallet-address-card">
                                        <h5>
                                          {t("from")}
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 24 24"
                                          >
                                            <path d="M13.1 19.5a1.5 1.5 0 01-1.061-2.561l4.586-4.585a.5.5 0 000-.708l-4.582-4.585a1.5 1.5 0 012.121-2.122l4.586 4.586a3.505 3.505 0 010 4.95l-4.586 4.586a1.5 1.5 0 01-1.064.439z"></path>
                                            <path d="M6.1 19.5a1.5 1.5 0 01-1.061-2.561L9.982 12 5.043 7.061a1.5 1.5 0 012.121-2.122l6 6a1.5 1.5 0 010 2.122l-6 6A1.5 1.5 0 016.1 19.5z"></path>
                                          </svg>
                                          {transaction.transaction_hash
                                            ? `${transaction.transaction_hash.substr(
                                              0,
                                              5
                                            )}...${transaction.transaction_hash.substr(
                                              transaction.transaction_hash
                                                .length - 4
                                            )}`
                                            : t("n_a")}
                                        </h5>
                                        {transaction.transaction_hash && (
                                          <CopyToClipboard
                                            text={transaction.transaction_hash}
                                            onCopy={(e) =>
                                              onCopy(e, t("transaction_hash"))
                                            }
                                          >
                                            <Button className="copy-btn">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M21.155 3.272L18.871.913A3.02 3.02 0 0016.715 0H12a5.009 5.009 0 00-4.9 4H7a5.006 5.006 0 00-5 5v10a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5v-.1a5.009 5.009 0 004-4.9V5.36a2.988 2.988 0 00-.845-2.088zM13 22H7a3 3 0 01-3-3V9a3 3 0 013-3v8a5.006 5.006 0 005 5h4a3 3 0 01-3 3zm4-5h-5a3 3 0 01-3-3V5a3 3 0 013-3h4v2a2 2 0 002 2h2v8a3 3 0 01-3 3z"></path>
                                              </svg>
                                            </Button>
                                          </CopyToClipboard>
                                        )}
                                      </div>
                                      {transaction.swap_transaction_hash && (
                                        <div className="exchange-wallet-address-card">
                                          <h5>
                                           {t("to")}
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M13.1 19.5a1.5 1.5 0 01-1.061-2.561l4.586-4.585a.5.5 0 000-.708l-4.582-4.585a1.5 1.5 0 012.121-2.122l4.586 4.586a3.505 3.505 0 010 4.95l-4.586 4.586a1.5 1.5 0 01-1.064.439z"></path>
                                              <path d="M6.1 19.5a1.5 1.5 0 01-1.061-2.561L9.982 12 5.043 7.061a1.5 1.5 0 012.121-2.122l6 6a1.5 1.5 0 010 2.122l-6 6A1.5 1.5 0 016.1 19.5z"></path>
                                            </svg>
                                            {transaction.swap_transaction_hash
                                              ? `${transaction.swap_transaction_hash.substr(
                                                0,
                                                5
                                              )}...${transaction.swap_transaction_hash.substr(
                                                transaction
                                                  .swap_transaction_hash
                                                  .length - 4
                                              )}`
                                              : t("n_a")}
                                          </h5>
                                          <CopyToClipboard
                                            text={
                                              transaction.swap_transaction_hash
                                            }
                                            onCopy={(e) =>
                                              onCopy(e, t("swap_transaction_hash"))
                                            }
                                          >
                                            <Button className="copy-btn">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M21.155 3.272L18.871.913A3.02 3.02 0 0016.715 0H12a5.009 5.009 0 00-4.9 4H7a5.006 5.006 0 00-5 5v10a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5v-.1a5.009 5.009 0 004-4.9V5.36a2.988 2.988 0 00-.845-2.088zM13 22H7a3 3 0 01-3-3V9a3 3 0 013-3v8a5.006 5.006 0 005 5h4a3 3 0 01-3 3zm4-5h-5a3 3 0 01-3-3V5a3 3 0 013-3h4v2a2 2 0 002 2h2v8a3 3 0 01-3 3z"></path>
                                              </svg>
                                            </Button>
                                          </CopyToClipboard>
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="exchange-status-sec">
                                      <div className="exchange-status-card">
                                        <div
                                          className={`${getStatus(
                                            transaction.stylopay_status
                                          )}`}
                                        >
                                          {t("stylopay")}{" "}
                                          {
                                            transaction.stylopay_status_formatted
                                          }
                                        </div>
                                      </div>
                                      <div className="exchange-status-card">
                                        <div
                                          className={`${getStatus(
                                            transaction.status
                                          )}`}
                                        >
                                          {t("token_transfer")}{" "}
                                          {transaction.status_formatted}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </InfiniteScroll>
                    </div>
                  </div>
                ) : (
                  <div className="exchange-box">
                    <div className="exchange-card exchange-history-list-card">
                      <NoDataFound />
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default TokenTransactionsIndex;
