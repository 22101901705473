import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Static.css";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { fetchStaticPageStart } from "../../store/actions/PageAction";
import { useDispatch } from "react-redux";
import StaticPageLoader from "../helper/StaticPageLoader";
import SomethingWentWrong from "../Common/SomethingWentWrong";

const StaticIndex = (props) => {
  const dispatch = useDispatch();

  const { static_page_unique_id } = useParams();

  useEffect(() => {
    if (static_page_unique_id) {
      dispatch(fetchStaticPageStart({ static_page_unique_id: static_page_unique_id }));
    }
  }, [static_page_unique_id]);

  return (
    <>
      <div className="static-pag-sec">
        <Container>
          {props.pageData.loading ? (
            <StaticPageLoader />
          ) : Object.keys(props.pageData.data).length > 0 ? (
            <>
              <Row className="justify-content-md-center">
                <Col md={8}>
                  <div className="section-title">
                    <h1>{props.pageData.data.title}</h1>
                  </div>
                </Col>
              </Row>
              <div className="static-box">
                <Row>
                  <Col md={12}>
                    <div className="static-card">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: props.pageData.data.description,
                        }}
                      ></p>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <SomethingWentWrong />
          )}
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  pageData: state.page.pageData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(StaticIndex);
