import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetBuyData } from "../../store/actions/UserAction";
import { Form, InputGroup, Button, Image } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../../components/helper/ToastNotification";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import { resetTokenBuyData } from "../../store/actions/BuyAction";
import { setTokenSellData } from '../../store/actions/BuyAction';
import CustomLazyLoad from "../helper/CustomLazyLoad";

const SellSuccess = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation("buy_index");
  const tokenSell = useSelector(state => state.buy.tokenSell);
  const tokenSellData = useSelector(state => state.buy.tokenSellData);
  const updateTokenSellData = data => dispatch(setTokenSellData(data));

  const onCopy = (event, type) => {
    getSuccessNotificationMessage(`${type} was copied to clipboard!`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      dispatch(resetBuyData());
      dispatch(resetTokenBuyData());
    }
  }, []);

  return (
    <>
      <div className="buy-processing-img-sec">
      <CustomLazyLoad
          src={window.location.origin + "/img/buy/payment-success.gif"}
          className="buy-success-img"
        />
      </div>
      <div className="payment-processing-countdown-card">
        <div className="payment-success-text">{t("payment_successful")}</div>
      </div>
      {Object.keys(tokenSell.data).length > 0 && (
        <>
          <div className="exchange-form">
          <h6 className="mt-3">{t("transaction_hash")}</h6>
          <InputGroup>
            <Form.Control
              placeholder="763ehj fewjebciwetr78324bfjbfwj3kefnw"
              value={tokenSell.data.sell_transaction.transaction_hash}
            />
            <InputGroup.Text id="basic-addon2">
              <CopyToClipboard
                text={tokenSell.data.sell_transaction.transaction_hash}
                onCopy={(e) => onCopy(e, t("transaction_hash"))}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                >
                  <path d="M21.155 3.272L18.871.913A3.02 3.02 0 0016.715 0H12a5.009 5.009 0 00-4.9 4H7a5.006 5.006 0 00-5 5v10a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5v-.1a5.009 5.009 0 004-4.9V5.36a2.988 2.988 0 00-.845-2.088zM13 22H7a3 3 0 01-3-3V9a3 3 0 013-3v8a5.006 5.006 0 005 5h4a3 3 0 01-3 3zm4-5h-5a3 3 0 01-3-3V5a3 3 0 013-3h4v2a2 2 0 002 2h2v8a3 3 0 01-3 3z"></path>
                </svg>
              </CopyToClipboard>
            </InputGroup.Text>
          </InputGroup>
          <h6 className="mt-3">{t("order_id")}</h6>
          <InputGroup>
            <Form.Control
              placeholder="763ehj fewjebciwetr78324bfjbfwj3kefnw"
              value={tokenSell.data.sell_transaction.order_id}
            />
            <InputGroup.Text id="basic-addon2">
              <CopyToClipboard
                text={tokenSell.data.sell_transaction.order_id}
                onCopy={(e) => onCopy(e, t("order_id"))}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                >
                  <path d="M21.155 3.272L18.871.913A3.02 3.02 0 0016.715 0H12a5.009 5.009 0 00-4.9 4H7a5.006 5.006 0 00-5 5v10a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5v-.1a5.009 5.009 0 004-4.9V5.36a2.988 2.988 0 00-.845-2.088zM13 22H7a3 3 0 01-3-3V9a3 3 0 013-3v8a5.006 5.006 0 005 5h4a3 3 0 01-3 3zm4-5h-5a3 3 0 01-3-3V5a3 3 0 013-3h4v2a2 2 0 002 2h2v8a3 3 0 01-3 3z"></path>
                </svg>
              </CopyToClipboard>
            </InputGroup.Text>
          </InputGroup>
          </div>
        </>
      )}
      <div className="exchange-btn-sec">
        <Button
          className="default-btn"
          onClick={() => {
            Object.keys(tokenSell.data).length > 0 && tokenSell.data.user_wallet_id ?
              <>
                {(updateTokenSellData({
                  ...tokenSellData,
                  step: 1,
                }),
                  navigate(`/transactions`))}
              </>
              :
              navigate('/');
          }}
        >
          {t("continue")}
        </Button>
      </div>
    </>
  );
};

export default SellSuccess;
