import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";

const ExperienceCatalyzesGrowth = (props) => {

  const t = useTranslation("experience_catalyze")

  return (
    <>
      <div className="growth-of-exchange-sec bg-privacy-protection sm-padding">
        <Container>
          <Row className="align-items-center">
            <Col xl={6}>
              <div className="growth-of-exchange-info">
                <h2 data-aos="fade-up">{t("privacy_and_protection")} <span>{t("the_dual_pillars_of_your_trading_experience")}</span></h2>
              </div>
              <div className="growth-of-exchange-list-box">
                <div className="growth-of-exchange-list-card" data-aos="fade-up">
                  <div className="growth-of-exchange-list-icon-sec">
                    <Image
                      className="growth-of-exchange-list-icon"
                      src={
                        window.location.origin + "/img/landing/experience-catalyzes-growth/experienc-catalyzes-growth-icon-1.svg"
                      }
                      type="image/png"
                    />
                  </div>
                  <div className="growth-of-exchange-list-info">
                    <h4>{t("multi_level_security_safeguard")}</h4>
                    <p>{t("fortify_your_inestments_para")}</p>
                  </div>
                </div>
                <div className="growth-of-exchange-list-card" data-aos="fade-up">
                  <div className="growth-of-exchange-list-icon-sec">
                    <Image
                      className="growth-of-exchange-list-icon"
                      src={
                        window.location.origin + "/img/landing/experience-catalyzes-growth/experienc-catalyzes-growth-icon-2.svg"
                      }
                      type="image/png"
                    />
                  </div>
                  <div className="growth-of-exchange-list-info">
                    <h4>{t("confidentiality_assurance_measures")}</h4>
                    <p>{t("privacy_is_paramount_para")}</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={6}>
              <div className="growth-of-exchange-img-sec">
                <Image
                data-aos="fade-up" className="growth-of-exchange-img"
                  src={
                    window.location.origin + "/img/gif/privacy-protection.gif"
                  }
                  type="image/png"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ExperienceCatalyzesGrowth;
