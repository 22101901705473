import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Static.css";


const TermsConditions = () => {

  return (
    <>
      <div className="static-pag-sec">
        <Container>
          <>
            <Row className="justify-content-md-center">
              <Col md={8}>
                <div className="section-title">
                  <h1>Privacy Policy</h1>
                </div>
              </Col>
            </Row>
            <div className="static-box">
              <Row>
                <Col md={12}>
                  <div className="static-card">
                    <div class="privacy-policy-details">
                      <h4>Privacy Policy for Herald Exchange</h4>
                      <p>Welcome to Herald Exchange! This Privacy Policy outlines how Herald Exchange ("we," "our," or "us") collects, uses, discloses, and protects the information we collect from users of our platform. By using Herald Exchange, you agree to the terms outlined in this Privacy Policy.</p>
                    </div>
                    <div class="privacy-policy-details">
                      <h4>Information we collect</h4>
                      <p><b>Personal Information: </b>We may collect personal information from users, including but not limited to names, email addresses, and contact information when you register for an account.</p>
                      <p><b>Transaction Information: </b>When you engage in transactions on Herald Exchange, we collect information about the transactions, including the details of the assets exchanged.</p>
                      <p><b>Usage Data: </b>We collect information about your use of the platform, including log data, IP addresses, browser type, and device informatio</p>
                    </div>
                    <div class="privacy-policy-details">
                      <h4>How we use your information</h4>
                      <p><b>Providing Services: </b>We use the collected information to provide and improve our services, authenticate users, and facilitate transactions on the platform.</p>
                      <p><b>Communication: </b>We may use your contact information to communicate with you about updates, promotions, and important information related to Herald Exchange. You can opt-out of promotional communications.</p>
                      <p><b>Security: </b> We use the information to enhance the security of our platform, detect and prevent fraud, and ensure the integrity of our services.</p>
                    </div>
                    <div class="privacy-policy-details">
                      <h4>Information Sharing</h4>
                      <p><b>Third-Party Service Providers: </b> We may share your information with third-party service providers who assist us in providing and improving our services.</p>
                      <p><b>Legal Compliance: </b>We may disclose your information to comply with legal obligations, enforce our policies, and respond to legal requests.</p>
                    </div>
                    <div class="privacy-policy-details">
                      <h4>Your Choices</h4>
                      <p><b>Account Information: </b>You can review and update your account information by logging into your account on Herald Exchange.</p>
                      <p><b>Opt-Out: </b> You can opt-out of receiving promotional communications by following the instructions provided in the communication.</p>
                    </div>
                    <div class="privacy-policy-details">
                      <h4>Security</h4>
                      <p>We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, and destruction.</p>
                    </div>
                    <div class="privacy-policy-details">
                      <h4>Changes to This Privacy Policy</h4>
                      <p>We may update this Privacy Policy to reflect changes in our practices. We will notify users of any material changes through our platform.</p>
                    </div>
                    <div class="privacy-policy-details">
                      <h4>Contact Us</h4>
                      <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="contact@efiexchange.com">contact@efiexchange.com</a></p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        </Container>
      </div>
    </>
  );
};



export default TermsConditions;
