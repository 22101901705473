import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, Modal, Row, Col } from "react-bootstrap";
import '../Wallet/Wallet.css';
import { connect } from 'react-redux';
import * as Yup from "yup";
import { profileUpdateStart } from "../../store/actions/UserAction";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { useTranslation } from 'react-multi-lang';
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { isPossiblePhoneNumber } from 'react-phone-number-input';

const EditProfileModal = (props) => {

  const t = useTranslation("profile");
  const formRef = useRef(null);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
    name: Yup.string().required(t("full_name.required")),
    website_url: Yup.string().required(t("website_url.required"))
      .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, t('website_url.invalid_url')),
    mobile: Yup.string()
      // .required(t("phone.required"))
      .test("valid-mobile", t("mobile.invalid"), (value) => {
        if (!value) {
          return false;
        }
        return isValidPhoneNumber(value);
      })
      .matches(/^(?=.*[0-9])/, t("phone.space_values_not_valid")),
    city: Yup.string()
      // .required(t("city.required"))
      .matches(/^\S.*$/, t("city.required")).trim()
      .matches(/^[A-Za-z ]+$/, t("city.invalid_city")),
    state: Yup.string()
      // .required(t("state.required"))
      .matches(/^\S.*$/, t("city.required")).trim()
      .matches(/^[A-Za-z ]+$/, t("state.invalid_state")),
    country: Yup.string()
      // .required(t("country.required"))
      .matches(/^\S.*$/, t("country.required")).trim()
      .matches(/^[A-Za-z ]+$/, t("country.invalid_country")),
    zipcode: Yup.string()
      .matches(/^[a-zA-Z0-9\s]+$/, t('invalid_zipcode')),
    primary_address: Yup.string()
      // .required(t("primary_address.required"))
      .trim()
      .notOneOf([''])
      .min(10, t("primary_address.min_length")),
    secondary_address: Yup.string()
      // .required(t("secondary_address.required"))
      .trim()
      .notOneOf([''])
      .min(10, t("secondary_address.min_length")),
  });

  const validateMobileNumber = (value) => {
    if (value) {
      if (isPossiblePhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile.invalid"));
        return false;
      } else if (isValidPhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile.invalid"));
        return false;
      } else {
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", t("mobile_number.required"));
      return false;
    }
  };

  const handleSubmit = (values) => {
    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");
      props.dispatch(profileUpdateStart({
        ...values,
        mobile: mobile,
        country_code: countryCode,
      }));
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="new-modal-custome"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4> {t("edit_billing_address")}</h4>
          <Formik
            initialValues={{
              name: props.profile.data.name,
              email: props.profile.data.email,
              website_url: props.profile.data.website_url || "",
              mobile: ((`+${props.profile.data.country_code}` || "+91") + props.profile.data.mobile) || "",
              primary_address: props.profile.data.primary_address || "",
              secondary_address: props.profile.data.secondary_address || "",
              city: props.profile.data.city || "",
              state: props.profile.data.state || "",
              country: props.profile.data.country || "",
              zipcode: props.profile.data.zipcode || "",
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            innerRef={formRef}
          >
            {({ values, setFieldValue, setFieldTouched, errors, touched, isValid }) => (
              <FORM className="new-feature-form mt-4">
                <Row>
                  <Col lg={6}>
                    <Form.Group className="mb-3 register-phone-input">
                      <Form.Label
                        htmlFor="mobile"
                        className="form-label"
                      >
                        {t("mobile.label")}
                      </Form.Label>
                      <PhoneInput
                        defaultCountry="IN"
                        placeholder={t("mobile_number.placeholder")}
                        value={values.mobile}
                        onChange={(value) =>
                          setFieldValue("mobile", value)
                        }
                        onBlur={() => formRef.current.submitForm()}
                        international
                        countryCallingCodeEditable={false}
                        className={`${touched.mobile && errors.mobile
                          ? "is-invalid"
                          : ""
                          }`}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="mobile"
                        className="errorMsg"
                      />
                      {values.mobile == undefined && <p className='text-danger'>{t("mobile.invalid_mobile")}</p>}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group controlId="formBasicPrimaryAddress" className="mb-3">
                      <Form.Label>
                        {t("primary_address.label")}
                      </Form.Label>
                      <Field
                        as="textarea"
                        rows="2"
                        type="text"
                        className="form-control"
                        name="primary_address"
                        placeholder={t("primary_address.placeholder")}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="primary_address"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group controlId="formBasicSecondaryAddress" className="mb-3">
                      <Form.Label>
                        {t("secondary_address.label")}
                      </Form.Label>
                      <Field
                        as="textarea"
                        rows="2"
                        type="text"
                        className="form-control"
                        name="secondary_address"
                        placeholder={t("secondary_address.placeholder")}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="secondary_address"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group controlId="formBasicCity" className="mb-3">
                      <Form.Label>
                        {t("city.label")}
                      </Form.Label>
                      <Field
                        type="text"
                        className="form-control"
                        name="city"
                        placeholder={t("city.placeholder")}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="city"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group controlId="formBasicState" className="mb-3">
                      <Form.Label>
                        {t("state.label")}
                      </Form.Label>
                      <Field
                        type="text"
                        className="form-control"
                        name="state"
                        placeholder={t("state.placeholder")}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="state"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group controlId="formBasicCountry" className="mb-3">
                      <Form.Label>
                        {t("country.label")}
                      </Form.Label>
                      <Field
                        type="text"
                        className="form-control"
                        name="country"
                        placeholder={t("country.placeholder")}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="country"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group controlId="formBasicZipcode" className="mb-3">
                      <Form.Label>
                        {t("zipcode.label")}
                      </Form.Label>
                      <Field
                        type="text"
                        className="form-control"
                        name="zipcode"
                        placeholder={t("zipcode.placeholder")}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="zipcode"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="new-action-frame layout-element">
                  <Button
                    className="new-overlay-btn"
                    type='button'
                    onClick={props.onHide}>
                    {t("cancel")}
                  </Button>
                  <Button
                    className="new-primary-btn"
                    type="submit"
                    disabled={props.updateProfile.buttonDisable}
                    onClick={() => handleSubmit(values)}
                  >
                    {props.updateProfile.buttonDisable
                      ? t("loading")
                      : t("save_changes")}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
};

const mapStateToPros = (state) => ({
  profile: state.user.profile,
  updateProfile: state.user.updateProfile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(
  mapStateToPros,
  mapDispatchToProps
)(EditProfileModal);
