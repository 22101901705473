import React, { useState } from "react";
import { Container, Button, Row, Form, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

const ContactUsPageIndex = (props) => {
  return (
    <>
      <div className="contact-us-sec sm-padding">
        <Container>
          <Row className="align-items-center">
            <Col md={12} xl={6}>
              <div className="contact-us-form-box">
                <h3>Contact Us</h3>
                <Form className="contact-us-form" method="post">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control type="number" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control type="email" />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Form.Group>
                  <div className="contact-us-submit-btn-sec">
                    <Button className="new-default-primary-btn w-100">
                      Submit
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 23 22"
                        >
                        <rect
                            width="22.496"
                            height="21.492"
                            fill="#fff"
                            rx="10.746"
                            transform="matrix(1 0 0 -1 0 21.492)"
                        ></rect>
                        <path
                            stroke="#E89900"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4.701 10.746h12.951m-4.55 4.701l4.692-4.701-4.693-4.701"
                        ></path>
                      </svg>
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
            <Col md={12} xl={6}>
              <div className="contact-us-img-sec">
                <Image
                  className="contact-us-img"
                  src={
                    window.location.origin +
                    "/img/landing/contact-us/contact-us-img.png"
                  }
                  type="image/png"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ContactUsPageIndex;
