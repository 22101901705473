import React, { useEffect, useCallback, useState, useRef } from "react";
import { Button, Form, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { updateBuyData } from "../../store/actions/UserAction";
import { useTranslation } from "react-multi-lang";
import {
  buyTokensViaWalletStart,
  cardTransactionsStart,
  getAdminBankAccountStart,
  tokenBuyStart,
} from "../../store/actions/BuyAction";
import CommonCenterLoader from "../helper/CommonCenterLoader";
import { useDropzone } from "react-dropzone";
import { setTokenBuyData } from "../../store/actions/BuyAction";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getErrorNotificationMessage } from "../helper/ToastNotification";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import { fetchWalletDetailsStart } from "../../store/actions/WalletAction";

const TokenBuy = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("buy_index");
  const navigate = useNavigate();
  const formRef = useRef(null);
  const tokenBuyData = useSelector((state) => state.buy.tokenBuyData);
  const buyData = useSelector((state) => state.user.buyData);
  const setBuyData = (data) => dispatch(updateBuyData(data));
  const adminBankAccount = useSelector((state) => state.buy.adminBankAccount);
  const cardTransactions = useSelector((state) => state.buy.cardTransactions);
  const buyTokensViaWallet = useSelector(
    (state) => state.buy.buyTokensViaWallet
  );
  const updateTokenBuyData = (data) => dispatch(setTokenBuyData(data));
  const tokenBuy = useSelector((state) => state.buy.tokenBuy);
  const wallet = useSelector((state) => state.wallet.walletData);
  const [errorMessage, setErrorMessage] = useState(false);
  const [paymentType, setPaymentType] = useState("bank");
  const [cardNumber, setCardNumber] = useState("");
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    payment_id: Yup.string()
      .required(t("payment_id.required"))
      .matches(/^(?=.*[a-zA-Z-0-9])/, t("invalid"))
      .min(3, t("payment_id.invalid")),
    payment_file: Yup.string().required(t("please_upload_payment_screenshot")),
  });

  const cardSchema = Yup.object().shape({
    card_number: Yup.string()
      .required("Card number is required")
      .test(
        "len",
        "Card number must be a 16-digit number",
        (val) => String(val).replace(/\s+/g, "").length === 16
      ),
    cvv: Yup.string()
      .required("CVV is required")
      .matches(/^\d{3,5}$/, "CVV must be a 3-digit number"),
    expiry_month: Yup.string()
      .required("Expiry month is required")
      .matches(
        /^(0[1-9]|1[0-2])$/,
        "Expiry month must be a two-digit number between 01 and 12"
      ),
    expiry_year: Yup.number()
      .required("Expiry year is required")
      .min(
        new Date().getFullYear(),
        `Expiry year must be greater than or equal to ${new Date().getFullYear()}`
      )
      .typeError("Expiry year must be a four-digit number")
      .integer("Expiry year must be a four-digit number")
      .test(
        "len",
        "Expiry year must be a four-digit number",
        (val) => String(val).length === 4
      ),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getAdminBankAccountStart({
        currency: tokenBuyData.buy_via_currency,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(fetchWalletDetailsStart());
  }, []);

  useEffect(() => {
    if (
      adminBankAccount.data &&
      Object.keys(adminBankAccount.data).length > 0
    ) {
      setBuyData({
        ...buyData,
        admin_bank_account_id:
          adminBankAccount.data.admin_bank_account.admin_bank_account_id,
      });
      updateTokenBuyData({
        ...tokenBuyData,
        admin_bank_account_id:
          adminBankAccount.data.admin_bank_account.admin_bank_account_id,
      });
      setPaymentType("bank");
    } else {
      setPaymentType("card");
    }
  }, [adminBankAccount]);

  const [files, setFiles] = useState([]);

  const onDropRejected = useCallback((rejectedFiles) => {
    if (rejectedFiles.length > 1) {
      setErrorMessage(t("please_upload_only_one_file_at_a_time"));
    } else {
      const invalidFiles = rejectedFiles.filter(
        (file) => !["image/jpeg", "image/png", "image/jpg"].includes(file.type)
      );
      if (invalidFiles.length > 0) {
        setErrorMessage(t("invalid_file_format"));
      }
    }
  }, []);

  const onDropAccepted = () => {
    setErrorMessage("");
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropRejected,
    onDropAccepted,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      formRef.current.setFieldValue("payment_file", "preview");
    },
  });

  const images = files.map((file, key) => (
    <div className="preview-added-image" key={key}>
      <Button
        variant="link"
        className="preview-cancel"
        onClick={() => {
          formRef.current.setFieldValue("payment_file", "");
          setFiles([]);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#fff"
          viewBox="0 0 24 24"
        >
          <path d="M18 6a1 1 0 00-1.414 0L12 10.586 7.414 6A1 1 0 006 6a1 1 0 000 1.414L10.586 12 6 16.586A1 1 0 006 18a1 1 0 001.414 0L12 13.414 16.586 18A1 1 0 0018 18a1 1 0 000-1.414L13.414 12 18 7.414A1 1 0 0018 6z"></path>
        </svg>
      </Button>
      <CustomLazyLoad
        className="product-preview"
        key={file.name}
        src={file.preview}
        alt="image-preview"
      />
    </div>
  ));

  const onSubmit = (values) => {
    const {
      step,
      payment_id,
      amountConversion,
      payment_file,
      forex_exchange_rate,
      forex_source_currency,
      crypto_exchange_rate,
      crypto_source_currency,
      commission,
      minAmount,
      ...rest
    } = tokenBuyData;
    if (files.length > 0) {
      dispatch(
        tokenBuyStart({
          ...rest,
          payment_id: values.payment_id,
          payment_file: files[0],
        })
      );
    } else {
      getErrorNotificationMessage(t("please_upload_payment_screenshot"));
    }
  };

  const onCardTransaction = (values) => {
    const data = {
      ...values,
      card_number: values.card_number.replace(/\s+/g, ""),
    };
    const {
      step,
      payment_id,
      amountConversion,
      payment_file,
      forex_exchange_rate,
      forex_source_currency,
      crypto_exchange_rate,
      crypto_source_currency,
      commission,
      minAmount,
      ...rest
    } = tokenBuyData;
    dispatch(
      cardTransactionsStart({
        ...rest,
        ...data,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !tokenBuy.loading &&
      Object.keys(tokenBuy.data).length > 0
    ) {
      updateTokenBuyData({
        ...tokenBuyData,
        step: 3,
      });
    }
    setSkipRender(false);
  }, [tokenBuy]);

  useEffect(() => {
    if (
      !skipRender &&
      !cardTransactions.loading &&
      Object.keys(cardTransactions.data).length > 0
    ) {
      updateTokenBuyData({
        ...tokenBuyData,
        step: 3,
      });
    }
    setSkipRender(false);
  }, [cardTransactions]);

  useEffect(() => {
    if (
      !skipRender &&
      !buyTokensViaWallet.loading &&
      Object.keys(buyTokensViaWallet.data).length > 0
    ) {
      updateTokenBuyData({
        ...tokenBuyData,
        step: 3,
      });
    }
    setSkipRender(false);
  }, [buyTokensViaWallet]);

  const walletSubmit = () => {
    const {
      step,
      payment_id,
      amountConversion,
      payment_file,
      forex_exchange_rate,
      forex_source_currency,
      crypto_exchange_rate,
      crypto_source_currency,
      admin_bank_account_id,
      commission,
      ...rest
    } = tokenBuyData;
    dispatch(
      buyTokensViaWalletStart({
        ...rest,
      })
    );
  };
  const handleChange = (e) => {
    const { value } = e.target;
    const unformattedValue = value.replace(/\s+/g, "");
    if (unformattedValue.length > 16) {
      return;
    }
    const formattedValue = formatNumber(value);
    setCardNumber(formattedValue);
    if (formRef.current) {
      formRef.current.setFieldValue("card_number", formattedValue);
    }
  };

  const formatNumber = (number) => {
    return number
      .replace(/\s+/g, "")
      .split("")
      .reduce((seed, next, index) => {
        if (index !== 0 && index % 4 === 0) {
          seed += " ";
        }
        return seed + next;
      }, "");
  };

  return (
    <>
      <div className="single-page-header"></div>
      {
        adminBankAccount.loading ? (
          <CommonCenterLoader />
        ) : (
          // ) : Object.keys(adminBankAccount.data).length > 0 ? (
          <div>
            <div
              className="back-arrow-btn-sec"
              onClick={() => updateTokenBuyData({ ...tokenBuyData, step: 1 })}
            >
              <Image
                src={
                  window.location.origin + "/img/exchange/back-arrow-icon.svg"
                }
                className="back-arrow-btn"
              />
              <h4>{t("buy_crypto")}</h4>
            </div>
            <hr />
            <div className="mb-3 exchange-form">
              <Form.Label>{t("payment_type")}</Form.Label>
              <Form.Group
                className="mb-3"
                controlId="formBasicEmail"
                style={{ display: "flex", gap: "1em" }}
              >
                <Form.Check
                  type={"radio"}
                  label={"Bank"}
                  checked={paymentType == "bank"}
                  onChange={() => setPaymentType("bank")}
                  disabled={!Object.keys(adminBankAccount.data).length > 0}
                />
                <Form.Check
                  type={"radio"}
                  label={"Card"}
                  checked={paymentType == "card"}
                  onChange={() => setPaymentType("card")}
                />
                <Form.Check
                  type={"radio"}
                  label={"Wallet"}
                  checked={paymentType == "wallet"}
                  onChange={() => setPaymentType("wallet")}
                />
                <div></div>
              </Form.Group>
            </div>
            {paymentType == "bank" ? (
              Object.keys(adminBankAccount.data).length > 0 ? (
                <React.Fragment>
                  {tokenBuyData.buy_via_currency == "INR" ? (
                    <div className="bank-details-full-card">
                      <div className="bank-details-full-card-top">
                        <div className="bank-details-card-top-img">
                          {/* <CustomLazyLoad
                            src={
                              window.location.origin + "/img/bank/hdfc-bank.svg"
                            }
                            className="bank-logo-frame"
                          /> */}
                        </div>
                        <div className="bank-details-card-top-info">
                          <h4>
                            {" "}
                            {
                              adminBankAccount.data.admin_bank_account
                                .account_holder_name
                            }
                          </h4>
                          <p>
                            {" "}
                            {adminBankAccount.data.admin_bank_account.bank_name}
                          </p>
                        </div>
                      </div>
                      <div className="bank-details-full-card-middle">
                        <h4>
                          {t("account_number")}: &nbsp;
                          {
                            adminBankAccount.data.admin_bank_account
                              .account_number
                          }
                        </h4>
                        <h4>
                          {t("ifsc_code")}: &nbsp;
                          {adminBankAccount.data.admin_bank_account.ifsc_code}
                        </h4>
                      </div>
                      <div className="bank-details-full-card-bottom">
                        <div className="bank-details-card-top-ifsc-code-sec">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M24 23a1 1 0 01-1 1H1a1 1 0 010-2h22a1 1 0 011 1zM.291 8.552a2.443 2.443 0 01.153-2.566 4.716 4.716 0 011.668-1.5L9.613.582a5.174 5.174 0 014.774 0l7.5 3.907a4.716 4.716 0 011.668 1.5 2.443 2.443 0 01.153 2.566A2.713 2.713 0 0121.292 10H21v8h1a1 1 0 010 2H2a1 1 0 010-2h1v-8h-.292A2.713 2.713 0 01.291 8.552zM5 18h3v-8H5zm5-8v8h4v-8zm9 0h-3v8h3zM2.063 7.625A.717.717 0 002.708 8h18.584a.717.717 0 00.645-.375.452.452 0 00-.024-.5 2.7 2.7 0 00-.949-.864l-7.5-3.907a3.176 3.176 0 00-2.926 0l-7.5 3.907a2.712 2.712 0 00-.949.865.452.452 0 00-.026.499z"
                              data-original="#000000"
                            ></path>
                          </svg>
                          <span>
                            {" "}
                            {adminBankAccount.data.admin_bank_account.bank_name}
                          </span>
                        </div>
                        <div className="bank-details-card-top-ifsc-code-sec">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="none"
                            viewBox="0 0 16 17"
                          >
                            <path
                              stroke="#252525"
                              strokeLinecap="round"
                              strokeWidth="2"
                              d="M1.333 15.813V15a4.875 4.875 0 014.875-4.875h3.25A4.875 4.875 0 0114.333 15v.813M7.833 7.688a3.25 3.25 0 110-6.5 3.25 3.25 0 010 6.5z"
                            ></path>
                          </svg>
                          <span>{t("savings_account")}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="bank-details-full-card">
                      <div className="bank-details-full-card-top">
                        <div className="bank-details-card-top-img">
                          <CustomLazyLoad
                            src={
                              window.location.origin + "/img/bank/hdfc-bank.svg"
                            }
                            className="bank-logo-frame"
                          />
                        </div>
                        <div className="bank-details-card-top-info">
                          <h4>
                            {" "}
                            {
                              adminBankAccount.data.admin_bank_account
                                .account_holder_name
                            }
                          </h4>
                          <p>
                            {" "}
                            {adminBankAccount.data.admin_bank_account.bank_name}
                          </p>
                        </div>
                      </div>
                      <div className="bank-details-full-card-middle">
                        <h4>
                          {t("account_number")}: &nbsp;
                          {
                            adminBankAccount.data.admin_bank_account
                              .account_number
                          }
                        </h4>
                        <h4>
                          {t("iban_no")}: &nbsp;
                          {adminBankAccount.data.admin_bank_account.iban_no}
                        </h4>
                        <h4>
                          {t("swift_code_beneficiary_bank")}: &nbsp;
                          {
                            adminBankAccount.data.admin_bank_account
                              .swift_code_beneficiary_bank
                          }
                        </h4>
                        <h4>
                          {t("swift_code_correspondent_bank")}: &nbsp;
                          {
                            adminBankAccount.data.admin_bank_account
                              .swift_code_correspondent_bank
                          }
                        </h4>
                        <h4>
                          {t("correspondent_account_no")}: &nbsp;
                          {
                            adminBankAccount.data.admin_bank_account
                              .correspondent_account_no
                          }
                        </h4>
                        <h4>
                          {t("bank_address")}: &nbsp;
                          {
                            adminBankAccount.data.admin_bank_account
                              .bank_address
                          }
                        </h4>
                      </div>
                      <div className="bank-details-full-card-bottom">
                        <div className="bank-details-card-top-ifsc-code-sec">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M24 23a1 1 0 01-1 1H1a1 1 0 010-2h22a1 1 0 011 1zM.291 8.552a2.443 2.443 0 01.153-2.566 4.716 4.716 0 011.668-1.5L9.613.582a5.174 5.174 0 014.774 0l7.5 3.907a4.716 4.716 0 011.668 1.5 2.443 2.443 0 01.153 2.566A2.713 2.713 0 0121.292 10H21v8h1a1 1 0 010 2H2a1 1 0 010-2h1v-8h-.292A2.713 2.713 0 01.291 8.552zM5 18h3v-8H5zm5-8v8h4v-8zm9 0h-3v8h3zM2.063 7.625A.717.717 0 002.708 8h18.584a.717.717 0 00.645-.375.452.452 0 00-.024-.5 2.7 2.7 0 00-.949-.864l-7.5-3.907a3.176 3.176 0 00-2.926 0l-7.5 3.907a2.712 2.712 0 00-.949.865.452.452 0 00-.026.499z"
                              data-original="#000000"
                            ></path>
                          </svg>
                          <span>
                            {" "}
                            {adminBankAccount.data.admin_bank_account.bank_name}
                          </span>
                        </div>
                        <div className="bank-details-card-top-ifsc-code-sec">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="none"
                            viewBox="0 0 16 17"
                          >
                            <path
                              stroke="#252525"
                              strokeLinecap="round"
                              strokeWidth="2"
                              d="M1.333 15.813V15a4.875 4.875 0 014.875-4.875h3.25A4.875 4.875 0 0114.333 15v.813M7.833 7.688a3.25 3.25 0 110-6.5 3.25 3.25 0 010 6.5z"
                            ></path>
                          </svg>
                          <span>{t("savings_account")}</span>
                        </div>
                      </div>
                    </div>
                  )}
                  <hr />
                  <Formik
                    initialValues={{
                      payment_id: "",
                      payment_file: "",
                    }}
                    validationSchema={validationSchema}
                    innerRef={formRef}
                    onSubmit={(values) => onSubmit(values)}
                  >
                    {({ values, touched, errors, setFieldValue }) => (
                      <FORM className="exchange-form">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>{t("payment_id.label")}</Form.Label>
                          <span>*</span>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder={t("payment_id.placeholder")}
                            name="payment_id"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="payment_id"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>{t("payment_picture")}</Form.Label>
                          <span>*</span>
                          <div {...getRootProps()}>
                            <input name="payment_file" {...getInputProps()} />
                            {files.length <= 0 && (
                              <div className="add-product-upload-box">
                                <CustomLazyLoad
                                  src={
                                    window.location.origin + "/img/upload.png"
                                  }
                                  className="upload-icon"
                                />
                                <p>
                                  {t("upload_your_payment_screenshot_here")}
                                  <br />{" "}
                                  <span> {t("accept_only_jpeg_jpg_png")}</span>
                                </p>
                              </div>
                            )}
                            <div>{images}</div>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="payment_file"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                        {errorMessage && (
                          <p className="error-msg text-danger select-date">
                            {errorMessage}
                          </p>
                        )}
                        <div className="exchange-btn-sec">
                          <Button
                            className="default-btn"
                            type="submit"
                            disabled={tokenBuy.buttonDisable}
                          >
                            {tokenBuy.buttonDisable
                              ? t("loading")
                              : t("continue")}
                          </Button>
                          <p>
                            {t("by_continuing_you_agree_to_our")}{" "}
                            <Link
                              className="cookie-txt"
                              to="/page/privacy-agreement"
                              target="_blank"
                            >
                              {t("cookie_policy")}.
                            </Link>
                          </p>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="no-bank-details-sec exchange-btn-sec">
                    <CustomLazyLoad
                      src={window.location.origin + "/img/no-bank-found.png"}
                      className="no-data-found-img"
                    />
                    <p>{t("no_bank_details_found")}</p>
                    <Button
                      className="default-btn w-100"
                      onClick={() => dispatch(getAdminBankAccountStart())}
                    >
                      {t("retry")}
                    </Button>
                  </div>
                </React.Fragment>
              )
            ) : paymentType == "card" ? (
              <React.Fragment>
                <Formik
                  initialValues={{
                    card_number: "",
                    cvv: "",
                    expiry_month: "",
                    expiry_year: "",
                  }}
                  validationSchema={cardSchema}
                  innerRef={formRef}
                  onSubmit={(values) => onCardTransaction(values)}
                >
                  {({ values, touched, errors, setFieldValue }) => (
                    <FORM className="exchange-form">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{t("card_number.label")}</Form.Label>
                        <Field
                          className="form-control"
                          type="text"
                          onChange={handleChange}
                          placeholder={t("card_number.placeholder")}
                          name="card_number"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="card_number"
                          className="text-danger text-right"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicCVV">
                        <Form.Label>CVV</Form.Label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder="Enter CVV"
                          name="cvv"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="cvv"
                          className="text-danger text-right"
                        />
                      </Form.Group>
                      <div
                        style={{ display: "flex", gap: "1em", width: "100%" }}
                      >
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicExpiryMonth"
                          style={{ width: "100%" }}
                        >
                          <Form.Label>Expiry Month</Form.Label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder="Enter expiry month"
                            name="expiry_month"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="expiry_month"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicExpiryYear"
                          style={{ width: "100%" }}
                        >
                          <Form.Label>Expiry Year</Form.Label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder="Enter expiry year"
                            name="expiry_year"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="expiry_year"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                      </div>
                      <div className="exchange-btn-sec">
                        <Button
                          className="default-btn"
                          type="submit"
                          disabled={cardTransactions.buttonDisable}
                        >
                          {cardTransactions.buttonDisable
                            ? t("loading")
                            : t("continue")}
                        </Button>
                        <p>
                          {t("by_continuing_you_agree_to_our")}{" "}
                          <Link
                            className="cookie-txt"
                            to="/page/privacy-agreement"
                            target="_blank"
                          >
                            {t("cookie_policy")}.
                          </Link>
                        </p>
                      </div>
                    </FORM>
                  )}
                </Formik>
              </React.Fragment>
            ) : (
              <div className="exchange-btn-sec">
                {Object.keys(wallet.data).length > 0 &&
                  parseInt(
                    wallet.data?.user_wallets?.filter(
                      (data) =>
                        data.currency_code == tokenBuyData.buy_via_currency
                    )[0]?.remaining_original
                  ) < tokenBuyData.from_amount && (
                    <>
                      <p className="text-danger">
                        {" "}
                        Balance is insufficient, add tokens to your
                        wallet.Balance is{" "}
                        {parseInt(
                          wallet.data?.user_wallets?.filter(
                            (data) =>
                              data.currency_code ==
                              tokenBuyData.buy_via_currency
                          )[0]?.remaining_original
                        )}
                        {"  "}
                        {
                          wallet.data?.user_wallets?.filter(
                            (data) =>
                              data.currency_code ==
                              tokenBuyData.buy_via_currency
                          )[0]?.currency_code
                        }
                      </p>
                      <Button
                        type="button"
                        className="resend-otp-btn"
                        onClick={() => navigate("/wallet-currency-list")}
                      >
                        Add Token
                      </Button>
                    </>
                  )}
                <Button
                  className="default-btn"
                  onClick={walletSubmit}
                  disabled={buyTokensViaWallet.buttonDisable}
                >
                  {buyTokensViaWallet.buttonDisable
                    ? t("loading")
                    : t("continue")}
                </Button>
                <p>
                  {t("by_continuing_you_agree_to_our")}{" "}
                  <Link
                    className="cookie-txt"
                    to="/page/privacy-agreement"
                    target="_blank"
                  >
                    {t("cookie_policy")}.
                  </Link>
                </p>
              </div>
            )}
          </div>
        )
        // ) : (
        // <div className="no-bank-details-sec exchange-btn-sec">
        //   <Image
        //     src={window.location.origin + "/img/no-bank-found.png"}
        //     className="no-data-found-img"
        //   />
        //   <p>{t("no_bank_details_found")}</p>
        //   <Button
        //     className="default-btn w-100"
        //     onClick={() => dispatch(getAdminBankAccountStart())}
        //   >
        //     {t("retry")}
        //   </Button>
        // </div>)
      }
    </>
  );
};

export default TokenBuy;
