import React from 'react';
import { Button, Image } from 'react-bootstrap';


const SomethingWentWrong = ({ btnText = "", onClick = () => { } }) => {

  return (
    <div className="something-wrong mt-5">
      <Image src={window.location.origin + "/img/something-went-wrong.svg"} />
      {btnText ?
        <div className="exchange-btn-sec">
          <Button className="default-btn" onClick={onClick}>{btnText}</Button>
        </div>
        : null
      }
    </div>
  );
}

export default SomethingWentWrong;