import React from "react";
import { Container, Image, Nav, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";

const GrowthOfExchange = () => {

  const t = useTranslation("growth_exchange")

  return (
    <>
      <div className="growth-of-exchange-sec sm-padding">
        <Container>
          <Row className="align-items-center">
            <Col xl={6} className="order-res">
              <div className="growth-of-exchange-img-sec" data-aos="fade-up">
                <Image
                  className="growth-of-exchange-img"
                  src={
                    window.location.origin + "/img/gif/trade-innovation.gif"
                  }
                  type="image/png"
                />
              </div>
            </Col>
            <Col xl={6}>
              <div className="growth-of-exchange-info">
                <h2 data-aos="fade-up">{t("enhance_your")} <span>{t("crypto_trades_with_our_trailblazing_innovations")}</span></h2>
              </div>
              <div className="growth-of-exchange-list-box">
                <div className="growth-of-exchange-list-card" data-aos="fade-up">
                  <div className="growth-of-exchange-list-icon-sec">
                    <Image
                      className="growth-of-exchange-list-icon"
                      src={
                        window.location.origin + "/img/landing/growth-icon-1.svg"
                      }
                      type="image/png"
                    />
                  </div>
                  <div className="growth-of-exchange-list-info">
                    <h4>{t("frontier_technology_in_crypto")}</h4>
                    <p>{t("pioneering_seamless_crypto_transactions_para")}</p>
                  </div>
                </div>
                <div className="growth-of-exchange-list-card" data-aos="fade-up">
                  <div className="growth-of-exchange-list-icon-sec">
                    <Image
                      className="growth-of-exchange-list-icon"
                      src={
                        window.location.origin + "/img/landing/growth-icon-2.svg"
                      }
                      type="image/png"
                    />
                  </div>
                  <div className="growth-of-exchange-list-info">
                    <h4>{t("clear_cut_pricing")}</h4>
                    <p>{t("committing_to_clarity_with_competitive")}</p>
                  </div>
                </div>
                <div className="growth-of-exchange-list-card" data-aos="fade-up">
                  <div className="growth-of-exchange-list-icon-sec">
                    <Image
                      className="growth-of-exchange-list-icon"
                      src={
                        window.location.origin + "/img/landing/growth-icon-3.svg"
                      }
                      type="image/png"
                    />
                  </div>
                  <div className="growth-of-exchange-list-info">
                    <h4>{t("crypto_learning_hub")}</h4>
                    <p>{t("empowering_your_crypto_expertise_para")}</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default GrowthOfExchange;
