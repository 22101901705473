import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../Wallet/Wallet.css";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import {
  getCurrenciesListStart,
  tokenSwapStart,
} from "../../store/actions/BuyAction";
import {
  fetchAllTransactionStart,
  fetchSingleWalletViewStart,
  fetchWalletDetailsStart,
} from "../../store/actions/WalletAction";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import CommonCenterLoader from "../helper/CommonCenterLoader";
import { useParams } from "react-router-dom";
import configuration from "react-global-configuration";

const SwapModalNew = (props) => {
  const t = useTranslation("swap_modal");
  const params = useParams();
  const dispatch = useDispatch();
  const tokenSwap = useSelector((state) => state.buy.tokenSwap);
  const wallet = useSelector((state) => state.wallet.walletData);
  const [skipRender, setSkipRender] = useState(true);
  const [filteredData, setFilteredData] = useState({});
  const [walletData, setWalletData] = useState({});
  const [minAmount, setMinAmount] = useState(0.0000001);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const currencies = useSelector((state) => state.buy.currencies);
  const [cryptoCurrencyList, setCryptoCurrencyList] = useState([]);

  const validationSchema = Yup.object().shape({
    value: Yup.number()
      .required(t("amount.required"))
      .min(
        !isNaN(minAmount) ? minAmount?.toFixed(7) : 0,
        t("amount.invalid", {
          value: !isNaN(minAmount) ? minAmount?.toFixed(7) : 0,
        })
      ),
    to_wallet_id: Yup.string().required(t("to_wallet.required")),
  });

  const handleSubmit = (values) => {
    const userWallet = wallet.data.user_wallets.find(
      (item) => item.id == props.user_wallet_id
    );
    const userCurrencyType = userWallet?.currency_type;
    const filteredCurrencyType = filteredData.currency_type;

    let endpoint;

    switch (`${userCurrencyType}-${filteredCurrencyType}`) {
      case "crypto-forex":
        endpoint = "efi/swap_crypto_fiat";
        break;
      case "crypto-crypto":
        endpoint = "efi/swap_tokens";
        break;
      case "forex-crypto":
        endpoint = "efi/swap_fiat_crypto";
        break;
      case "forex-forex":
        endpoint = "efi/swap_fiats";
        break;
    }
    dispatch(
      tokenSwapStart({
        ...values,
        endpoint: endpoint,
        from_wallet_id: props.user_wallet_id,
        user_wallet_id: props.user_wallet_id,
      })
    );
  };

  useEffect(() => {
    dispatch(fetchWalletDetailsStart());
    dispatch(getCurrenciesListStart());
    dispatch(fetchWalletDetailsStart());
  }, []);

  useEffect(() => {
    if (
      !skipRender &&
      !tokenSwap.loading &&
      Object.keys(tokenSwap.data).length > 0
    ) {
      dispatch(
        fetchSingleWalletViewStart({
          user_wallet_id: params.user_wallet_id,
        })
      );
      dispatch(
        fetchAllTransactionStart({
          user_wallet_id: params.user_wallet_id,
          skip: 0,
          take: 12,
        })
      );
      props.onHide();
    }
    setSkipRender(false);
  }, [tokenSwap]);

  useEffect(() => {
    if (!skipRender && !wallet.loading && Object.keys(wallet.data).length > 0) {
      setWalletData(
        wallet.data.user_wallets.filter(
          (item) => item.id == props.user_wallet_id
        )[0]
      );
      const categories = wallet.data.user_wallets
        .filter((wallet) => wallet.id != props.user_wallet_id)
        .map((item) => ({
          label: item.currency_code,
          value: item.id,
        }));
      setCategoriesOptions(categories);
    }
    setSkipRender(false);
  }, [wallet]);

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#111",
      fontSize: "0.85em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#32089F",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#32089F!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#32089F!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#111",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  useEffect(() => {
    if (
      !skipRender &&
      !currencies.loading &&
      Object.keys(currencies.data).length > 0 &&
      Object.keys(wallet.data).length > 0
    ) {
      setMinAmount(
        parseInt(
          currencies.data?.forex_currencies.filter(
            (data) =>
              wallet.data.user_wallets.find(
                (item) => item.id == props.user_wallet_id
              ).currency_code == data.currency_code
          )[0]?.min_swap_amount <= 0
            ? 0.0000001
            : parseInt(
                currencies.data?.forex_currencies.filter(
                  (data) =>
                    wallet.data.user_wallets.find(
                      (item) => item.id == props.user_wallet_id
                    ).currency_code == data.currency_code
                )[0]?.min_swap_amount
              ).toFixed(7)
        )
      );
    }
    setSkipRender(false);
  }, [currencies]);

  useEffect(() => {
    if (!isNaN(minAmount) ? minAmount.toFixed(7) : 0 == 0) {
      setMinAmount(0.0000001);
    }
  }, [minAmount]);

  useEffect(() => {
    const cryptoExchangeUrl = configuration.get("configData.urls")
      ? configuration.get("configData.urls")
      : "wss://cms-proexchange.rare-able.com:3091";

    const socket = new WebSocket(cryptoExchangeUrl);

    socket.onopen = () => {
      const subscribeMessage = JSON.stringify({
        action: "subscribe",
        asset: ["BTC", "ETH", "BNB", "BUSD", "USDT"],
      });
      socket.send(subscribeMessage);
    };

    socket.onmessage = (event) => {
      try {
        const parsedData = JSON.parse(event.data);

        parsedData.map(([asset, value]) => ({
          asset,
          value,
        }));
        setCryptoCurrencyList(
          parsedData.map(([asset, value]) => ({
            asset,
            value,
          }))
        );
      } catch (error) {}
    };
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, []);

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="swap-modal-new"
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{t("heading")}</h4>
          {wallet.loading ? (
            <CommonCenterLoader />
          ) : Object.keys(wallet.data).length > 0 &&
            wallet.data.user_wallets.length > 0 ? (
            <>
              <Formik
                initialValues={{
                  value: "",
                  to_wallet_id: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ setFieldValue, values }) => (
                  <FORM className="exchange-form swap-exchange mt-4">
                    <div className="swap-token-input">
                      <Form.Group
                        className="input-group-custome "
                        controlId="exampleForm.ControlInput1"
                      >
                        <Field
                          type="number"
                          name="value"
                          placeholder={t("amount.placeholder")}
                          className="form-control"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="value"
                          className="text-danger currency-text"
                        />
                        {values.value > walletData.remaining_original ? (
                          <>
                            <p className="error-msg text-danger select-date">
                              {t("invalid_tokens")}
                            </p>
                          </>
                        ) : null}
                      </Form.Group>
                      <div className="aed-currency">
                        <div className="aed-currency-svg">
                          <Image
                            className="swap-img"
                            src={
                              walletData.currency_picture
                                ? walletData.currency_picture
                                : null
                            }
                          />
                          <p>{walletData.currency_code}</p>
                        </div>
                        <p className="balance">
                          {t("balance")}: <span>{walletData.total}</span>
                        </p>
                      </div>
                    </div>
                    <div className="swap-arrow">
                      <div className="up-down-arrow">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <g fill="#000" fillRule="evenodd" clipRule="evenodd">
                            <path d="M12.595 4.507a.75.75 0 011.058-.072l5.84 5.09A.75.75 0 0119 10.842H5a.75.75 0 010-1.5h11.998l-4.33-3.776a.75.75 0 01-.073-1.058zM11.405 19.493a.75.75 0 01-1.058.072l-5.84-5.09A.75.75 0 015 13.158h14a.75.75 0 010 1.5H7.002l4.33 3.776a.75.75 0 01.073 1.058z"></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                    <div className="swap-token-input mt-4">
                      <div className="recieve-amount">
                        {values.to_wallet_id ? (
                          <h3>
                            {Object.keys(filteredData).length &&
                            Object.keys(wallet).length > 0
                              ? wallet.data?.user_wallets?.find(
                                  (item) => item.id == props?.user_wallet_id
                                )?.currency_type == "crypto"
                                ? (
                                    (values.value -
                                      (values.value *
                                        currencies.data?.crypto_currencies?.find(
                                          (item) =>
                                            item.currency_code ==
                                            walletData.currency_code
                                        )?.swap_commission) /
                                        100) *
                                    (cryptoCurrencyList?.filter(
                                      (data) =>
                                        walletData.currency_code == data.asset
                                    ))[0]?.value *
                                    filteredData.exchange_rate
                                  ).toFixed(7)
                                : (
                                    (values.value -
                                      (values.value *
                                        currencies.data?.forex_currencies?.find(
                                          (item) =>
                                            item.currency_code ==
                                            walletData.currency_code
                                        )?.swap_commission) /
                                        100) *
                                    (filteredData.exchange_rate /
                                      walletData.exchange_rate)
                                  ).toFixed(7)
                              : 0}
                          </h3>
                        ) : null}
                        <p>{t("receive")}</p>
                      </div>
                      <div className="aed-currency">
                        <Select
                          className="fillert-drop"
                          name="to_wallet_id"
                          options={categoriesOptions}
                          styles={customStyles}
                          onChange={(selectedOption) => {
                            if (selectedOption) {
                              setFieldValue(
                                "to_wallet_id",
                                selectedOption.value
                              );
                              setFilteredData(
                                wallet.data.user_wallets.find(
                                  (item) => item.id == selectedOption.value
                                )
                              );
                            } else {
                              setFieldValue("to_wallet_id", "");
                            }
                          }}
                          isClearable
                          placeholder={
                            <div className="placeholder-flex">
                              {t("to_wallet.placeholder")}
                            </div>
                          }
                        />
                        <ErrorMessage
                          component={"div"}
                          name="to_wallet_id"
                          className="text-danger currency-text"
                        />
                        {/* {filteredData.swap_commission > 0 ? (
                          <p className="balance">
                            {t("service_fees")}:{" "}
                            <span>
                              {Object.keys(filteredData).length > 0 &&
                              values.value &&
                              values.to_wallet_id
                                ? (values.value *
                                    (1 / walletData.exchange_rate) *
                                    filteredData.exchange_rate *
                                    filteredData.swap_commission) /
                                  100
                                : 0}
                            </span>
                          </p>
                        ) : null} */}
                      </div>
                    </div>
                    <div className="new-action-frame layout-element">
                      <Button
                        className="new-overlay-btn"
                        onClick={props.onHide}
                        disabled={tokenSwap.buttonDisable}
                      >
                        {t("cancel")}
                      </Button>
                      <Button
                        type="submit"
                        className="new-primary-btn"
                        disabled={
                          values.value <= 0 ||
                          values.value > walletData.remaining_original ||
                          tokenSwap.buttonDisable
                        }
                      >
                        {tokenSwap.buttonDisable ? t("loading") : t("swap")}
                      </Button>
                    </div>
                  </FORM>
                )}
              </Formik>
            </>
          ) : null}
        </Modal.Body>
      </Modal>
      {/* <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='swap-modal-new'
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            
          </Modal.Title>
        </Modal.Header>
      
      </Modal> */}
    </>
  );
};

export default SwapModalNew;
