import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import '../Wallet/Wallet.css';
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from 'react-multi-lang';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import {
  sendIbanTransferStart,
  beneficiariesListStart,
  getIbanWalletsListStart
} from '../../store/actions/TransferAction';
import Skeleton from 'react-loading-skeleton';

const TransferAmountIndex = (props) => {

  const t = useTranslation("iban_transactions");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [skipRender, setSkipRender] = useState(true);
  const sendIbanTransfers = useSelector(state => state.transfer.sendIbanTransfers);
  const beneficiariesList = useSelector(state => state.transfer.beneficiariesList);
  const ibanWalletList = useSelector(state => state.transfer.ibanWalletList);

  const validationSchema = Yup.object().shape({
    beneficiary_id: Yup.string().required(t("beneficiary_id.required")),
    from_amount: Yup.string()
      .required(t("from_amount.required")),
    user_wallet_id: Yup.string()
      .required(t("user_wallet_id.required"))
  });

  useEffect(() => {
    dispatch(beneficiariesListStart());
    dispatch(getIbanWalletsListStart());
  }, []);

  const handleSubmit = (values) => {
    dispatch(sendIbanTransferStart(values)
    );
  };

  useEffect(() => {
    if (!skipRender && !sendIbanTransfers.loading &&
      Object.keys(sendIbanTransfers.data).length > 0) {
      navigate("/transactions")
    }
    setSkipRender(false);
  }, [sendIbanTransfers]);

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "12px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "12px!important",
      boxShadow: "none!important",
      height: "45px",
      cursor: "pointer",
      minWidth: "auto",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000",
      fontSize: "1em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#DDE1E6!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#DDE1E6!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#50506B80",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  return (
    <>
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="new-modal-custome"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4> {t("heading")}</h4>
        <Formik
            initialValues={{
              from_amount: "",
              user_wallet_id: "",
              beneficiary_id: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, setFieldTouched, errors }) => (
              <FORM  className="new-feature-form">
                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("user_wallet_id.label")}
                    <span>*</span>
                  </Form.Label>
                  <Select
                    aria-label="Default select example"
                    name="user_wallet_id"
                    styles={customStyles}
                    onChange={(e) => {
                      setFieldValue("user_wallet_id", e.target.value)
                    }}
                  >
                    <option selected disabled>{t("user_wallet_id.placeholder")}</option>
                    {ibanWalletList.loading ?
                      <Skeleton count={1} />
                      :
                      Object.keys(ibanWalletList.data).length > 0 &&
                        ibanWalletList.data.user_wallets.length > 0 ?
                        ibanWalletList.data.user_wallets.map(
                          (wallet, i) => (
                            <option key={i} value={wallet.id}>
                              {wallet.currency_code}
                            </option>
                          )
                        )
                        :
                        null
                    }
                  </Select>
                  <ErrorMessage
                    component={"div"}
                    name="user_wallet_id"
                    className="errorMsg w-100"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("beneficiary_id.label")}
                    <span>*</span>
                  </Form.Label>
                  <Select
                    aria-label="Default select example"
                    name="beneficiary_id"
                    styles={customStyles}
                    onChange={(e) => {
                      setFieldValue("beneficiary_id", e.target.value)
                    }}
                  >
                    <option selected disabled>{t("beneficiary_id.placeholder")}</option>
                    {beneficiariesList.loading ?
                      <Skeleton count={1} /> :
                      Object.keys(beneficiariesList.data).length > 0 &&
                        beneficiariesList.data.beneficiaries.length > 0 ?
                        beneficiariesList.data.beneficiaries.map(
                          (beneficiary, i) => (
                            <option key={i} value={beneficiary.beneficiary_id}>
                              {beneficiary.account_holder_name} -{" "}
                              {beneficiary.iban}
                            </option>
                          ))
                        :
                        null
                    }
                  </Select>
                  <ErrorMessage
                    component={"div"}
                    name="beneficiary_id"
                    className="errorMsg w-100"
                  />
                </Form.Group>
                <Form.Group className="mb-3" >
                  <Form.Label>{t("from_amount.label")} <span>*</span></Form.Label>
                  <Field
                    type="number"
                    className="form-control"
                    placeholder={t("from_amount.placeholder")}
                    name="from_amount"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="from_amount"
                    className="errorMsg w-100"
                  />
                </Form.Group>
                <div className="new-action-frame layout-element">
                  <Button
                    className='new-overlay-btn'
                    onClick={props.onHide}
                  >{t("save_btn.cancel")}
                  </Button>
                  <Button
                    type="submit"
                    className='new-primary-btn'
                    disabled={sendIbanTransfers.buttonDisable}
                  >
                    {sendIbanTransfers.buttonDisable
                      ? t("save_btn.loading")
                      : t("save_btn.text")}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
      </Modal.Body>
    </Modal>
    </>
  )
};

export default TransferAmountIndex;
