import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  Button,
  Offcanvas,
  Dropdown,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useLocation, useParams } from "react-router-dom";
import {
  createKycApplicantStart,
  profileStart,
  updateBuyData,
  updateSwapData,
} from "../../../store/actions/UserAction";
import configuration from "react-global-configuration";
import { useTranslation, setLanguage } from "react-multi-lang";
import { useNavigate } from "react-router-dom";
import useLogout from "./useLogout";

const HeaderIndex = (props) => {
  const kycApplicant = useSelector((state) => state.user.kycApplicant);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const t = useTranslation("header");
  const navigate = useNavigate();
  const login = useSelector((state) => state.user.login);
  const profile = useSelector((state) => state.user.profile);
  const buyData = useSelector((state) => state.user.buyData);
  const setBuyData = (data) => dispatch(updateBuyData(data));
  const swapData = useSelector((state) => state.user.swapData);
  const setSwapData = (data) => dispatch(updateSwapData(data));
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("token") || sessionStorage.getItem("token")
  );
  const [skipRender, setSkipRender] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { logout } = useLogout();

  const handleDropdownToggle = (isOpen) => {
    setDropdownOpen(isOpen);
  };

  const handleDropdownClose = () => {
    setDropdownOpen(false);
  };
  useEffect(() => {
    localStorage.getItem("token") ||
      (sessionStorage.getItem("token") && dispatch(profileStart()));
  }, []);

  const handleLogout = () => {
    setIsLoggedIn(false);
    logout();
    setBuyData({
      ...buyData,
      step: 1,
    });
    setSwapData({
      ...swapData,
      step: 1,
    });
  };

  useEffect(() => {
    if (!skipRender && !login.loading && Object.keys(login.data).length > 0) {
      setIsLoggedIn(localStorage.getItem("token"));
    }
    setSkipRender(false);
  }, [login]);

  const verifyKyc = () => {
    dispatch(createKycApplicantStart());
  };

  return (
    <>
      <header className="header-nav-center">
        <Navbar expand="lg">
          <Container>
            <Link to="/" className="navbar-brand">
              <Image
                className="logo"
                src={configuration.get("configData.site_logo")}
                alt={configuration.get("configData.site_name")}
              />
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <Image
                className="lazyload hamburger-menu"
                src={window.location.origin + "/img/hamburger-menu.svg"}
              />
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Link
                  className={`nav-link ${
                    location.pathname === "/buy" ? "active" : ""
                  }`}
                  to="/buy"
                >
                  {t("buy_swap_crypto")}
                </Link>
                <Link
                  className={`nav-link ${
                    location.pathname === "/sell" ? "active" : ""
                  }`}
                  to="/sell"
                >
                  {t("sell_crypto")}
                </Link>
                {/* <Link
                  className={`nav-link ${location.pathname === "/beneficiaries" ? "active" : ""}`}
                  to={isLoggedIn ? "/beneficiaries" : "/login"}
                >
                  {t("iban_transfer")}
                </Link> */}
                <Link
                  className={`nav-link ${
                    location.pathname === "/page/about-us" ? "active" : ""
                  }`}
                  to="/page/about-us"
                  target="_blank"
                >
                  {t("about_us")}
                </Link>
                <Link
                  className={`nav-link ${
                    location.pathname === "/page/help" ? "active" : ""
                  }`}
                  to="/page/help"
                  target="_blank"
                >
                  {t("help_center")}
                </Link>
                {profile.data?.onboarding_completed == 0 && (
                  <Link className="overlay-btn" to="/onboarding">
                    Initiate Onboarding
                  </Link>
                )}
                {profile.data?.kyc_verified == 0 &&
                  profile.data?.onboarding_completed == 1 && (
                    <Link className="overlay-btn" to="#" onClick={verifyKyc}>
                      {kycApplicant.buttonDisable ? "Loading" : "Verify Kyc"}
                    </Link>
                  )}
                {/* {isLoggedIn ? <Link to="#" onClick={handleLogout} className="nav-link">Logout</Link> : <Link
                  className={`nav-link ${location.pathname === "/login" ? "active" : ""}`}
                  to="/login"
                >Login</Link>} */}
                <div className="nav-item">
                  {!isLoggedIn ? (
                    <Link
                      to="/login"
                      className={`nav-link ${
                        location.pathname === "/login" ? "active" : ""
                      }`}
                    >
                      {t("login")}
                    </Link>
                  ) : !profile.loading &&
                    Object.keys(profile.data).length > 0 ? (
                    <div className="user-dropdown-sec">
                      <Dropdown
                        onToggle={handleDropdownToggle}
                        show={dropdownOpen}
                      >
                        <Dropdown.Toggle id="dropdown-basic">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#111111"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zM8 21.164V19c0-.552.449-1 1-1h6c.551 0 1 .448 1 1v2.164c-1.226.537-2.578.836-4 .836s-2.774-.299-4-.836zm10-1.169V19c0-1.654-1.346-3-3-3H9c-1.654 0-3 1.346-3 3v.995A9.991 9.991 0 012 12C2 6.486 6.486 2 12 2s10 4.486 10 10a9.992 9.992 0 01-4 7.995zM12 6c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z"></path>
                          </svg>
                          <span>{profile.data.name}</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Link
                            to="/profile"
                            onClick={() => handleDropdownClose()}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <path d="M12 12a6 6 0 10-6-6 6.006 6.006 0 006 6zm0-10a4 4 0 11-4 4 4 4 0 014-4zM12 14a9.01 9.01 0 00-9 9 1 1 0 002 0 7 7 0 0114 0 1 1 0 002 0 9.01 9.01 0 00-9-9z"></path>
                            </svg>
                            <span>{t("profile")}</span>
                          </Link>
                          {/* <Link to="/transaction-history" onClick={() => handleDropdownClose()}>
                     
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#000"
                                d="M12 22.5c5.79 0 10.5-4.71 10.5-10.5S17.79 1.5 12 1.5 1.5 6.21 1.5 12 6.21 22.5 12 22.5zM12 3c4.965 0 9 4.035 9 9s-4.035 9-9 9-9-4.035-9-9 4.035-9 9-9z"
                              ></path>
                              <path
                                fill="#000"
                                d="M7.5 11.25h9a.75.75 0 100-1.5H8.902l.722-1.084a.75.75 0 10-1.248-.832l-1.5 2.25A.75.75 0 007.5 11.25zm0 3h7.6l-.724 1.084a.751.751 0 001.248.832l1.5-2.25a.75.75 0 00-.624-1.166h-9a.75.75 0 100 1.5z"
                              ></path>
                            </svg>
                            <span>{t('transactions')}</span>
                          </Link> */}
                          {profile.data?.onboarding_completed == 1 && (
                            <Link
                              to="/wallet-currency-list"
                              onClick={() => handleDropdownClose()}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="26"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <g
                                  fill="#6E6D8E"
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                >
                                  <path d="M6.25 9A.75.75 0 017 8.25h6a.75.75 0 010 1.5H7A.75.75 0 016.25 9zM18.92 10.7c-.359 0-.675.14-.902.373l-.014.014c-.266.26-.415.63-.377 1.022v.009c.054.635.664 1.182 1.413 1.182h1.943a.276.276 0 00.267-.27v-2.06a.276.276 0 00-.267-.27zm-1.97-.68a2.743 2.743 0 011.97-.82h2.107a1.777 1.777 0 011.723 1.77v2.06A1.777 1.777 0 0121 14.8h-1.96c-1.41 0-2.777-1.03-2.907-2.553a2.759 2.759 0 01.816-2.227z"></path>
                                  <path d="M7 4.25c-.244 0-.476.017-.696.051l-.02.003c-1.108.14-1.978.613-2.574 1.313-.598.7-.96 1.676-.96 2.883v7c0 1.33.44 2.38 1.155 3.095S5.67 19.75 7 19.75h9c1.33 0 2.38-.44 3.095-1.155S20.25 16.83 20.25 15.5v-.7h-1.21c-1.41 0-2.778-1.03-2.907-2.553a2.758 2.758 0 01.816-2.227 2.743 2.743 0 011.971-.82h1.33v-.7c0-1.215-.368-2.197-.975-2.9-.606-.702-1.49-1.175-2.612-1.305A3.849 3.849 0 0016 4.25zm-.914-1.433c.297-.045.602-.067.914-.067h9c.27 0 .56.01.855.057 1.45.173 2.685.804 3.555 1.813.873 1.012 1.34 2.355 1.34 3.88v1.45a.75.75 0 01-.75.75h-2.08c-.36 0-.675.14-.903.373l-.013.014c-.266.26-.415.63-.377 1.022v.009c.053.635.664 1.182 1.413 1.182H21a.75.75 0 01.75.75v1.45c0 1.67-.56 3.12-1.595 4.155S17.67 21.25 16 21.25H7c-1.67 0-3.12-.56-4.155-1.595S1.25 17.17 1.25 15.5v-7c0-1.513.458-2.848 1.318-3.857.86-1.008 2.08-1.643 3.518-1.826z"></path>
                                </g>
                              </svg>
                              <span>{t("wallet")}</span>
                            </Link>
                          )}
                          {profile.data?.onboarding_completed == 1 && (
                            <Link
                              to="/recipient-list"
                              onClick={() => handleDropdownClose()}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                enableBackground="new 0 0 512 512"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  d="M24 23a1 1 0 01-1 1H1a1 1 0 010-2h22a1 1 0 011 1zM.291 8.552a2.443 2.443 0 01.153-2.566 4.716 4.716 0 011.668-1.5L9.613.582a5.174 5.174 0 014.774 0l7.5 3.907a4.716 4.716 0 011.668 1.5 2.443 2.443 0 01.153 2.566A2.713 2.713 0 0121.292 10H21v8h1a1 1 0 010 2H2a1 1 0 010-2h1v-8h-.292A2.713 2.713 0 01.291 8.552zM5 18h3v-8H5zm5-8v8h4v-8zm9 0h-3v8h3zM2.063 7.625A.717.717 0 002.708 8h18.584a.717.717 0 00.645-.375.452.452 0 00-.024-.5 2.7 2.7 0 00-.949-.864l-7.5-3.907a3.176 3.176 0 00-2.926 0l-7.5 3.907a2.712 2.712 0 00-.949.865.452.452 0 00-.026.499z"
                                  data-original="#000000"
                                ></path>
                              </svg>
                              <span>{t("bank_accounts")}</span>
                            </Link>
                          )}
                          {/* <Link to="/beneficiaries" onClick={() => handleDropdownClose()}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <path d="M23 11h-2V9a1 1 0 00-2 0v2h-2a1 1 0 000 2h2v2a1 1 0 002 0v-2h2a1 1 0 000-2zM9 12a6 6 0 10-6-6 6.006 6.006 0 006 6zM9 2a4 4 0 11-4 4 4 4 0 014-4zM9 14a9.01 9.01 0 00-9 9 1 1 0 002 0 7 7 0 0114 0 1 1 0 002 0 9.01 9.01 0 00-9-9z"></path>
                              </svg>
                            <span>{t("beneficiaries")}</span>
                          </Link> */}
                          {profile.data?.onboarding_completed == 1 && (
                            <Link
                              to="/transactions"
                              onClick={() => handleDropdownClose()}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <g>
                                  <path d="M21 11H7c-.6 0-1-.4-1-1s.4-1 1-1h11.6l-2.3-2.3c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l4 4c.3.3.4.7.2 1.1-.1.4-.5.6-.9.6zM7 19c-.3 0-.5-.1-.7-.3l-4-4c-.3-.3-.4-.7-.2-1.1s.5-.6.9-.6h14c.6 0 1 .4 1 1s-.4 1-1 1H5.4l2.3 2.3c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3z"></path>
                                </g>
                              </svg>
                              <span>{t("transactions")}</span>
                            </Link>
                          )}
                          {/* <Link
                            to="/recipient-list"
                            onClick={() => handleDropdownClose()}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="22"
                              fill="none"
                              viewBox="0 0 72 94"
                            >
                              <path
                                fill="#000"
                                d="M34.97 46.025c12.12 0 21.979-9.872 21.979-22.012S47.09 2 34.969 2s-21.98 9.873-21.98 22.013c0 12.14 9.858 22.012 21.98 22.012zm0-36.687c8.08 0 14.652 6.581 14.652 14.675 0 8.093-6.572 14.675-14.653 14.675-8.08 0-14.653-6.582-14.653-14.675 0-8.094 6.572-14.675 14.653-14.675zm6.41 52.17a25.732 25.732 0 00-6.41-.808c-14.14 0-25.643 11.52-25.643 25.682a3.667 3.667 0 01-3.664 3.668A3.667 3.667 0 012 86.382c0-18.205 14.792-33.02 32.97-33.02 2.79 0 5.564.35 8.242 1.043a3.672 3.672 0 012.633 4.468 3.659 3.659 0 01-4.461 2.638l-.004-.004zM69.347 74.673a2.217 2.217 0 00-1.572-.661 2.197 2.197 0 00-1.573.66l-9.524 9.643V56.788c0-.596-.234-1.168-.65-1.59a2.206 2.206 0 00-1.57-.657c-.588 0-1.153.236-1.57.658-.416.421-.65.993-.65 1.589v27.527l-9.528-9.642a2.21 2.21 0 00-1.572-.66 2.21 2.21 0 00-1.572.66 2.265 2.265 0 00-.652 1.592c0 .597.235 1.17.652 1.591L52.884 91.34a2.218 2.218 0 001.572.661 2.197 2.197 0 001.573-.66l13.318-13.484A2.247 2.247 0 0070 76.265a2.272 2.272 0 00-.653-1.592z"
                              ></path>
                              <path
                                stroke="none"
                                strokeWidth="3"
                                d="M34.97 46.025c12.12 0 21.979-9.872 21.979-22.012S47.09 2 34.969 2s-21.98 9.873-21.98 22.013c0 12.14 9.858 22.012 21.98 22.012zm0-36.687c8.08 0 14.652 6.581 14.652 14.675 0 8.093-6.572 14.675-14.653 14.675-8.08 0-14.653-6.582-14.653-14.675 0-8.094 6.572-14.675 14.653-14.675zm6.41 52.17a25.732 25.732 0 00-6.41-.808c-14.14 0-25.643 11.52-25.643 25.682a3.667 3.667 0 01-3.664 3.668A3.667 3.667 0 012 86.382c0-18.205 14.792-33.02 32.97-33.02 2.79 0 5.564.35 8.242 1.043a3.672 3.672 0 012.633 4.468 3.659 3.659 0 01-4.461 2.638l-.004-.004zM69.347 74.673a2.217 2.217 0 00-1.572-.661 2.197 2.197 0 00-1.573.66l-9.524 9.643V56.788c0-.596-.234-1.168-.65-1.59a2.206 2.206 0 00-1.57-.657c-.588 0-1.153.236-1.57.658-.416.421-.65.993-.65 1.589v27.527l-9.528-9.642a2.21 2.21 0 00-1.572-.66 2.21 2.21 0 00-1.572.66 2.265 2.265 0 00-.652 1.592c0 .597.235 1.17.652 1.591L52.884 91.34a2.218 2.218 0 001.572.661 2.197 2.197 0 001.573-.66l13.318-13.484A2.247 2.247 0 0070 76.265a2.272 2.272 0 00-.653-1.592z"
                              ></path>
                            </svg>
                            <span>Recipient</span>
                          </Link> */}
                          <Link to="#" onClick={handleLogout}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              data-name="Layer 1"
                              viewBox="0 0 24 24"
                            >
                              <path d="M11.476 15a1 1 0 00-1 1v3a3 3 0 01-3 3H5a3 3 0 01-3-3V5a3 3 0 013-3h2.476a3 3 0 013 3v3a1 1 0 002 0V5a5.006 5.006 0 00-5-5H5a5.006 5.006 0 00-5 5v14a5.006 5.006 0 005 5h2.476a5.006 5.006 0 005-5v-3a1 1 0 00-1-1z"></path>
                              <path d="M22.867 9.879l-4.586-4.586a1 1 0 10-1.414 1.414l4.263 4.263L6 11a1 1 0 000 2l15.188-.03-4.323 4.323a1 1 0 101.414 1.414l4.586-4.586a3 3 0 00.002-4.242z"></path>
                            </svg>
                            <span>{t("logout")}</span>
                          </Link>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  ) : null}
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
      <header className="header-nav-center-devices">
        <Navbar expand="lg">
          <Container>
            <div className="devices-nav-bar">
              <div className="header-right-wrap">
                <Link to="/" className="navbar-brand">
                  <Image
                    className="logo"
                    src={configuration.get("configData.site_logo")}
                    alt={configuration.get("configData.site_name")}
                  />
                </Link>
              </div>
              <div className="header-right-wrap">
                <div className="nav-item">
                  {!isLoggedIn ? (
                    <Link
                      to="/login"
                      className={`nav-link ${
                        location.pathname === "/login" ? "active" : ""
                      }`}
                    >
                      {t("login")}
                    </Link>
                  ) : !profile.loading &&
                    Object.keys(profile.data).length > 0 ? (
                    <div className="user-dropdown-sec">
                      <Dropdown
                        onToggle={handleDropdownToggle}
                        show={dropdownOpen}
                      >
                        <Dropdown.Toggle id="dropdown-basic">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#111111"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zM8 21.164V19c0-.552.449-1 1-1h6c.551 0 1 .448 1 1v2.164c-1.226.537-2.578.836-4 .836s-2.774-.299-4-.836zm10-1.169V19c0-1.654-1.346-3-3-3H9c-1.654 0-3 1.346-3 3v.995A9.991 9.991 0 012 12C2 6.486 6.486 2 12 2s10 4.486 10 10a9.992 9.992 0 01-4 7.995zM12 6c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z"></path>
                          </svg>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Link
                            to="/profile"
                            onClick={() => handleDropdownClose()}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <path d="M12 12a6 6 0 10-6-6 6.006 6.006 0 006 6zm0-10a4 4 0 11-4 4 4 4 0 014-4zM12 14a9.01 9.01 0 00-9 9 1 1 0 002 0 7 7 0 0114 0 1 1 0 002 0 9.01 9.01 0 00-9-9z"></path>
                            </svg>
                            <span>{t("profile")}</span>
                          </Link>
                          {/* <Link to="/transaction-history" onClick={() => handleDropdownClose()}>
               
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#000"
                          d="M12 22.5c5.79 0 10.5-4.71 10.5-10.5S17.79 1.5 12 1.5 1.5 6.21 1.5 12 6.21 22.5 12 22.5zM12 3c4.965 0 9 4.035 9 9s-4.035 9-9 9-9-4.035-9-9 4.035-9 9-9z"
                        ></path>
                        <path
                          fill="#000"
                          d="M7.5 11.25h9a.75.75 0 100-1.5H8.902l.722-1.084a.75.75 0 10-1.248-.832l-1.5 2.25A.75.75 0 007.5 11.25zm0 3h7.6l-.724 1.084a.751.751 0 001.248.832l1.5-2.25a.75.75 0 00-.624-1.166h-9a.75.75 0 100 1.5z"
                        ></path>
                      </svg>
                      <span>{t('transactions')}</span>
                    </Link> */}
                          {profile.data?.onboarding_completed == 1 && (
                            <Link
                              to="/wallet-currency-list"
                              onClick={() => handleDropdownClose()}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="26"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <g
                                  fill="#6E6D8E"
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                >
                                  <path d="M6.25 9A.75.75 0 017 8.25h6a.75.75 0 010 1.5H7A.75.75 0 016.25 9zM18.92 10.7c-.359 0-.675.14-.902.373l-.014.014c-.266.26-.415.63-.377 1.022v.009c.054.635.664 1.182 1.413 1.182h1.943a.276.276 0 00.267-.27v-2.06a.276.276 0 00-.267-.27zm-1.97-.68a2.743 2.743 0 011.97-.82h2.107a1.777 1.777 0 011.723 1.77v2.06A1.777 1.777 0 0121 14.8h-1.96c-1.41 0-2.777-1.03-2.907-2.553a2.759 2.759 0 01.816-2.227z"></path>
                                  <path d="M7 4.25c-.244 0-.476.017-.696.051l-.02.003c-1.108.14-1.978.613-2.574 1.313-.598.7-.96 1.676-.96 2.883v7c0 1.33.44 2.38 1.155 3.095S5.67 19.75 7 19.75h9c1.33 0 2.38-.44 3.095-1.155S20.25 16.83 20.25 15.5v-.7h-1.21c-1.41 0-2.778-1.03-2.907-2.553a2.758 2.758 0 01.816-2.227 2.743 2.743 0 011.971-.82h1.33v-.7c0-1.215-.368-2.197-.975-2.9-.606-.702-1.49-1.175-2.612-1.305A3.849 3.849 0 0016 4.25zm-.914-1.433c.297-.045.602-.067.914-.067h9c.27 0 .56.01.855.057 1.45.173 2.685.804 3.555 1.813.873 1.012 1.34 2.355 1.34 3.88v1.45a.75.75 0 01-.75.75h-2.08c-.36 0-.675.14-.903.373l-.013.014c-.266.26-.415.63-.377 1.022v.009c.053.635.664 1.182 1.413 1.182H21a.75.75 0 01.75.75v1.45c0 1.67-.56 3.12-1.595 4.155S17.67 21.25 16 21.25H7c-1.67 0-3.12-.56-4.155-1.595S1.25 17.17 1.25 15.5v-7c0-1.513.458-2.848 1.318-3.857.86-1.008 2.08-1.643 3.518-1.826z"></path>
                                </g>
                              </svg>
                              <span>{t("wallet")}</span>
                            </Link>
                          )}
                          {profile.data?.onboarding_completed == 1 && (
                            <Link
                              to="/recipient-list"
                              onClick={() => handleDropdownClose()}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                enableBackground="new 0 0 512 512"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  d="M24 23a1 1 0 01-1 1H1a1 1 0 010-2h22a1 1 0 011 1zM.291 8.552a2.443 2.443 0 01.153-2.566 4.716 4.716 0 011.668-1.5L9.613.582a5.174 5.174 0 014.774 0l7.5 3.907a4.716 4.716 0 011.668 1.5 2.443 2.443 0 01.153 2.566A2.713 2.713 0 0121.292 10H21v8h1a1 1 0 010 2H2a1 1 0 010-2h1v-8h-.292A2.713 2.713 0 01.291 8.552zM5 18h3v-8H5zm5-8v8h4v-8zm9 0h-3v8h3zM2.063 7.625A.717.717 0 002.708 8h18.584a.717.717 0 00.645-.375.452.452 0 00-.024-.5 2.7 2.7 0 00-.949-.864l-7.5-3.907a3.176 3.176 0 00-2.926 0l-7.5 3.907a2.712 2.712 0 00-.949.865.452.452 0 00-.026.499z"
                                  data-original="#000000"
                                ></path>
                              </svg>
                              <span>{t("bank_accounts")}</span>
                            </Link>
                          )}{" "}
                          {profile.data?.onboarding_completed == 1 && (
                            <Link
                              to="/Transactions"
                              onClick={() => handleDropdownClose()}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <g>
                                  <path d="M21 11H7c-.6 0-1-.4-1-1s.4-1 1-1h11.6l-2.3-2.3c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l4 4c.3.3.4.7.2 1.1-.1.4-.5.6-.9.6zM7 19c-.3 0-.5-.1-.7-.3l-4-4c-.3-.3-.4-.7-.2-1.1s.5-.6.9-.6h14c.6 0 1 .4 1 1s-.4 1-1 1H5.4l2.3 2.3c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3z"></path>
                                </g>
                              </svg>
                              <span>{t("transactions")}</span>
                            </Link>
                          )}
                          <Link to="#" onClick={handleLogout}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              data-name="Layer 1"
                              viewBox="0 0 24 24"
                            >
                              <path d="M11.476 15a1 1 0 00-1 1v3a3 3 0 01-3 3H5a3 3 0 01-3-3V5a3 3 0 013-3h2.476a3 3 0 013 3v3a1 1 0 002 0V5a5.006 5.006 0 00-5-5H5a5.006 5.006 0 00-5 5v14a5.006 5.006 0 005 5h2.476a5.006 5.006 0 005-5v-3a1 1 0 00-1-1z"></path>
                              <path d="M22.867 9.879l-4.586-4.586a1 1 0 10-1.414 1.414l4.263 4.263L6 11a1 1 0 000 2l15.188-.03-4.323 4.323a1 1 0 101.414 1.414l4.586-4.586a3 3 0 00.002-4.242z"></path>
                            </svg>
                            <span>{t("logout")}</span>
                          </Link>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  ) : null}
                </div>

                <Button
                  variant="primary"
                  className="device-hamber-btn"
                  onClick={handleShow}
                >
                  <Image
                    className="lazyload hamburger-menu"
                    src={window.location.origin + "/img/hamburger-menu.svg"}
                  />
                </Button>
              </div>
            </div>
            <Offcanvas
              show={show}
              onHide={handleClose}
              className="resp-offcanvas"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  <Image
                    className="logo"
                    src={configuration.get("configData.site_logo")}
                    alt={configuration.get("configData.site_name")}
                  />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="devices-nav-links">
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="ms-auto">
                    <Link
                      className={`nav-link ${
                        location.pathname === "/buy" ? "active" : ""
                      }`}
                      to="/buy"
                    >
                      {t("buy_swap_crypto")}
                    </Link>
                    <Link
                      className={`nav-link ${
                        location.pathname === "/sell" ? "active" : ""
                      }`}
                      to="/sell"
                    >
                      {t("sell_crypto")}
                    </Link>
                    {/* <Link
                      className={`nav-link ${location.pathname === "/sell" ? "active" : ""}`}
                      to="/sell"
                    >{t("iban_transfer")}</Link> */}
                    <Link
                      className={`nav-link ${
                        location.pathname === "/page/about-us" ? "active" : ""
                      }`}
                      to="/page/about-us"
                      target="_blank"
                    >
                      {t("about_us")}
                    </Link>
                    <Link
                      className={`nav-link ${
                        location.pathname === "/page/help" ? "active" : ""
                      }`}
                      to="/page/help"
                      target="_blank"
                    >
                      {t("help_center")}
                    </Link>
                    {profile.data?.onboarding_completed == 0 && (
                      <Link className="overlay-btn" to="/onboarding">
                        Initiate Onboarding
                      </Link>
                    )}
                    {profile.data?.kyc_verified == 0 &&
                      profile.data?.onboarding_completed == 1 && (
                        <Link
                          className="overlay-btn"
                          to="#"
                          onClick={verifyKyc}
                        >
                          {kycApplicant.buttonDisable
                            ? "Loading"
                            : "Verify Kyc"}
                        </Link>
                      )}
                    {/* {isLoggedIn ? <Link to="#" onClick={handleLogout} className="nav-link">Logout</Link> : <Link
            className={`nav-link ${location.pathname === "/login" ? "active" : ""}`}
            to="/login"
          >Login</Link>} */}
                  </Nav>
                </Navbar.Collapse>
              </Offcanvas.Body>
            </Offcanvas>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default HeaderIndex;
