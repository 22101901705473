export const bankCountry = [
  { label: "Andorra", value: "AND" },
  { label: "Australia", value: "AUS" },
  { label: "Austria", value: "AUT" },
  { label: "Belgium", value: "BEL" },
  { label: "Bangladesh", value: "BGD" },
  { label: "Bulgaria", value: "BGR" },
  { label: "Switzerland", value: "CHE" },
  { label: "China", value: "CHN" },
  { label: "Cyprus", value: "CYP" },
  { label: "Czech Republic", value: "CZE" },
  { label: "Germany", value: "DEU" },
  { label: "Denmark", value: "DNK" },
  { label: "Spain", value: "ESP" },
  { label: "Estonia", value: "EST" },
  { label: "Finland", value: "FIN" },
  { label: "France", value: "FRA" },
  { label: "United Kingdom", value: "GBR" },
  { label: "Greece", value: "GRC" },
  { label: "Hong Kong", value: "HKG" },
  { label: "Croatia", value: "HRV" },
  { label: "Hungary", value: "HUN" },
  { label: "Indonesia", value: "IDN" },
  { label: "India", value: "IND" },
  { label: "Ireland", value: "IRL" },
  { label: "Iceland", value: "ISL" },
  { label: "Italy", value: "ITA" },
  { label: "Japan", value: "JPN" },
  { label: "Liechtenstein", value: "LIE" },
  { label: "Sri Lanka", value: "LKA" },
  { label: "Lithuania", value: "LTU" },
  { label: "Luxembourg", value: "LUX" },
  { label: "Latvia", value: "LVA" },
  { label: "Monaco", value: "MCO" },
  { label: "Malta", value: "MLT" },
  { label: "Malaysia", value: "MYS" },
  { label: "Netherlands", value: "NLD" },
  { label: "Norway", value: "NOR" },
  { label: "Philippines", value: "PHL" },
  { label: "Poland", value: "POL" },
  { label: "Portugal", value: "PRT" },
  { label: "Romania", value: "ROU" },
  { label: "San Marino", value: "SMR" },
  { label: "Slovakia", value: "SVK" },
  { label: "Slovenia", value: "SVN" },
  { label: "Sweden", value: "SWE" },
  { label: "Thailand", value: "THA" },
  { label: "United States", value: "USA" },
  { label: "Vatican City State", value: "VAT" },
  { label: "Vietnam", value: "VNM" },
];
