import React, { useEffect, useState } from "react";
import { Row, Container, Image, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  createKycApplicantStart,
  profileUpdateStart,
} from "../../store/actions/UserAction";
import { connect } from "react-redux";
import ProfileLoader from "../helper/ProfileLoader";
import KYCUpdateModal from "../KYC/KYCUpdateModal";
import { useTranslation } from "react-multi-lang";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import EditProfileModal from "./EditProfileModal";
import "@djthoms/pretty-checkbox";
import ChangePasswordModal from "./ChangePasswordModal";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import EditAddressModal from "./EditAddressModal";
import ChangeMobileNumberModal from "./ChangeMobileNumberModal";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import LogoutModal from "./LogoutModal";
import DeleteAccountModal from "./DeleteAccountModal";
import ChangeProfileImageModal from "./ChangeProfileImageModal";

const ProfileIndex = (props) => {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [editModal, setEditShow] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [changeMobileNumber, setchangeMobileNumber] = useState(false);
  const [changeProfileImage, setChangeProfileImage] = useState(false);
  const [logoutShow, setLogoutShow] = useState(false);
  const [deleteAccount, setDeleteAccount] = useState(false);

  const uploader = Uploader({
    apiKey: "free",
  });

  const options = {
    multi: false,
    mimeTypes: ["image/jpeg", "image/png", "image/jpg"],
  };

  const t = useTranslation("profile");
  const [kyc, setKyc] = useState(false);
  const [skipRender, setSkipRender] = useState(true);

  const closeKycModal = () => {
    setKyc(false);
  };
  useEffect(() => {
    if (
      !skipRender &&
      !props.kycStatusUpdate.loading &&
      Object.keys(props.kycStatusUpdate.data).length > 0
    ) {
      closeKycModal();
    }
    setSkipRender(false);
  }, [props.kycStatusUpdate]);

  const verifyKyc = () => {
    props.dispatch(createKycApplicantStart());
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.kycApplicant.loading &&
      Object.keys(props.kycApplicant.data).length > 0
    ) {
      window.open(props.kycApplicant.data?.link, "_blank");
    }
    setSkipRender(false);
  }, [props.kycApplicant]);

  useEffect(() => {
    if (
      !skipRender &&
      !props.updateProfile.loading &&
      Object.keys(props.updateProfile.data).length > 0
    ) {
      setEditShow(false);
      setAddressModal(false);
    }
  }, [props.updateProfile]);

  useEffect(() => {
    if (
      !skipRender &&
      !props.changePassword.loading &&
      Object.keys(props.changePassword.data).length > 0
    ) {
      setModalShow(false);
      // navigate("/");
      // localStorage.clear();
      // sessionStorage.clear();
    }
    setSkipRender(false);
  }, [props.changePassword]);

  return (
    <>
      {props.profile.loading ? (
        <ProfileLoader />
      ) : (
        <div className="feature-profile-wrapped">
          <Container>
            <div className="feature-profile-content-frame">
              <div className="feature-primary-profile-card">
                <div className="feature-primary-profile-info-right">
                  <div className="feature-primary-profile-input">
                    <div className="feature-primary-profile-dp">
                      {props.updateProfile.buttonDisable ? (
                        <Skeleton circle={true} height={100} width={100} />
                      ) : (
                        <CustomLazyLoad
                          className="user profile-image"
                          src={
                            props.profile.data.picture ||
                            window.location.origin + "/img/icon/user.png"
                          }
                          type="image/png"
                        />
                      )}
                    </div>
                    {/* <UploadButton
                      uploader={uploader}
                      options={options}
                      onComplete={(files) => {
                        files.length > 0 &&
                          files[0].editedFile.file &&
                          props.dispatch(
                            profileUpdateStart({
                              picture: files[0].editedFile,
                              name: props.profile.data.name,
                              email: props.profile.data.email,
                              website_url: props.profile.data.website_url,
                            })
                          );
                      }}
                    > */}

                    <Button
                      className="new-secondary-btn"
                      onClick={() => setChangeProfileImage(true)}
                    >
                      {t("upload")}
                    </Button>

                    {/* </UploadButton> */}
                  </div>
                  <div className="feature-primary-profile-info">
                    <h3>{props.profile.data.name}</h3>
                    <p>
                      {t(
                        "manage_your_personal_information_email_password_more"
                      )}
                    </p>
                    <div className="feature-primary-profile-details">
                      <h5>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#333"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
                          <path d="M3 7l9 6l9 -6" />
                        </svg>
                        <span>{props.profile.data.email}</span>
                      </h5>
                      <h5>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#333"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                        </svg>
                        <span>
                          +{props.profile.data.country_code}
                          {props.profile.data.mobile || "N/A"}
                        </span>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="feature-primary-profile-info-left">
                  {props.profile.data.kyc_verified ? (
                    <Button className="finger-print-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="none"
                        viewBox="0 0 35 35"
                      >
                        <path
                          fill="#51C8AC"
                          d="M21.328 15.953v-.51c0-3.336-2.453-6.183-5.585-6.482a6.187 6.187 0 00-5.572 2.45 1.092 1.092 0 11-1.749-1.313c1.77-2.356 4.573-3.595 7.528-3.314 4.197.4 7.483 4.13 7.553 8.52-.757.139-1.486.354-2.175.65zM3.464 21.431c.603 0 1.093-.49 1.093-1.094V15.53c0-5.782 4.334-10.592 9.867-10.95a10.61 10.61 0 015.992 1.39 1.091 1.091 0 001.494-.398 1.092 1.092 0 00-.399-1.494 12.752 12.752 0 00-7.228-1.681C7.603 2.83 2.37 8.598 2.37 15.53v4.807c0 .604.49 1.094 1.093 1.094zm4.374-7.395c-.603 0-1.093.49-1.093 1.094v8.75a9.882 9.882 0 004.92 8.527 1.094 1.094 0 101.095-1.894 7.685 7.685 0 01-3.828-6.633v-8.75c0-.604-.49-1.094-1.094-1.094zm11.303 3.21v-1.96c0-2.035-1.439-3.802-3.346-4.112a4.034 4.034 0 00-3.262.9 4.006 4.006 0 00-1.413 3.056v5.833c0 4.822 2.689 9.025 6.644 11.195a10.165 10.165 0 01-2.47-5.048 10.502 10.502 0 01-1.987-6.146V15.13c0-.535.234-1.042.643-1.39a1.812 1.812 0 011.495-.407c.86.14 1.508.979 1.508 1.953v4.246a10.28 10.28 0 012.188-2.286zm5.148-7.402a10.57 10.57 0 011.414 5.286v.018c.753.027 1.484.136 2.188.318v-.336c0-2.243-.59-4.449-1.708-6.38a1.095 1.095 0 00-1.894 1.094z"
                        ></path>
                        <path
                          fill="#1AA080"
                          d="M25.338 18.047a7.292 7.292 0 100 14.583 7.292 7.292 0 000-14.583zm3.433 5.862l-3.889 3.89a.729.729 0 01-1.031 0l-1.945-1.945a.729.729 0 111.031-1.031l1.43 1.429 3.373-3.374a.729.729 0 111.03 1.031z"
                        ></path>
                      </svg>
                      {t("kyc_verified")}
                    </Button>
                  ) : (
                    <>
                      {props.profile.data?.onboarding_completed == 0 && (
                        <Link className="overlay-btn" to="/onboarding">
                          Initiate Onboarding
                        </Link>
                      )}
                      {props.profile.data?.kyc_verified == 0 &&
                        props.profile.data?.onboarding_completed == 1 && (
                          <Button
                            onClick={verifyKyc}
                            className="default-btn kyc-btn"
                            disabled={props.kycApplicant.buttonDisable}
                          >
                            {props.kycApplicant.buttonDisable
                              ? t("loading")
                              : "Verify kyc"}
                          </Button>
                        )}
                    </>
                  )}
                  <div className="feature-profile-action">
                    <Button
                      className="new-action-btn"
                      onClick={() => setchangeMobileNumber(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#333"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                        <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                        <path d="M16 5l3 3" />
                      </svg>
                      <span>{t("edit")}</span>
                    </Button>
                  </div>
                </div>
              </div>
              {/* <div className="feature-second-profile-card">
                <div className="feature-second-profile-right">
                  <div className="feature-second-profile-right-icons">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                      <path d="M3.6 9h16.8" />
                      <path d="M3.6 15h16.8" />
                      <path d="M11.5 3a17 17 0 0 0 0 18" />
                      <path d="M12.5 3a17 17 0 0 1 0 18" />
                    </svg>
                  </div>
                  <div className="feature-second-profile-right-info">
                    <h3>{t("website_urls")}</h3>
                    <p>{props.profile.data.website_url}</p>
                  </div>
                </div>
                <div className="feature-second-profile-left">
                  <div className="feature-profile-action">
                    <Button
                      className="new-action-btn"
                      onClick={() => setEditShow(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#333"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                        <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                        <path d="M16 5l3 3" />
                      </svg>
                      <span>{t("edit")}</span>
                    </Button>
                  </div>
                </div>
              </div> */}
              {props.profile.data?.onboarding_completed == 1 && (
                <div className="feature-second-profile-card">
                  <div className="feature-second-profile-right">
                    <div className="feature-second-profile-right-icons">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#333"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                        <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                      </svg>
                    </div>

                    <div className="feature-second-profile-right-info">
                      <div className="info-details-layout">
                        <div className="info-details">
                          <h3>{t("primary_address.label")}</h3>
                          <p>
                            {props.profile.data.primary_address || t("n_a")}
                          </p>
                        </div>
                        <div className="info-details">
                          <h3>{t("secondary_address.label")}</h3>
                          <p>
                            {props.profile.data.secondary_address || t("n_a")}
                          </p>
                        </div>
                      </div>
                      <div className="feature-info-details-grid cusomte-width">
                        <div className="feature-info-details-info">
                          <h3>{t("city.label")}</h3>
                          <p>{props.profile.data.city || t("n_a")}</p>
                        </div>
                        <div className="feature-info-details-info">
                          <h3>{t("state.label")}</h3>
                          <p>{props.profile.data.state || t("n_a")}</p>
                        </div>
                        <div className="feature-info-details-info">
                          <h3>{t("country.label")}</h3>
                          <p>{props.profile.data.country || t("n_a")}</p>
                        </div>
                        <div className="feature-info-details-info">
                          <h3>{t("zipcode.label")}</h3>
                          <p>{props.profile.data.zipcode || t("n_a")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="feature-second-profile-left">
                    <div className="feature-profile-action">
                      {/* <Button
                      className="new-action-btn"
                      onClick={() => setAddressModal(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#333"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                        <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                        <path d="M16 5l3 3" />
                      </svg>
                      <span>{t("edit")}</span>
                    </Button> */}
                    </div>
                  </div>
                </div>
              )}
              <div className="feature-second-profile-card">
                <div className="feature-second-profile-right">
                  <div className="feature-second-profile-right-icons">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#333"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3" />
                    </svg>
                  </div>
                  <div className="feature-second-profile-right-info">
                    <h3>{t("change_password")}</h3>
                    <p>***** **** *** **</p>
                  </div>
                </div>
                <div className="feature-second-profile-left">
                  <div className="feature-profile-action">
                    <Button
                      className="new-action-btn"
                      onClick={() => setModalShow(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#333"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M5 13a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-6z" />
                        <path d="M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0" />
                        <path d="M8 11v-4a4 4 0 1 1 8 0v4" />
                      </svg>
                      <span>{t("change_password")}</span>
                    </Button>
                  </div>
                </div>
              </div>
              <div className="feature-second-profile-card">
                <div className="feature-second-profile-right">
                  <div className="feature-second-profile-right-icons">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#333"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                      <path d="M9 12h12l-3 -3" />
                      <path d="M18 15l3 -3" />
                    </svg>
                  </div>
                  <div className="feature-second-profile-right-info">
                    <h3>{t("logout")}</h3>
                    <p>{t("if_you_notice_any_para")}</p>
                  </div>
                </div>
                <div className="feature-second-profile-left">
                  <div className="feature-profile-action">
                    <Button
                      className="new-action-btn"
                      onClick={() => setLogoutShow(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#333"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                        <path d="M9 12h12l-3 -3" />
                        <path d="M18 15l3 -3" />
                      </svg>
                      <span>{t("logout")}</span>
                    </Button>
                  </div>
                </div>
              </div>
              <div className="feature-second-profile-card">
                <div className="feature-second-profile-right">
                  <div className="feature-second-profile-right-icons">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#333"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M18 6l-12 12" />
                      <path d="M6 6l12 12" />
                    </svg>
                  </div>
                  <div className="feature-second-profile-right-info">
                    <h3>{t("close_your_account")}</h3>
                    <p>{t("if_you_want_to_stop_para")}</p>
                  </div>
                </div>
                <div className="feature-second-profile-left">
                  <div className="feature-profile-action">
                    <Button
                      className="new-action-btn"
                      onClick={() => setDeleteAccount(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                        <path d="M6 21v-2a4 4 0 0 1 4 -4h3.5" />
                        <path d="M22 22l-5 -5" />
                        <path d="M17 22l5 -5" />
                      </svg>
                      <span>{t("delete_account")}</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
      {kyc && <KYCUpdateModal kyc={kyc} closeKyc={closeKycModal} />}

      {/* Edit PopUp  */}
      {editModal ? (
        <EditProfileModal show={editModal} onHide={() => setEditShow(false)} />
      ) : null}
      {modalShow ? (
        <ChangePasswordModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      ) : null}
      {addressModal ? (
        <EditAddressModal
          show={addressModal}
          onHide={() => setAddressModal(false)}
        />
      ) : null}

      {changeMobileNumber ? (
        <ChangeMobileNumberModal
          profile={props.profile}
          show={changeMobileNumber}
          onHide={() => setchangeMobileNumber(false)}
          setchangeMobileNumber={setchangeMobileNumber}
        />
      ) : null}
      {changeProfileImage ? (
        <ChangeProfileImageModal
          profile={props.profile}
          show={changeProfileImage}
          onHide={() => setChangeProfileImage(false)}
        />
      ) : null}
      {logoutShow ? (
        <LogoutModal show={logoutShow} onHide={() => setLogoutShow(false)} />
      ) : null}
      {deleteAccount ? (
        <DeleteAccountModal
          show={deleteAccount}
          onHide={() => setDeleteAccount(false)}
        />
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.user.profile,
  kycStatusUpdate: state.user.updateKYC,
  kycApplicant: state.user.kycApplicant,
  updateProfile: state.user.updateProfile,
  changePassword: state.user.changePassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(mapStateToPros, mapDispatchToProps)(ProfileIndex);
