import React, { useEffect, useRef, useState } from "react";
import {
  InputGroup,
  Table,
  Button,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Field, Form as FORM, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { recipientListStart } from "../../store/actions/RecipientAction";
import NoDataFound from "../helper/NoDataFound";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import * as Yup from "yup";

const RecipientList = () => {
  const formRef = useRef();
  const t = useTranslation("recipient.recipient_list");
  const dispatch = useDispatch();
  const recipientList = useSelector((state) => state.recipient.recipientList);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = useState({
    search_key: searchParams.get("search_key")
      ? searchParams.get("search_key")
      : "",
  });

  useEffect(() => {
    dispatch(recipientListStart(filter));
  }, [filter]);

  const validationSchema = Yup.object().shape({
    search_key: Yup.string()
      .required(t("required"))
      .matches(/^(?=\S*$)/, t("white_space")),
  });

  return (
    <>
      <div className="wallet-full-transaction-table">
        <Container>
          <Row>
            <Col md={12}>
              {recipientList.loading ? (
                <>
                  <div className="recipient-table-head">
                    <div className="recipient-table-heading">
                      <Skeleton height={30} width={150} />
                    </div>
                    <div className="recipient-table-action">
                      <Formik
                        initialValues={{
                          search_key: searchParams.get("search_key")
                            ? searchParams.get("search_key")
                            : "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(val) => {
                          setFilter({
                            ...filter,
                            search_key: val.search_key,
                          });

                          navigate(
                            searchParams.get("sort_by")
                              ? `/recipient-list?search_key=${
                                  val.search_key
                                }&search_key=${searchParams.get("search_key")}`
                              : `/recipient-list?search_key=${val.search_key}`
                          );
                        }}
                        innerRef={formRef}
                      >
                        {({ values, setFieldValue, resetForm }) => (
                          <FORM className="form transaction-form">
                            <InputGroup className="mb-0">
                              <Field
                                name="search_key"
                                placeholder={t("search")}
                                className="form-control"
                                onKeyPress={(event) => {
                                  if (event.key === "Enter") {
                                    event.preventDefault();
                                    formRef.current.handleSubmit();
                                  }
                                }}
                              />
                              <InputGroup.Text id="basic-addon2">
                                {searchParams.get("search_key") ? (
                                  <Button
                                    type="button"
                                    onClick={() => {
                                      setFieldValue("search_key", "");
                                      setFilter({
                                        ...filter,
                                        search_key: "",
                                      });
                                      navigate(
                                        searchParams.get("sort_by")
                                          ? `/recipient-list?sort_by=${searchParams.get(
                                              "search_key"
                                            )}`
                                          : `/recipient-list`
                                      );
                                    }}
                                  >
                                    <svg
                                      height="24"
                                      width="24"
                                      viewBox="0 0 20 20"
                                      aria-hidden="true"
                                      focusable="false"
                                      fill="#333"
                                    >
                                      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                    </svg>
                                  </Button>
                                ) : (
                                  <Button type="submit">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="#333"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      />
                                      <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                                      <path d="M21 21l-6 -6" />
                                    </svg>
                                  </Button>
                                )}
                              </InputGroup.Text>
                            </InputGroup>
                          </FORM>
                        )}
                      </Formik>
                      <Button
                        className="default-btn"
                        onClick={() => navigate("/create-personal-recipient")}
                      >
                        {t("heading")}
                      </Button>
                    </div>
                  </div>
                  <div className="new-table-frame">
                    {[...Array(8)].map(() => (
                      <Skeleton height={70} borderRadius={10} />
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <div className="recipient-table-head">
                    <div className="recipient-table-heading">
                      <h3>{t("content")}</h3>
                    </div>
                    <div className="recipient-table-action">
                      <Formik
                        initialValues={{
                          search_key: searchParams.get("search_key")
                            ? searchParams.get("search_key")
                            : "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(val) => {
                          setFilter({
                            ...filter,
                            search_key: val.search_key,
                          });

                          navigate(
                            searchParams.get("sort_by")
                              ? `/recipient-list?search_key=${
                                  val.search_key
                                }&search_key=${searchParams.get("search_key")}`
                              : `/recipient-list?search_key=${val.search_key}`
                          );
                        }}
                        innerRef={formRef}
                      >
                        {({ values, setFieldValue, resetForm }) => (
                          <FORM className="form transaction-form">
                            <InputGroup className="mb-0">
                              <Field
                                name="search_key"
                                placeholder={t("search")}
                                className="form-control"
                                onKeyPress={(event) => {
                                  if (event.key === "Enter") {
                                    event.preventDefault();
                                    formRef.current.handleSubmit();
                                  }
                                }}
                              />
                              <InputGroup.Text id="basic-addon2">
                                {searchParams.get("search_key") ? (
                                  <Button
                                    type="button"
                                    onClick={() => {
                                      setFieldValue("search_key", "");
                                      setFilter({
                                        ...filter,
                                        search_key: "",
                                      });
                                      navigate(
                                        searchParams.get("sort_by")
                                          ? `/recipient-list?sort_by=${searchParams.get(
                                              "search_key"
                                            )}`
                                          : `/recipient-list`
                                      );
                                    }}
                                  >
                                    <svg
                                      height="24"
                                      width="24"
                                      viewBox="0 0 20 20"
                                      aria-hidden="true"
                                      focusable="false"
                                      fill="#333"
                                    >
                                      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                    </svg>
                                  </Button>
                                ) : (
                                  <Button type="submit">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="#333"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      />
                                      <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                                      <path d="M21 21l-6 -6" />
                                    </svg>
                                  </Button>
                                )}
                              </InputGroup.Text>
                            </InputGroup>
                          </FORM>
                        )}
                      </Formik>
                      <Button
                        className="default-btn"
                        onClick={() => navigate("/create-personal-recipient")}
                      >
                        {t("heading")}
                      </Button>
                    </div>
                  </div>
                  {Object.keys(recipientList.data).length > 0 &&
                  recipientList.data?.total_stylopay_recipients > 0 ? (
                    <div className="new-table-frame">
                      <Table responsive className="new-feature-table">
                        <thead>
                          <tr>
                            <th>{t("name")}</th>
                            <th>{t("id")}</th>
                            <th>{t("email")}</th>
                            <th>{t("phone")}</th>
                            <th>{t("city")}</th>
                            <th>{t("pincode")}</th>
                            <th>{t("action")}</th>
                          </tr>
                        </thead>
                        {recipientList.data.stylopay_recipients.map(
                          (recipient, index) => (
                            <tbody>
                              <tr>
                                <td>
                                  <div className="wallet-payment-status">
                                    <p className="table-bold-text">
                                      {recipient.first_name}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div className="wallet-payment-status">
                                    <p className="table-bold-text">
                                      {recipient.unique_id}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div className="wallet-payment-status">
                                    <p className="table-bold-text">
                                      {recipient.email}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div className="wallet-payment-status">
                                    <p className="table-bold-text">
                                      {recipient.mobile}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div className="wallet-payment-status">
                                    <p className="table-bold-text">
                                      {recipient.city}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div className="wallet-payment-status">
                                    <p className="table-bold-text">
                                      {recipient.postal_code}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <Button
                                    className="new-view-btn"
                                    onClick={() =>
                                      navigate("/recipient-detail", {
                                        state: recipient.id,
                                      })
                                    }
                                  >
                                    {t("view")}
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          )
                        )}
                      </Table>
                    </div>
                  ) : (
                    <NoDataFound />
                  )}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default RecipientList;
