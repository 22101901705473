import React, { useState, useEffect } from "react";
import {
  Image,
  Row,
  Col,
  Tab,
  Nav,
  Button,
  Form,
  Container,
} from "react-bootstrap";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { buyTokenStart } from "../../store/actions/UserAction";
import BuySuccess from "./BuySuccess";
import BuyFailure from "./BuyFailure";
import Processing from "../Common/Processing";

const PaymentResponse = (props) => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const buyToken = useSelector((state) => state.user.buyToken);

  useEffect(() => {
    dispatch(buyTokenStart({
      order_id: searchParams.get('order_id'),
      stylopay_status: pathname == "/success" ? 1 : 2
    }));
  }, []);

  return (
    <>
      <div className="exchange-sec">
        <Container>
          <div className="buy-crypto-sec">
            <Row className="justify-content-md-center">
              <Col md={10} xl={5} lg={7}>
                <div className="exchange-box">
                  {buyToken.loading ? (
                    <Processing />
                  ) : Object.keys(buyToken.data).length > 0 ? (
                    <BuySuccess />
                  ) : (
                    <BuyFailure />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default PaymentResponse;
