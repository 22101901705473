import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
const NoDataFound = () => {
  const t = useTranslation("no_data_found")
  return (
    <>
      <div className="text-center">
        <Image
          className="no-data-img"
          src={window.location.origin + "/img/no-data-found.png"}
        />
        {/* <div className="no-bot-exists-info">
          <h4>{t("heading")}</h4> 
        </div> */}
      </div>
    </>
  );
};

export default NoDataFound;
