import React, { useEffect, useState, useRef } from 'react';
import { Form, Button, Modal } from "react-bootstrap";
import '../Wallet/Wallet.css';
import { connect } from 'react-redux';
import * as Yup from "yup";
import { profileUpdateStart } from "../../store/actions/UserAction";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { useTranslation } from 'react-multi-lang';
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";

const ChangeMobileNumberModal = (props) => {

  const t = useTranslation("change_mobileNumber");
  const [skipRender, setSkipRender] = useState(true);
  const formRef = useRef();
  const [defaultCountry, setDefaultCountry] = useState("IN");

  const validateMobileNumber = (value) => {

    if (value) {
      if (isPossiblePhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("user_mobile.invalid"));
        return false;
      } else if (isValidPhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("user_mobile.invalid"));
        return false;
      } else {
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", t("user_mobile.required"));
      return false;
    }
  };
  const MobileNumberUpdateSchema = Yup.object().shape({
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
    name: Yup.string().required(t("full_name.required")),
    mobile: Yup.string()
      .test("valid-mobile", t("user_mobile.invalid"), (value) => {

        if (!value) {

          return false;
        }
        return isValidPhoneNumber(value);
      })
      .required(t("user_mobile.required"))
      .matches(/^(?=.*[0-9])/, t("user_mobile.space_values_not_valid")),

  });

  const handleSubmit = (values) => {

    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");
      props.dispatch(profileUpdateStart({
        name: values.name,
        email: props.profile.data.email,
        website_url: props.profile.data.website_url,
        mobile: mobile,
        country_code: countryCode,
      }));
      props.setchangeMobileNumber(values.mobile,);
    }
  };

  useEffect(() => {
    if (!skipRender &&
      !props.updateProfile.loading &&
      Object.keys(props.updateProfile.data).length > 0) {
      props.setchangeMobileNumber(false);
    }
    setSkipRender(false);
  }, [props.updateProfile])


  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="new-modal-custome"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className='text-center' >{t("heading")}</h4>
          <Formik
            initialValues={{
              name: props.profile.data.name,
              email: props.profile.data.email,
              mobile: `+${props.profile.data.country_code}${props.profile.data.mobile}`
            }}
            validationSchema={MobileNumberUpdateSchema}
            onSubmit={handleSubmit}
            innerRef={formRef}
          >
            {({ values, setFieldValue, touched, errors }) => (
              <FORM className="new-feature-form">
                <Form.Group
                  controlId="formBasicEmail"
                  className="mb-3"
                >
                  <Form.Label>
                    {t("full_name.label")} <span>*</span>
                  </Form.Label>
                  <Field
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder={t("full_name.placeholder")}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="name"
                    className="text-danger"
                  />
                </Form.Group>
                <Form.Group
                  controlId="formBasicEmail"
                  className="mb-3"
                >
                  <Form.Label>
                    {t("email.label")} <span>*</span>
                  </Form.Label>
                  <Field
                    type="email"
                    className="form-control email-disable"
                    name="email"
                    placeholder={t("email.placeholder")}
                    disabled
                  />
                  <ErrorMessage
                    component={"div"}
                    name="email"
                    className="text-danger"
                  />
                </Form.Group>
                <Form.Group
                  controlId="formBasicEmail"
                  className="mb-3"
                >
                  <Form.Label>
                    {t("user_mobile.label")} <span>*</span>
                  </Form.Label>
                  <PhoneInput
                    name="mobile"
                    defaultCountry={defaultCountry}
                    placeholder={t("user_mobile.placeholder")}
                    value={values.mobile}
                    onChange={(value) =>
                      setFieldValue("mobile", value)
                    }

                    international
                    className={`${touched.mobile && errors.mobile
                      ? "is-invalid"
                      : ""
                      }`}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="mobile"
                    className="text-danger"
                  />
                </Form.Group>
                <div className="new-action-frame layout-element">
                  <Button
                    className="new-overlay-btn"
                    onClick={props.onHide}>
                    {t("save_changes.cancel")}
                  </Button>
                  <Button
                    className="new-primary-btn"
                    type="submit"
                    disabled={
                      props.updateProfile.buttonDisable ||
                      values.mobile == props.profile.data.mobile
                    }
                  >
                    {props.updateProfile.buttonDisable
                      ? t("loading")
                      : t("save_changes.text")}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

    </>
  )
};

const mapStateToPros = (state) => ({
  profile: state.user.profile,
  updateProfile: state.user.updateProfile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(
  mapStateToPros,
  mapDispatchToProps
)(ChangeMobileNumberModal);
