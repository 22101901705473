import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "../Wallet/Wallet.css";
import { Link } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getBankAccountStart } from "../../store/actions/BankAccountAction";
import CommonCenterLoader from "../helper/CommonCenterLoader";
import { useTranslation } from "react-multi-lang";
import { withdrawalRequestStart } from "../../store/actions/BuyAction";
import configuration, { set } from "react-global-configuration";
import { recipientListStart } from "../../store/actions/RecipientAction";
import { generateQuoteStart } from "../../store/actions/BuyAction";

const WithDrawWalletModal = (props) => {
  const formref = useRef();
  const t = useTranslation("withdraw_modal");
  const validationSchema = Yup.object().shape({
    stylopay_recipient_id: Yup.string().required(t("bank_account.required")),
    amount: Yup.number()
      .required(t("amount.required"))
      .min(10, t("amount.invalid")),
  });
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const bankAccountList = useSelector((state) => state.bankAccount.bankAccount);
  const withdrawalRequest = useSelector((state) => state.buy.withdrawalRequest);
  const generateQuote = useSelector((state) => state.buy.generateQuote);
  const [skipRender, setSkipRender] = useState(true);
  const recipientList = useSelector((state) => state.recipient.recipientList);

  const handleSubmit = (values) => {
    dispatch(
      generateQuoteStart({
        ...values,
        type: "forex",
        user_wallet_id: props.user_wallet_id,
      })
    );
  };

  const handleSubmitWithDrawal = (values) => {
    dispatch(
      withdrawalRequestStart({
        user_withdrawal_id:
          generateQuote.data?.user_withdrawal?.user_withdrawal_id,
      })
    );
  };

  useEffect(() => {
    dispatch(recipientListStart({ currency: props.currency }));
  }, []);

  useEffect(() => {
    if (
      !skipRender &&
      !withdrawalRequest.loading &&
      Object.keys(withdrawalRequest.data).length > 0
    ) {
      props.onHide();
    }
    setSkipRender(false);
  }, [withdrawalRequest]);

  useEffect(() => {
    if (
      !skipRender &&
      !generateQuote.loading &&
      Object.keys(generateQuote.data).length > 0
    ) {
      setStep(2);
    }
    setSkipRender(false);
  }, [generateQuote]);

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="withdrawwallet"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("heading")} {`(${props.currency})`}
          </Modal.Title>
          <div className="modal-close-btn-area">
            <Button onClick={props.onHide}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                enableBackground="new 0 0 512 512"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#131615"
                  d="M18 6a1 1 0 00-1.414 0L12 10.586 7.414 6A1 1 0 006 6a1 1 0 000 1.414L10.586 12 6 16.586A1 1 0 006 18a1 1 0 001.414 0L12 13.414 16.586 18A1 1 0 0018 18a1 1 0 000-1.414L13.414 12 18 7.414A1 1 0 0018 6z"
                  data-original="#000000"
                ></path>
              </svg>
            </Button>
          </div>
        </Modal.Header>
        {step == 1 ? (
          recipientList.loading ? (
            <CommonCenterLoader />
          ) : Object.keys(recipientList.data).length > 0 ? (
            <>
              <Formik
                initialValues={{
                  amount: "",
                  stylopay_recipient_id: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ touched, errors, setFieldValue, values }) => (
                  <FORM className="exchange-form">
                    <Modal.Body>
                      <div className="auth-form-box">
                        <Form.Group className="mb-3">
                          <Form.Label>
                            {t("bank_account.label")}
                            <span>*</span>
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="stylopay_recipient_id"
                            className="bank-select"
                            onChange={(e) =>
                              setFieldValue(
                                "stylopay_recipient_id",

                                e.target.value
                              )
                            }
                          >
                            <option value="">
                              {t("bank_account.placeholder")}
                            </option>
                            {recipientList.data.stylopay_recipients.map(
                              (account, i) => (
                                <option key={i} value={account.id}>
                                  {account.first_name} -{" "}
                                  {account.account_number}
                                </option>
                              )
                            )}
                          </Form.Select>
                          <ErrorMessage
                            component={"div"}
                            name="stylopay_recipient_id"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            {t("amount.label")} <span>*</span>
                          </Form.Label>
                          <Field
                            type="number"
                            name="amount"
                            placeholder={t("amount.placeholder")}
                            className="form-control"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="amount"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>{t("service_fee")}</Form.Label>
                          <Field
                            className="form-control"
                            type="text"
                            name="withdraw_commission"
                            value={
                              values.amount *
                              (configuration.get(
                                "configData.withdraw_commission"
                              ) /
                                100)
                            }
                            disabled
                          />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>{t("user_receive_amount")}</Form.Label>
                          <Field
                            className="form-control"
                            type="text"
                            name="withdraw_commission"
                            value={
                              values.amount -
                              values.amount *
                                (configuration.get(
                                  "configData.withdraw_commission"
                                ) /
                                  100)
                            }
                            disabled
                          />
                        </Form.Group>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        className="default-btn cancel"
                        onClick={props.onHide}
                        disabled={generateQuote.buttonDisable}
                      >
                        {t("cancel")}
                      </Button>
                      <Button
                        type="submit"
                        className="default-btn save"
                        disabled={generateQuote.buttonDisable}
                      >
                        {generateQuote.buttonDisable
                          ? t("loading")
                          : t("generate_quote")}
                      </Button>
                    </Modal.Footer>
                  </FORM>
                )}
              </Formik>
            </>
          ) : (
            <Modal.Body>
              <div className="withdraw-add-other-account-wrap">
                <Link
                  to="/create-personal-recipient"
                  className="withdraw-add-other-account-area"
                >
                  <span>
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="#4495d3"
                        d="M480 224H288V32c0-17.673-14.327-32-32-32s-32 14.327-32 32v192H32c-17.673 0-32 14.327-32 32s14.327 32 32 32h192v192c0 17.673 14.327 32 32 32s32-14.327 32-32V288h192c17.673 0 32-14.327 32-32s-14.327-32-32-32z"
                        data-original="#000000"
                      ></path>
                    </svg>
                  </span>
                  <p>{t("add_bank_account")}</p>
                </Link>
              </div>
            </Modal.Body>
          )
        ) : (
          <Formik
            initialValues={{
              request_amount: "",
              receive_amount: "",
              requested_time: "",
              stylopay_recipient_id: "",
            }}
            //  validationSchema={validationSchema}
            onSubmit={handleSubmitWithDrawal}
            innerRef={formref}
          >
            {({ touched, errors, setFieldValue, values }) => (
              <FORM className="exchange-form">
                <Modal.Body>
                  <div className="auth-form-box">
                    <Form.Group controlId="formBasicEmail" className="mb-3">
                      <Form.Label>
                        Requested Amount <span>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          generateQuote.data.user_withdrawal
                            .requested_amount_formatted
                        }
                        placeholder={t("amount.placeholder")}
                        className="form-control"
                        disabled={true}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="mb-3">
                      <Form.Label>
                        Recipient will receive <span>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          generateQuote.data.user_withdrawal
                            .user_amount_formatted
                        }
                        placeholder={t("amount.placeholder")}
                        className="form-control"
                        disabled={true}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="mb-3">
                      <Form.Label>
                        Requested at <span>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={generateQuote.data.user_withdrawal.created_at}
                        placeholder={t("amount.placeholder")}
                        className="form-control"
                        disabled={true}
                      />
                    </Form.Group>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className="default-btn cancel"
                    onClick={props.onHide}
                    disabled={
                      withdrawalRequest.buttonDisable ||
                      generateQuote.buttonDisable
                    }
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    type="submit"
                    className="default-btn save"
                    disabled={withdrawalRequest.buttonDisable}
                  >
                    {withdrawalRequest.buttonDisable
                      ? t("loading")
                      : t("withdraw")}
                  </Button>
                </Modal.Footer>
              </FORM>
            )}
          </Formik>
        )}
      </Modal>
    </>
  );
};

export default WithDrawWalletModal;
