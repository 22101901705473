import React, { useEffect, useState } from 'react';
import { Modal, Button, Table } from "react-bootstrap";
import { swapTokensViewStart } from '../../store/actions/BuyAction';
import Skeleton from 'react-loading-skeleton';
import './transaction.css';
import { useTranslation } from 'react-multi-lang';
import { useSelector, useDispatch } from 'react-redux';

const BuyTransactionModal = (props) => {

  const { transactionId } = props;
  const dispatch = useDispatch();
  const t = useTranslation("transactions");
  const swapTokensView = useSelector(state => state.buy.swapTokensView);

  useEffect(() => {
    dispatch(swapTokensViewStart({
      swap_transaction_id: transactionId
    }));
  }, []);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="new-modal-custome"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        {swapTokensView.loading ?
          <Modal.Body>
            <div className="feature-modal-content">
              <Skeleton count={6} height={60} width={"100%"} />
            </div>
          </Modal.Body>
          :
          Object.keys(swapTokensView.data).length > 0 ?
            <Modal.Body>
              <div className="feature-modal-content">
                <div className="feature-content-header">
                  <div className="feature-title-card">
                    <h2>{t("swap_transaction_details")}</h2>
                  </div>
                  <div className="feature-title-card-info">
                    <h3> {swapTokensView.data.swap_transaction.amount_formatted}</h3>
                    <div className="new-badges-frame">
                      {swapTokensView.data.swap_transaction.status == 1 ?
                        <div className="new-badges-card success">
                          {swapTokensView.data.swap_transaction.status_formatted}
                        </div>
                        :
                        <div className="new-badges-card pending">
                          {swapTokensView.data.swap_transaction.status_formatted}
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <div className="feature-content-main">
                  <div className="feature-content-grid grid-three-layout">
                    <div className="feature-content-card">
                      <p>{t("buy_transaction.transaction_date")}</p>
                      <h5>{swapTokensView.data.swap_transaction.created_at_formatted}</h5>
                    </div>
                    <div className="feature-content-card">
                      <p>{t("buy_transaction.exchange_value")}</p>
                      <h5>{swapTokensView.data.swap_transaction.adjusted_exchange_rate_formatted}</h5>
                    </div>
                    {/* <div className="feature-content-card">
                      <p>{t("buy_transaction.token")}</p>
                      <h5>{swapTokensView.data.swap_transaction.tokens_formatted}</h5>
                    </div> */}
                    <div className="feature-content-card">
                      <p>{t("buy_transaction.from")}</p>
                      <h5>{swapTokensView.data.swap_transaction.from_amount_formatted}</h5>
                    </div>
                    <div className="feature-content-card">
                      <p>{t("buy_transaction.to")}</p>
                      <h5>{swapTokensView.data.swap_transaction.to_amount_formatted}</h5>
                    </div>
                  </div>
                  <div className="feature-hr"></div> 
                </div>
                <div className="feature-content-footer">
                  <Button className="new-action-btn" onClick={props.onHide}>
                    <span>{t("close")}</span>
                  </Button>
                </div>
              </div>
            </Modal.Body>
            :
            null
        }
      </Modal>
    </>
  )
};


export default BuyTransactionModal;