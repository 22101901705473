import React from "react";
import { Container } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const ProfileLoader = () => {
  return (
    <div className="feature-profile-wrapped">
      <Container>
        <div className="feature-profile-content-frame">
          <div className="feature-primary-profile-cards">
            <Skeleton count={1} width={"100%"} height={200} />
          </div>
          <div className="feature-second-profile-cards">
            <Skeleton count={1} width={"100%"} height={98} />
          </div>
          <div className="feature-second-profile-cards">
            <Skeleton count={1} width={"100%"} height={195} />
          </div>
          <div className="feature-second-profile-cards">
            <Skeleton count={1} width={"100%"} height={98} />
          </div>
          <div className="feature-second-profile-cards">
            <Skeleton count={1} width={"100%"} height={98} />
          </div>
          <div className="feature-second-profile-cards">
            <Skeleton count={1} width={"100%"} height={98} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ProfileLoader;