import {
    FETCH_WALLET_DETAILS_START,
    FETCH_WALLET_DETAILS_SUCCESS,
    FETCH_WALLET_DETAILS_FAILURE,
    FETCH_ALL_TRANSACTION_START,
    FETCH_ALL_TRANSACTION_SUCCESS,
    FETCH_ALL_TRANSACTION_FAILURE,
    FETCH_MORE_ALL_TRANSACTION_START,
    SEND_WITHDRAW_REQUEST_START,
    SEND_WITHDRAW_REQUEST_SUCCESS,
    SEND_WITHDRAW_REQUEST_FAILURE,
    FETCH_WITHDRAWALS_START,
    FETCH_MORE_WITHDRAWALS_START,
    FETCH_WITHDRAWALS_SUCCESS,
    FETCH_WITHDRAWALS_FAILURE,
    CANCEL_WITHDRAW_REQUEST_START,
    CANCEL_WITHDRAW_REQUEST_SUCCESS,
    CANCEL_WITHDRAW_REQUEST_FAILURE,
    FETCH_SINGLE_WALLET_VIEW_START,
    FETCH_SINGLE_WALLET_VIEW_SUCCESS,
    FETCH_SINGLE_WALLET_VIEW_FAILURE
} from "./ActionConstant";


export function fetchWalletDetailsStart(data) {
    return {
        type: FETCH_WALLET_DETAILS_START,
        data,
    };
}

export function fetchWalletDetailsSuccess(data) {
    return {
        type: FETCH_WALLET_DETAILS_SUCCESS,
        data,
    };
}

export function fetchWalletDetailsFailure(error) {
    return {
        type: FETCH_WALLET_DETAILS_FAILURE,
        error,
    };
}

export function fetchSingleWalletViewStart(data) {
    return {
        type: FETCH_SINGLE_WALLET_VIEW_START,
        data,
    };
}

export function fetchSingleWalletViewSuccess(data) {
    return {
        type: FETCH_SINGLE_WALLET_VIEW_SUCCESS,
        data,
    };
}

export function fetchSingleWalletViewFailure(error) {
    return {
        type: FETCH_SINGLE_WALLET_VIEW_FAILURE,
        error,
    };
}


export function fetchAllTransactionStart(data) {
    return {
        type: FETCH_ALL_TRANSACTION_START,
        data,
    };
}

export function fetchAllTransactionSuccess(data) {
    return {
        type: FETCH_ALL_TRANSACTION_SUCCESS,
        data,
    };
}

export function fetchAllTransactionFailure(error) {
    return {
        type: FETCH_ALL_TRANSACTION_FAILURE,
        error,
    };
}

export function fetchMoreAllTransactionStart(data) {
    return {
        type: FETCH_MORE_ALL_TRANSACTION_START,
        data,
    };
}

export function sendWithDrawRequestStart(data) {
    return {
      type: SEND_WITHDRAW_REQUEST_START,
      data,
    };
  }
  
  export function sendWithDrawRequestSuccess(data) {
    return {
      type: SEND_WITHDRAW_REQUEST_SUCCESS,
      data,
    };
  }
  
  export function sendWithDrawRequestFailure(error) {
    return {
      type: SEND_WITHDRAW_REQUEST_FAILURE,
      error,
    };
  }

  export function fetchWithDrawalsStart(data) {
    return {
      type: FETCH_WITHDRAWALS_START,
      data,
    };
  }
  
  export function fetchWithDrawalsSuccess(data) {
    return {
      type: FETCH_WITHDRAWALS_SUCCESS,
      data,
    };
  }
  
  export function fetchWithDrawalsFailure(error) {
    return {
      type: FETCH_WITHDRAWALS_FAILURE,
      error,
    };
  }

  export function fetchMoreWithdrawalsStart(data) {
    return {
        type: FETCH_MORE_WITHDRAWALS_START,
        data,
    };
}
  
  export function cancelWithDrawRequestStart(data) {
    return {
      type: CANCEL_WITHDRAW_REQUEST_START,
      data,
    };
  }
  
  export function cancelWithDrawRequestSuccess(data) {
    return {
      type: CANCEL_WITHDRAW_REQUEST_SUCCESS,
      data,
    };
  }
  
  export function cancelWithDrawRequestFailure(error) {
    return {
      type: CANCEL_WITHDRAW_REQUEST_FAILURE,
      error,
    };
  }