import React, { useEffect, useState, useRef } from 'react';
import { Form, InputGroup, Table, Image, Button } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import NoDataFound from '../helper/NoDataFound';
import {
  tokenSwapTransactionsListStart,
  swapTokensExportStart,
} from "../../store/actions/BuyAction"
import { useTranslation } from 'react-multi-lang';
import { useSelector, useDispatch } from 'react-redux';
import { getErrorNotificationMessage } from '../helper/ToastNotification';
import { Field, Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, useSearchParams } from 'react-router-dom';
import Pagination from '../helper/Pagination';
import SwapTransactionModal from './SwapTransactionModal';
import Select from "react-select";
import DatePicker from "react-multi-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);


const SwapTransaction = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [searchParams] = useSearchParams();
  const t = useTranslation("transactions");
  const options = [
    { value: "0", label: t("processing") },
    { value: "1", label: t("success") },
    { value: "2 ", label: t("failed") },
  ];

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#111",
      fontSize: "0.85em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#32089F",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#32089F!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#32089F!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#111",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  const [skipRender, setSkipRender] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [value, setValue] = useState([null, null]);
  const swapData = useSelector(state => state.buy.tokenSwapTransactionsList);
  const swapTokensExport = useSelector(state => state.buy.swapTokensExport);
  const [filterStatus, setFilterStatus] = useState(false);
  const [filter, setFilter] = useState({
    search_key: searchParams.get("search_key")
      ? searchParams.get("search_key")
      : "",
    status: "",
  });
  const [close, setClose] = useState(false);
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1);
  const calenderRefs = useRef(null);
  const [dateValues, setDateValues] = useState({
    from_date: "",
    to_date: "",
  })
 
 
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      tokenSwapTransactionsListStart({
        ...filter,
        skip: 12 * (page - 1),
        take: 12,
      })
    );
  }, [filter, page]);

  const handleDateChange = (values) => {
    if (values) {

      setValue(values);
      formRef?.current?.setFieldValue("from_date", values && values.length > 0 ? JSON.stringify(new Date(values[0])).slice(1, 11) : "");
      formRef?.current?.setFieldValue("to_date", values && values.length > 1 ? JSON.stringify(new Date(values[1])).slice(1, 11) : "");
      setDateValues({
        from_date: values.length > 0 ? JSON.stringify(new Date(values[0])).slice(1, 11) : "",
        to_date: values.length > 1 ? JSON.stringify(new Date(values[1])).slice(1, 11) : "",
      })
    }
    else {
      setDateValues({
        from_date: "",
        to_date: "",
      })
      formRef?.current?.setFieldValue("from_date", "");
      formRef?.current?.setFieldValue("to_date", "");
      calenderRefs.current.closeCalendar();
      setFilter({
        ...filter,
        skip: 12 * (page - 1),
        take: 12,
        from_date: "",
        to_date: "",
      })

    }
  }

  const handleExport = () => {
    dispatch(swapTokensExportStart({
      ...filter,
      from_date: dateValues.from_date,
      to_date: dateValues.to_date,
    }))
  }

  const handlePageClick = ({ selected }) => {
    let params = searchParams.get("search_key")
      ? `search_key=${searchParams.get("search_key")}&`
      : "";
    navigate(`/transactions?${params}page=${selected + 1}`);
  };

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
      setFilter({
        ...filter,
        search_key: searchParams.get("search_key")
          ? searchParams.get("search_key")
          : "",
        from_date: dateValues.from_date,
        to_date: dateValues.to_date,
      });
    }
    setSkipRender(false);
  }, [searchParams.get("page"), searchParams.get("search_key")]);

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
    }
    setSkipRender(false);
  }, [searchParams.get("page")]);

  useEffect(() => {
    if (!skipRender && !swapTokensExport.buttonDisable && Object.keys(swapTokensExport.data).length > 0) {
      window.open(swapTokensExport.data.url, '_blank', 'noreferrer')
    }
    setSkipRender(false);
  }, [swapTokensExport]);

  const validationSchema = Yup.object().shape({
    search_key: Yup.string()
      .required(t("required"))
      .matches(/^(?=\S*$)/, t("white_space")),
  });

  return (
    <>
      <div className="feature-transaction-sort-wrapped">
        <Formik
          initialValues={{
            search_key: searchParams.get("search_key")
              ? searchParams.get("search_key")
              : "",
            sort_by: searchParams.get("sort_by")
              ? searchParams.get("sort_by")
              : filter.sort_by,
            from_date: "",
            to_date: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(val) => {

            setFilter({
              ...filter,
              search_key: val.search_key,
              from_date: dateValues.from_date,
              to_date: dateValues.to_date
            });

            navigate(
              searchParams.get("sort_by")
                ? `/transactions?search_key=${val.search_key}&sort_by=${searchParams.get("sort_by")}`
                : `/transactions?search_key=${val.search_key}`, { state: { value: value } }
            );
          }}
          innerRef={formRef}
        >
          {({ values, setFieldValue, resetForm }) => (
            <FORM className="form transaction-form" style={{ display: "flex", gap: "0.5em" }}>
              <div className="feature-transaction-left-frame">
                <div className="new-feature-form">
                  <InputGroup className="mb-0">
                    <Field
                      name="search_key"
                      placeholder={t("search")}
                      className="form-control"
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          formRef.current.handleSubmit();
                        }
                      }}
                    />
                    <InputGroup.Text id="basic-addon2">
                    { searchParams.get("search_key") ?   <Button
                          type="button"
                          onClick={() => {
                            setFieldValue("search_key", "");
                            setFilter({
                              ...filter,
                              search_key: "",
                              from_date: dateValues.from_date,
                              to_date: dateValues.to_date,
                            });
                            navigate(
                              searchParams.get("sort_by")
                                ? `/transactions?sort_by=${searchParams.get(
                                  "sort_by"
                                )}`
                                : `/transactions`
                            );
                            
                          }}
                        >
                          <svg height="24" width="24" viewBox="0 0 20 20" aria-hidden="true" focusable="false" fill='#333'><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                        </Button> :   <Button type="submit">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                        <path d="M21 21l-6 -6" />
                      </svg>
                    </Button> }
                    </InputGroup.Text>
                  </InputGroup>
                </div>
              </div>
              <div className="feature-transaction-right-frame">
                <div className="new-feature-form">
                  <Form.Group className={`d-flex date-sec ${close ? "date-close" : ""}`} >
                    <InputGroup className="mb-0">
                      <DatePicker
                        value={value}
                        style={{ padding: "1em" }}
                        placeholder="Select Date Range"
                        onChange={date => {
                          handleDateChange(date);
                        }}
                        range
                        numberOfMonths={2}
                        ref={calenderRefs}
                        maxDate={new Date(new Date().setHours(23, 59, 59, 999))}
                        editable={false}
                        onOpenPickNewDate={false}
                      >
                         <div className="new-action-frame layout-element p-2 m-0">
                          <Button
                           className="new-overlay-btn"
                            onClick={() => {
                              setValue([null, null])
                              calenderRefs.current.closeCalendar();
                            }}
                          >
                            {t("close")}
                          </Button>
                          <Button
                            className="new-primary-btn"
                            onClick={() => {
                              if (values.from_date || values.to_date) {
                                setFilter({
                                  ...filter,
                                  skip: 12 * (page - 1),
                                  take: 12,
                                  from_date: dateValues.from_date,
                                  to_date: dateValues.to_date,
                                })
                                calenderRefs.current.closeCalendar();
                                setClose(true)
                              }
                              else
                                getErrorNotificationMessage("Please select date")
                            }}>
                            {t("apply")}
                          </Button>
                        </div>
                      </DatePicker>
                     
                      <InputGroup.Text id="basic-addon2">
                      {close ? (
                        <>
                          <button
                            type="button"
                            className=" date-cancel"
                            onClick={() => {
                              setDateValues({
                                from_date: "",
                                to_date: "",
                              });
                              setValue([null, null]);
                              setClose(false)
                              setFilter({
                                ...filter,
                                from_date: "",
                                to_date: ""
                              });
                            }}
                          >
                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                          </button>
                        </>
                      ) : 
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"  >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                          <path d="M16 3l0 4" />
                          <path d="M8 3l0 4" />
                          <path d="M4 11l16 0" />
                          <path d="M8 15h2v2h-2z" />
                        </svg>
                        }
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </div>
                <Select
                  className='fillert-drop'
                  options={options}
                  isClearable
                  styles={customStyles}
                  isSearchable={false}
                  value={filterStatus || null}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setFilter({
                        ...filter,
                        status: selectedOption.value,

                      });
                      setFilterStatus(selectedOption);
                      navigate(filter.search_key ? `/transactions?search_key=${filter.search_key}&status=${selectedOption.value}` : `/transactions?status=${selectedOption.value}`);
                    } else {
                      const newFilter = { ...filter };
                      delete newFilter.status;
                      setFilter(newFilter);

                      navigate(filter.search_key ? `/transactions?search_key=${filter.search_key}` : "/transactions");
                      setFilterStatus(null);
                    }
                  }}
                  placeholder={
                    <div className="placeholder-flex">
                      {t("filter")}
                    </div>
                  }
                />
                <Button
                  type="submit"
                  className="new-action-btn"
                  onClick={handleExport}
                  disabled={swapData.buttonDisable || (swapData.loading || !Object.keys(swapData.data).length > 0 || !swapData.data.swap_transactions.length > 0)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-download">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                    <path d="M7 11l5 5l5 -5" />
                    <path d="M12 4l0 12" />
                  </svg>
                  <span>{t("buy_transaction.export")}</span>
                </Button>
              </div>
            </FORM>
          )}
        </Formik>
      </div>

      {swapData.loading ?
        <Skeleton count={4} height={80} />
        : Object.keys(swapData.data).length > 0 &&
          swapData.data.swap_transactions.length > 0
          ?
          <React.Fragment>
            <div className="new-table-frame">
              <Table responsive className="new-feature-table">
                <thead>
                  <tr>
                    <th>{t("buy_transaction.token")}</th>
                    {/* <th>{t("buy_transaction.requested_date")}</th> */}
                    <th>{t("buy_transaction.exchange_value")}</th>
                    {/* <th>{t("buy_transaction.amount")}</th> */}
                    <th>{t("buy_transaction.from")}</th>
                    <th>{t("buy_transaction.to")}</th>
                    <th>{t("buy_transaction.requested_date")}</th>
                    <th>{t("buy_transaction.status")}</th>
                    <th>{t("buy_transaction.action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {swapData.data.swap_transactions.map((transactions) => (
                    <tr>
                      {/* <td className='text-capitalize'>{transactions.tokens_formatted}</td> */}
                      <td className='text-capitalize'>{transactions.to_currency}</td>
                      <td className='text-capitalize'>{transactions.adjusted_exchange_rate_formatted}</td>
                      {/* <td>
                        <div className="wallet-payment-status">
                          <p className="table-bold-text"> {transactions.amount_formatted}</p>
                        </div>
                      </td> */}
                      <td>
                        <div className="wallet-payment-status">
                          <p className="table-bold-text"> {transactions.from_amount_formatted}</p>
                        </div>
                      </td>
                      <td>
                        <div className="wallet-payment-status">
                          <p className="table-bold-text"> {transactions.to_amount_formatted}</p>
                        </div>
                      </td>
                      <td>{transactions.created_at_formatted}</td>
                      <td>
                        {transactions.status == 1 ?
                          <div className="wallet-payment-status">
                            <p className='new-badges-card success'> {transactions.status_formatted}</p>
                          </div>
                          :
                          <div className="wallet-payment-status">
                            <p className='new-badges-card faile'>{transactions.status_formatted}</p>
                          </div>
                        }
                      </td>
                      <td>
                        <Button className="new-view-btn"   onClick={() => {
                              setModalShow(true)
                              setTransactionId(transactions.id)
                            }
                            }>
                          <span>View</span>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            {swapData.data.total > 12 ?
              <div className="new-billings-pagination-sec">
                <Pagination
                  page={page}
                  pageCount={Math.ceil(swapData.data.total / 12)}
                  handlePageClick={handlePageClick}
                />
              </div>
              :
              null
            }
          </React.Fragment>
          :
          <NoDataFound />
      }
      {modalShow ?
        <SwapTransactionModal
          show={modalShow}
          transactionId={transactionId}
          onHide={() => setModalShow(false)} />
        :
        null
      }
    </>
  )
};


export default SwapTransaction;