import React, { useEffect, useState } from 'react';
import { Form, InputGroup, Table, Image, Button } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import NoDataFound from '../helper/NoDataFound';
import {
  transferIbanListStart,
  moreTransferIbanListStart
} from '../../store/actions/TransferAction';
import { useTranslation } from 'react-multi-lang';
import { useSelector, useDispatch } from 'react-redux';
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../helper/ToastNotification";
import IbanTransactionModal from './IbanTransactionModal';
import Select from "react-select";
import DatePicker from "react-multi-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

const IbanTransaction = () => {
  const dispatch = useDispatch()
  const t = useTranslation("transactions");
  const [modalShow, setModalShow] = useState(false);
  const ibanTranferList = useSelector(state => state.transfer.ibanTranferList);
  const [error, setError] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [filterData, setFilterData] = useState({
    from_date: "",
    to_date: "",
    search_key: "",
    status: "",
  });

  const options = [
    { value: "0", label: t("processing") },
    { value: "1", label: t("success") },
    { value: "2 ", label: t("failed") },
  ];

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#111",
      fontSize: "0.85em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#32089F",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#32089F!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#32089F!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#111",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  useEffect(() => {
    dispatch(transferIbanListStart({
      skip: 0,
      take: 12,
    })
    );
  }, []);

  const fetchMoreTransactions = () => {
    dispatch(
      moreTransferIbanListStart({
        skip: ibanTranferList.data.iban_transfers.length,
        take: 12,
      })
    );
  };
  const handleSubmit = () => {
    dispatch(
      transferIbanListStart({
        ...filterData,
        status: filterData.status ? filterData.status.value : "",
        skip: 0,
        take: 12,
      })
    );
  };

  const handleToDateChange = (date) => {
    if (date && filterData.from_date &&
      dayjs(date).isBefore(filterData.from_date, 'day')) {
      setError(t("from_date_val_error"));
    } else {
      setError('');
    }
  };

  const handleFromDateChange = (date) => {
    if (date && filterData.to_date &&
      dayjs(date).isAfter(filterData.to_date, 'day')) {
      setError(t("from_date_val_error"));
    } else {
      setError('');
    }
  };

  return (
    <>
      <div className="wallet-full-transaction-top">
        <div className="wallet-full-transaction-sort">
          <div className="header-search">
            <div className="select-dates">
              <div className="data-picker-flex">
                <DatePicker
                  value={filterData.from_date}
                  onChange={(date) => {
                    handleFromDateChange(date)
                    setFilterData({          
                    ...filterData,
                    from_date: date
                  })}}
                  maxDate={new Date(new Date().setHours(23, 59, 59, 999))}
                  className="floating-inputs"
                  format={"YYYY-MM-DD"}
                  editable={false}
                  placeholder={t("select_from_date")}
                  onOpenPickNewDate={false}
                />

                <DatePicker
                  value={filterData.to_date}
                  onChange={(date) => {
                    handleToDateChange(date)
                    setFilterData({
                      ...filterData,
                      to_date: date
                    })
                  }}
                  maxDate={new Date(new Date().setHours(23, 59, 59, 999))}
                  className="floating-inputs"
                  format={"YYYY-MM-DD"}
                  editable={false}
                  placeholder={t("select_to_date")}
                  onOpenPickNewDate={false}
                />
              </div>
              <div>
                {error && <p
                  className="error-msg text-danger select-date">
                  {error}
                </p>}
              </div>
            </div>
            <Form className='exchange-form'>
              <InputGroup className="transaction-search">
                <Form.Control
                  name="search_key"
                  placeholder={t("search")}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      handleSubmit();
                    }
                  }}
                  value={filterData.search_key}
                  onChange={(event) => {
                    setFilterData({
                      ...filterData,
                      search_key: event.target.value,
                    });
                  }}
                />
              </InputGroup>
            </Form>
            <Select
              className='fillert-drop'
              options={options}
              isClearable
              styles={customStyles}
              value={filterData.status}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setFilterData({
                    ...filterData,
                    status: selectedOption,
                  });
                } else {
                  setFilterData({
                    ...filterData,
                    status: "",
                  });
                }
              }}
              placeholder={
                <div className="placeholder-flex">
                  {t("filter")}
                </div>
              }
            />
            <Button
              type="submit"
              disabled={error || !filterData.from_date && filterData.to_date}
              onClick={handleSubmit}
              className="search-btn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                x="0"
                y="0"
                enableBackground="new 0 0 513.749 513.749"
                version="1.1"
                viewBox="0 0 513.749 513.749"
                xmlSpace="preserve"
              >
                <path d="M504.352 459.061l-99.435-99.477c74.402-99.427 54.115-240.344-45.312-314.746S119.261-9.277 44.859 90.15-9.256 330.494 90.171 404.896c79.868 59.766 189.565 59.766 269.434 0l99.477 99.477c12.501 12.501 32.769 12.501 45.269 0 12.501-12.501 12.501-32.769 0-45.269l.001-.043zm-278.635-73.365c-88.366 0-160-71.634-160-160s71.634-160 160-160 160 71.634 160 160c-.094 88.326-71.673 159.906-160 160z"></path>
              </svg>
            </Button>
            <Button
              to="#"
              className="close-btn"
              onClick={() => {
                setError("")
                setFilterData({
                  from_date: "",
                  to_date: "",
                  search_key: "",
                  status: "",
                });
                dispatch(transferIbanListStart());
              }}
            >
              <svg color="#fff" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="15" height="15" viewBox="0 0 30 30">
                <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"></path>
              </svg>
            </Button>
          </div>
        </div>
      </div>
      {ibanTranferList.loading ?
        <Skeleton count={4} height={80} />
        :
        Object.keys(ibanTranferList.data).length > 0 &&
          ibanTranferList.data.iban_transfers.length > 0
          ?
          <InfiniteScroll
            dataLength={ibanTranferList.data.iban_transfers}
            next={fetchMoreTransactions}
            hasMore={
              ibanTranferList.data.iban_transfers.length <
              ibanTranferList.data.total
            }
            loader={<Skeleton count={4} height={100} />}
          >
            <Table>
              <thead>
                <tr>
                  <th>{t("buy_transaction.requested_date")}</th>
                  <th>{t("buy_transaction.payment_id")}</th>
                  <th>{t("buy_transaction.beneficiary_name")}</th>
                  <th>{t("buy_transaction.account_type")}</th>
                  <th>{t("buy_transaction.iban_number")}</th>
                  <th>{t("buy_transaction.country")}</th>
                  <th>{t("buy_transaction.currency_code")}</th>
                  <th>{t("buy_transaction.amount")}</th>
                  <th>{t("buy_transaction.status")}</th>
                  <th>{t("buy_transaction.action")}</th>
                </tr>
              </thead>
              <tbody>
                {ibanTranferList.data.iban_transfers.map((transactions) => (
                  <tr>
                    <td>{transactions.created_at}</td>
                    <td>
                      <div className="histroy-id-frame">
                        {transactions.payment_id ?
                          <>
                            {transactions.payment_id.slice(0, 5) + "......" + transactions.payment_id.slice(-5)}
                            <CopyToClipboard
                              text={transactions.payment_id}
                              onCopy={() =>
                                getSuccessNotificationMessage(
                                  t("swap_transaction.payment_id_copied_to_clipboard")
                                )
                              }
                            >
                              <Button className="copy-btn">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M21.155 3.272L18.871.913A3.02 3.02 0 0016.715 0H12a5.009 5.009 0 00-4.9 4H7a5.006 5.006 0 00-5 5v10a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5v-.1a5.009 5.009 0 004-4.9V5.36a2.988 2.988 0 00-.845-2.088zM13 22H7a3 3 0 01-3-3V9a3 3 0 013-3v8a5.006 5.006 0 005 5h4a3 3 0 01-3 3zm4-5h-5a3 3 0 01-3-3V5a3 3 0 013-3h4v2a2 2 0 002 2h2v8a3 3 0 01-3 3z"></path>
                                </svg>
                              </Button>
                            </CopyToClipboard>
                          </>
                          :
                          t("n_a")
                        }
                      </div>
                    </td>
                    <td>{transactions.beneficiary.account_holder_name}</td>
                    <td>{transactions.beneficiary.account_type}</td>
                    <td>{transactions.beneficiary.iban_number}</td>
                    <td className='text-uppercase'>{transactions.beneficiary.country}</td>
                    <td>{transactions.beneficiary.currency_code}</td>
                    <td>
                      <div className="wallet-payment-status">
                        <p className="paid"> {transactions.from_amount_formatted}</p>
                      </div></td>
                    <td>
                      {transactions.status == 1 ?
                        <div className="wallet-payment-status">
                          <p className='paid'> {transactions.status_formatted}</p>
                        </div>
                        :
                        <div className="wallet-payment-status">
                          <p className='processing'>{transactions.status_formatted}</p>
                        </div>
                      }
                    </td>
                    <td>
                      <Button className='modal-open-icon'>
                        <svg
                          onClick={() => {
                            setModalShow(true)
                            setTransactionId(transactions.iban_transfer_id)
                          }
                          }
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          enableBackground="new 0 0 512 512"
                          viewBox="0 0 24 24"
                        >
                          <g fill="#3c3c41">
                            <path
                              d="M23.821 11.181C22.943 9.261 19.5 3 12 3S1.057 9.261.179 11.181a1.969 1.969 0 000 1.64C1.057 14.739 4.5 21 12 21s10.943-6.261 11.821-8.181a1.968 1.968 0 000-1.638zM12 18a6 6 0 116-6 6.006 6.006 0 01-6 6z"
                              data-original="#000000"
                            ></path>
                            <circle cx="12" cy="12" r="4" data-original="#000000"></circle>
                          </g>
                        </svg>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </InfiniteScroll>
          :
          <NoDataFound />
      }
      {modalShow ?
        <IbanTransactionModal
          show={modalShow}
          transactionId={transactionId}
          onHide={() => setModalShow(false)} />
        :
        null
      }
    </>
  )
};


export default IbanTransaction;