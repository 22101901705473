import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import configuration from "react-global-configuration";
import CustomLazyLoad from "../helper/CustomLazyLoad";

const WhyChooseUs = (props) => {

  const t = useTranslation("why_chooseus");

  return (
    <>
      <div className="why-choose-us-sec sm-padding" data-aos="fade-up"
        data-aos-duration="2000" data-aos-delay="200">
        <Container>
          <div className="why-choose-us-box">
            <Row className="align-items-center justify-content-center">
              <Col xl={8} lg={8} md={7}>
              <div className="why-choose-us-header-sec">
                  <h2 data-aos="fade-up" className="text-center">{t("why_choose_us")}</h2>
                  <p data-aos="fade-up" className="text-center">{configuration.get("configData.site_name")} {t("defines_trading_excellence_para")}</p>
                </div>
                </Col>
                <Col xl={10}>
                <div className="why-choose-us-item">
                  <div className="why-choose-us-card" data-aos="fade-up">
                    <div className="why-choose-us-icon-sec">
                      <CustomLazyLoad
                        className="why-choose-us-icon"
                        src={
                          window.location.origin + "/img/landing/why-choose-us/why-choose-us-icon-1.svg"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="why-choose-us-info">
                      <h4>{t("diverse_coin_listings")}</h4>
                      <p>{t("discover_a_wealth_para")}</p>
                    </div>
                  </div>
                  <div className="why-choose-us-card" data-aos="fade-up">
                    <div className="why-choose-us-icon-sec">
                      <CustomLazyLoad
                        className="why-choose-us-icon"
                        src={
                          window.location.origin + "/img/landing/why-choose-us/why-choose-us-icon-2.svg"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="why-choose-us-info">
                      <h4>{t("security")}</h4>
                      <p>{t("your_security_is_paramount_para")} {configuration.get("configData.site_name")} {t("offers fortified_para")} </p>
                    </div>
                  </div>
                  <div className="why-choose-us-card" data-aos="fade-up">
                    <div className="why-choose-us-icon-sec">
                      <CustomLazyLoad
                        className="why-choose-us-icon"
                        src={
                          window.location.origin + "/img/landing/why-choose-us/why-choose-us-icon-3.svg"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="why-choose-us-info">
                      <h4>{t("fees_and_commission")}</h4>
                      <p>{t("benefit_from_our_transparent_para")}</p>
                    </div>
                  </div>
                  <div className="why-choose-us-card" data-aos="fade-up">
                    <div className="why-choose-us-icon-sec">
                      <CustomLazyLoad
                        className="why-choose-us-icon"
                        src={
                          window.location.origin + "/img/landing/why-choose-us/why-choose-us-icon-4.svg"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="why-choose-us-info">
                      <h4>{t("customer_support")}</h4>
                      <p>{t("always_at_your_service_para")}</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default WhyChooseUs;
