export const buyChains = [
  {
    name: "Tether",
    label: "Tether",
    value: "Tether",
    symbol: "USDT",
    apiId: "tether",
    icon: window.location.origin + "/img/chains/tether.svg"
  },
  // {
  //   name: "Binance",
  //   label: "Binance",
  //   value: "Binance",
  //   symbol: "BNB",
  //   apiId: "binancecoin",
  //   icon: window.location.origin + "/img/chains/binance.svg"
  // },
  // {
  //   name: "Binance USD",
  //   label: "Binance USD",
  //   value: "Binance USD",
  //   symbol: "BUSD",
  //   apiId: "binance-usd",
  //   icon: window.location.origin + "/img/chains/binance_usd.svg"
  // },
  // {
  //   name: "Ethereum",
  //   label: "Ethereum",
  //   value: "Etherium",
  //   symbol: "ETH",
  //   apiId: "ethereum",
  //   icon: window.location.origin + "/img/chains/ethereum.svg"
  // },
];

export const swapChains = [
  // {
  //   name: "Bitcoin",
  //   label: "Bitcoin",
  //   value: "Bitcoin",
  //   symbol: "BTC",
  //   apiId: "bitcoin",
  //   icon: window.location.origin + "/img/chains/bitcoin.svg",
  //   id: 10001,
  //   decimals: 18,
  //   rpcUrl: "https://data-seed-prebsc-1-s1.bitcoin.org:8545/",
  //   blockExplorerUrl: "https://testnet.bscscan.com/",
  //   testnet: true,
  //   isNetwork: false,
  // },
  {
    name: "Binance",
    label: "Binance",
    value: "Binance",
    symbol: "BNB",
    apiId: "binancecoin",
    icon: window.location.origin + "/img/chains/binance.svg",
    id: 97,
    decimals: 18,
    rpcUrl: "https://data-seed-prebsc-1-s3.binance.org:8545/",
    blockExplorerUrl: "https://testnet.bscscan.com/",
    testnet: true,
    isNetwork: true,
  },
  {
    name: "Ethereum",
    label: "Ethereum",
    value: "Ethereum",
    symbol: "ETH",
    apiId: "ethereum",
    icon: window.location.origin + "/img/chains/ethereum.svg",
    id: 5,
    decimals: 18,
    rpcUrl: "https://goerli.infura.io/v3/",
    blockExplorerUrl: "https://goerli.etherscan.io/",
    testnet: true,
    isNetwork: true,
  },
  {
    name: "Polygon",
    label: "Polygon",
    value: "Polygon",
    symbol: "MATIC",
    apiId: "matic-network",
    icon: window.location.origin + "/img/chains/polygon.svg",
    id: 80001,
    decimals: 18,
    rpcUrl: "https://matic-mumbai.chainstacklabs.com/",
    blockExplorerUrl: "https://mumbai.polygonscan.com/",
    testnet: true,
    isNetwork: true,
  },
  {
    name: "Avalanche",
    label: "Avalanche",
    value: "Avalanche",
    symbol: "AVAX",
    apiId: "avalanche-2",
    icon: window.location.origin + "/img/chains/avalanche.svg",
    id: 43113,
    decimals: 18,
    rpcUrl: "https://api.avax-test.network/ext/bc/C/rpc",
    blockExplorerUrl: "https://testnet.snowtrace.io/",
    testnet: true,
    isNetwork: true,
  },
]