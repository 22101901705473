import React, { useCallback, useEffect, useState, useRef } from "react";
import { Modal, Button, Form, Image, Row, Col } from "react-bootstrap";
import "../Wallet/Wallet.css";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-multi-lang";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import { profileUpdateStart } from "../../store/actions/UserAction";

const ChangeProfileImageModal = (props) => {
  const dispatch = useDispatch();
  const updateProfile = useSelector((state) => state.user.updateProfile);
  const t = useTranslation("deposit_modal");
  const formRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [files, setFiles] = useState([]);

  const onDropRejected = useCallback((rejectedFiles) => {
    if (rejectedFiles.length > 1) {
      setErrorMessage("Please upload photo");
    } else {
      const invalidFiles = rejectedFiles.filter(
        (file) => !["image/jpeg", "image/png", "image/jpg"].includes(file.type)
      );
      if (invalidFiles.length > 0) {
        setErrorMessage(t("invalid_file_format"));
      }
    }
  }, []);

  const onDropAccepted = () => {
    setErrorMessage("");
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropRejected,
    onDropAccepted,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      formRef.current.setFieldValue("picture", acceptedFiles[0]);
    },
  });

  const images = files.map((file, key) => (
    <div className="preview-added-image" key={key}>
      <Button
        variant="link"
        className="preview-cancel"
        onClick={() => {
          formRef.current.setFieldValue("file", "");
          setFiles([]);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#fff"
          viewBox="0 0 24 24"
        >
          <path d="M18 6a1 1 0 00-1.414 0L12 10.586 7.414 6A1 1 0 006 6a1 1 0 000 1.414L10.586 12 6 16.586A1 1 0 006 18a1 1 0 001.414 0L12 13.414 16.586 18A1 1 0 0018 18a1 1 0 000-1.414L13.414 12 18 7.414A1 1 0 0018 6z"></path>
        </svg>
      </Button>
      <CustomLazyLoad
        className="product-preview"
        key={file.name}
        src={file.preview}
        alt="image-preview"
      />
    </div>
  ));

  const uploadSchema = Yup.object().shape({
    picture: Yup.string().required(t("please_upload_payment_screenshot")),
  });

  const handleSubmit = (values) => {
    dispatch(
      profileUpdateStart({
        ...values,
        name: props.profile.data.name,
        email: props.profile.data.email,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !updateProfile.loading &&
      Object.keys(updateProfile.data).length > 0
    ) {
      props.onHide();
    }
    setSkipRender(false);
  }, [updateProfile]);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="new-modal-custome"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4> Change Profile Photo</h4>
          <Formik
            initialValues={{
              picture: "",
            }}
            validationSchema={uploadSchema}
            onSubmit={handleSubmit}
            innerRef={formRef}
          >
            {({ values, setFieldValue, touched, errors }) => (
              <FORM className="new-feature-form">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Upload photo
                    <span>*</span>
                  </Form.Label>
                  <div {...getRootProps()}>
                    <input name="picture" {...getInputProps()} />
                    {files.length <= 0 && (
                      <div className="add-product-upload-box">
                        <CustomLazyLoad
                          src={window.location.origin + "/img/upload.svg"}
                          className="upload-icon"
                        />
                        <p>
                          Upload your profile photo here
                          <br /> <span> {t("accepted_types_jpg_png")}</span>
                        </p>
                      </div>
                    )}
                    <div>{images}</div>
                  </div>
                  <ErrorMessage
                    component={"div"}
                    name="picture"
                    className="text-danger text-right"
                  />
                </Form.Group>
                <div className="new-action-frame layout-element">
                  <Button className="new-overlay-btn" onClick={props.onHide}>
                    {t("save_changes.cancel")}
                  </Button>
                  <Button
                    className="new-primary-btn"
                    type="submit"
                    disabled={updateProfile.buttonDisable}
                  >
                    {updateProfile.buttonDisable ? t("loading") : "Submit"}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>

          {errorMessage && (
            <p className="error-msg text-danger select-date">{errorMessage}</p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChangeProfileImageModal;
