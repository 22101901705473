import React, { useState } from "react";
import { Container, Image, Nav, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import CustomLazyLoad from "../helper/CustomLazyLoad";

const BestCryptoExchange = (props) => {

  const t = useTranslation("best_crypto");

  return (
    <>
      <div className="best-crypto-wrapped sm-padding">
        <Container>
          <Row>
          <Col md={6} lg={6} xl={5}>
              <div className="best-crypto-step-title ">
              <h2 data-aos="fade-zoom-in">{t("your_trusted")} <span>{t("partner_in_crypto_exchange")}</span></h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={6} xl={4} className="resp-marg-btm-xs">
              <div className="best-card-full-frame outer-border" data-aos="fade-right">
              <div className="best-crypto-step-card fade-color-one" >
                <div className="num-bold" ><span>01</span></div>
                <div className="best-card-head">
                <CustomLazyLoad
                    className="best-crypto-exchange-icon"
                    src={
                      window.location.origin + "/img/landing/best-crypto-exchange/best-crypto-exchange-icon-1.svg"
                    }
                    type="image/png"
                  />
                <h4>{t("choose_currency")}</h4>
                </div>
                <p>{t("select_from_a_diverse")}</p>
              </div>
              </div>
            </Col>
            <Col md={6} lg={6} xl={4} className="resp-marg-btm-xs">

            <div className="best-card-full-frame outer-border" data-aos="fade-right">
              <div className="best-crypto-step-card fade-color-two">
                <div className="num-bold"> <span> 02 </span></div>
                <div className="best-card-head">
                <CustomLazyLoad
                    className="best-crypto-exchange-icon"
                    src={
                      window.location.origin + "/img/landing/best-crypto-exchange/best-crypto-exchange-icon-2.svg"
                    }
                    type="image/png"
                  />
                <h4>{t("make_deposit")}</h4>
                </div>
                <p>{t("deposit_your_assets_para")}</p>
              </div>
              </div>
            </Col>
            <Col md={6} lg={6} xl={4}>

            <div className="best-card-full-frame outer-border" data-aos="fade-right">
              <div className="best-crypto-step-card fade-color-three">
                <div className="num-bold"><span> 03 </span></div>
                <div className="best-card-head">
                <CustomLazyLoad
                    className="best-crypto-exchange-icon"
                    src={
                      window.location.origin + "/img/landing/best-crypto-exchange/best-crypto-exchange-icon-3.svg"
                    }
                    type="image/png"
                  />
                <h4>{t("get_your_coins")}</h4>
                </div>
                <p>{t("finalize_your_exchange_para")}</p>
              </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default BestCryptoExchange;
