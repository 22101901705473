import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import SwapField from "./SwapField";
import { useTranslation } from "react-multi-lang";
import configuration from "react-global-configuration";


const NewHomeBanner = () => {

  const t = useTranslation("home_banner")

  return (
    <>
      <div className="home-banner-sec">
        <Container fluid>
          <div className="vertical-center-1 demo">
            <Row className="align-items-center">
              <Col xl={6} lg={12} md={12} className="order-change">
                <div className="banner-new-content">
                  <h5 data-aos="fade-up"  data-aos-easing="ease-in-sine">{t("revolutionizing_your_crypto_journey")}</h5>
                  <h1 data-aos="fade-up" data-aos-easing="ease-in-sine">
                    {t("empower_your_digital")} <span> {t("assets_with")} {configuration.get("configData.site_name")} </span>
                  </h1>
                  <p data-aos="fade-up" data-aos-easing="ease-in-sine">{t("welcome_to")} {configuration.get("configData.site_name")} –
                   {t("where_advanced_technology_para")} {configuration.get("configData.site_name")}
                   {t("is_your_trusted_partner_para")}
                  </p>
                  <div className="banner-new-btn-sec">
                    <Link to="/buy" className="new-default-secondary-btn" data-aos="fade-up" data-aos-easing="ease-in-sine">
                      {t("lets_go")}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 23 22"
                      >
                        <rect
                          width="22.496"
                          height="21.492"
                          fill="#E89900"
                          rx="10.746"
                          transform="matrix(1 0 0 -1 0 21.492)"
                        ></rect>
                        <path
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4.701 10.746h12.951m-4.55 4.701l4.692-4.701-4.693-4.701"
                        ></path>
                      </svg>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col xl={6} lg={12} md={12}>
                <SwapField />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default NewHomeBanner;
