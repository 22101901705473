import React from "react";
import NewFooterIndex from "../NewLanding/NewFooterIndex";
import NewLandingHeader from "../NewLanding/NewLandingHeader";

const LandingLayout = (props) => {

  return (
    <>
      <div className="main-wrapper">
        <NewLandingHeader />
        {props.children}
      </div>
      <NewFooterIndex/>
    </>
  );
};

export default LandingLayout;
