import React, { useEffect, useState } from "react";
import {
  Image,
  Tab,
  Nav,
  Row,
  Container,
  Col,
  Accordion,
} from "react-bootstrap";
import { fetchFaqsListStart } from '../../store/actions/PageAction';
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";

const FAQIndex = (props) => {

  const [type, setType] = useState('buy');
  const t = useTranslation("landing_page");

  useEffect(() => {
    props.dispatch(fetchFaqsListStart({
      type: 'buy'
    }));
  }, []);

  const handleFaqType = (type) => {
    setType(type)
    props.dispatch(fetchFaqsListStart({
      type: type
    }));
  }

  return (
    <>
      {props.faqsList.loading ? (
        <div className="faq-sec sm-padding">
          <div className="text-center mb-5">
            <Skeleton height={30} width={400} />
          </div>
          <Row>
            <Col md={6} className="text-center">
              <Skeleton height={40} width={400} />
              <Skeleton height={40} width={400} />
              <Skeleton height={40} width={400} />
              <Skeleton height={40} width={400} />
              <Skeleton height={40} width={400} />
            </Col>
            <Col md={6}>
              <Skeleton height={400} width={700} />
            </Col>
          </Row>
        </div>
      )
        : props.faqsList.data.faqs && props.faqsList.data.faqs.length > 0 ?
          <div className="faq-sec sm-padding">
            <Container>
              <Row className="justify-content-md-center">
                <Col md={8} lg={9} className="text-center">
                  <div className="section-title">
                    <h2>
                      <div className="section-title-info">
                        {t("frequently")}
                        <span> {t("asked_questions")}</span>
                      </div>
                    </h2>
                  </div>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={6} className="resp-marg-btm-xs">
                  <Tab.Container id="left-tabs-example" defaultActiveKey={type}>
                    <Row>
                      <Col sm={12}>
                        <Nav variant="pills">
                          <Nav.Item>
                            <Nav.Link eventKey="buy" onClick={() => handleFaqType('buy')}>{t("buy")}</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="swap" onClick={() => handleFaqType('swap')}>{t("swap")}</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col sm={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey="buy">
                            <Accordion defaultActiveKey="0">
                              {props.faqsList.data.faqs.map((list, index) => (
                                <Accordion.Item eventKey={index}>
                                  <Accordion.Header>
                                    {list.title}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {list.description}
                                  </Accordion.Body>
                                </Accordion.Item>
                              ))}
                            </Accordion>
                          </Tab.Pane>
                          <Tab.Pane eventKey="swap">
                            <Accordion defaultActiveKey="0">
                              {props.faqsList.data.faqs.map((list, index) => (
                                <Accordion.Item eventKey={index}>
                                  <Accordion.Header>
                                    {list.title}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {list.description}
                                  </Accordion.Body>
                                </Accordion.Item>
                              ))}
                            </Accordion>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </Col>
                <Col md={6}>
                  <div className="faq-img-sec">
                    <Image
                      src={window.location.origin + "/img/faq-img.png"}
                      className="faq-img"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          : null
      }
    </>
  );
};

const mapStateToPros = (state) => ({
  faqsList: state.page.faqsList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(FAQIndex);
