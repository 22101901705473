import React, { useState, useEffect } from 'react';
import { Container, Tab, Tabs, Button, Nav, Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';
import BuyTransaction from './BuyTransaction';
import SellTransaction from './SellTransaction';
import DepositTransaction from './DepositTransaction';
import SwapTransaction from './SwapTransaction';
import IbanTransaction from './IbanTransaction';
import { useSearchParams } from 'react-router-dom';

const TransactionIndex = () => {
  const t = useTranslation("transactions");
  const [searchParams, setSearchParams] = useSearchParams("");
  const [activeTab, setActiveTab] = useState("buy");
  const [skipRender, setSkipRender] = useState(true);
  const sendIbanTransfers = useSelector(state => state.transfer.sendIbanTransfers);

  useEffect(() => {
    if (!skipRender && !sendIbanTransfers.loading &&
      Object.keys(sendIbanTransfers.data).length > 0) {
      setActiveTab("iban")
    }
    setSkipRender(false);
  }, [sendIbanTransfers]);



  return (
    <>
      <div className="wallet-wrapped">
        <div className="wallet-top-frame">
        </div>
        <div className="wallet-full-transaction-frame">
          <Container>
            <div className="wallet-full-transaction-table">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col sm={12}>
                    <Nav onClick={()=>{
                    setSearchParams({});
                    }}
                     variant="pills">
                      <Nav.Item>
                        <Nav.Link eventKey="first"
                          onClick={() => setActiveTab("buy")}
                        >{t("buy_transaction.header")}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second"
                          onClick={() => setActiveTab("sell")}
                        >{t("sell_transaction.header")}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third"
                          onClick={() => setActiveTab("deposit")}
                        >{t("deposit_transaction.header")}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fourth"
                          onClick={() => setActiveTab("swap")}
                        >{t("swap_transaction.header")}</Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item>
                        <Nav.Link eventKey="fifth"
                          onClick={() => setActiveTab("iban")}
                        >{t("iban_transaction.header")}</Nav.Link>
                      </Nav.Item> */}
                    </Nav>
                  </Col>
                  <Col sm={12}>
                    {activeTab == "buy" &&
                      <div className="payment-tabs-content">
                        <BuyTransaction
                          activeTab={activeTab}
                        />
                      </div>
                    }
                    {activeTab == "sell" &&
                      <div className="payment-tabs-content">
                        <SellTransaction
                          activeTab={activeTab}
                        />
                      </div>
                    }
                    {activeTab === "deposit" && (
                      <div className="payment-tabs-content">
                        <DepositTransaction
                          activeTab={activeTab}
                        />
                      </div>
                    )}
                    {activeTab === "swap" && (
                      <div className="payment-tabs-content">
                        <SwapTransaction
                          activeTab={activeTab}
                        />
                      </div>
                    )}
                    {/* {activeTab === "iban" && (
                      <div className="payment-tabs-content">
                        <IbanTransaction
                          activeTab={activeTab}
                        />
                      </div>
                    )} */}
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </Container>
        </div >
      </div >
    </>
  )
};

export default TransactionIndex;