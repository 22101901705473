import React, { useState, useEffect } from "react";
import { Image, Form, Container, Row, Col, Tab, Nav, Button } from "react-bootstrap";
import "./Exchange.css";
import Select, { components } from "react-select";
import "./eth.svg";
import "./binance.svg";
import "./solana.svg";
import "./tether.svg";
import { Link } from "react-router-dom";
import CustomSelect from "../Common/CustomSelect";
import { buyChains } from "../Common/Chains";
import { useTranslation } from "react-multi-lang";

const BuyCrypto = (props) => {
const t = useTranslation("buy_crypto")
  const options = [
    { value: "Etherium", label: "Etherium", icon: "eth.svg" },
    { value: "Binance", label: "Binance", icon: "binance.svg" },
    { value: "Solana", label: "Solana", icon: "solana.svg" },
    { value: "Tether", label: "Tether", icon: "tether.svg" },
  ];

  const { Option } = components;
  const IconOption = props => (
    <Option {...props} className="select-option">
      <img
        src={require('./' + props.data.icon)}
        style={{ width: 30, height: 30 }}
        alt={props.data.label}
      />
      {props.data.label}
    </Option>
  );

  const customStyles = {
    ///.....
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999, left: '0px' }),
    menuList: provided => ({
      ...provided,
      padding: 0,
      minWidth: 100,
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "8px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      }
    }),
    container: provided => ({ ...provided, width: '100%' }),
    control: provided =>
    ({
      ...provided,
      backgroundColor: 'transparent',
      borderColor: 'transparent', border: 'none!important',
      boxShadow: 'none',
      paddingLeft: '0'
    }),
    valueContainer: provided => ({
      ...provided,
      paddingLeft: '0'
    }),
    singleValue: provided => ({ ...provided, color: '#111111' }),
    indicatorContainer: provided => ({ ...provided, color: '#fff!important' }),
    indicatorContainer: provided => ({ ...provided, fill: '#fff!important' }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      "svg": {
        fill: "#111"
      }
    }),
    ///.....
  }

  return (
    <>
      <div className="buy-crypto-sec">
        <Row className="justify-content-md-center">
          <Col md={5}>
            <div className="exchange-box">
              <Tab.Container id="left-tabs-example" defaultActiveKey="buy-crypto">
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="buy-crypto">{t("buy")}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="swap-crypto">{t("swap")}</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Tab.Container>
              <div className="exchange-conversion-box">
                <div className="exchange-conversion-card">
                  <div className="border-theme-right">
                    <div className="exchange-conversion-left-sec">
                      <div className="exchange-conversion-label">
                        <Image
                          src={window.location.origin + "/img/exchange/bitcoin-icon.svg"}
                          className="exchange-conversion-icon"
                        />
                      {t("btc")}
                      </div>
                      <div className="exchange-select-sec">
                        <Select
                          defaultValue={options[0]}
                          options={options}
                          components={{ Option: IconOption }}
                          menuPortalTarget={document.body}
                          menuPosition={'fixed'}
                          styles={customStyles}
                          isisSearchable={false}
                        // menuIsOpen={true} 
                        />
                        <CustomSelect
                          options={buyChains}
                        // value={buyChains[0]}
                        // onChange={(value) => { console.log(value) }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="exchange-conversion-right-sec">
                    <h5>{t("send")}</h5>
                    <h3>0.1</h3>
                  </div>
                </div>
                <div className="swap-transfer-icon-sec">
                  <span className="or-line">
                    <span>
                      <Image
                        src={window.location.origin + "/img/exchange/swap-transfer-icon.svg"}
                        className="swap-icon"
                      />
                    </span>
                  </span>
                </div>
                <div className="exchange-conversion-card">
                  <div className="border-theme-right">
                    <div className="exchange-conversion-left-sec">
                      <div className="exchange-conversion-label">
                        <Image
                          src={window.location.origin + "/img/exchange/dollar-icon.svg"}
                          className="exchange-conversion-icon"
                        />
                       {t("usd")}
                      </div>
                      <div className="exchange-select-sec">
                        <h3>{t("dollars")}</h3>
                      </div>
                    </div>
                  </div>
                  <div className="exchange-conversion-right-sec">
                    <h5 className="text-para">{t("you_get")}</h5>
                    <h3>1.625689</h3>
                  </div>
                </div>
                <div className="exchange-btn-sec">
                  <Button className="default-btn" onClick={() => props.setStep(2)}>
                  {t("continue")}
                  </Button>
                  <p> {t("by_continuing_you_agree_to_our")} <Link to="#"> {t("cookie")}</Link></p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BuyCrypto;
