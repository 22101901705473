import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  Image,
  Form,
  InputGroup,
  Button,
  Row,
  Container,
  FormControl,
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import { registerStart } from "../../store/actions/UserAction";
import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { useTranslation } from "react-multi-lang";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import "react-date-picker/dist/DatePicker.css";
import { countryData } from "../helper/CountryList";
import OtpVerification from "./OtpVerification";
import Authenticator from "./Authenticator";
import { ButtonLoader } from "../helper/Loader";

const RegisterIndex = (props) => {
  const t = useTranslation("register");
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [skipRender, setSkipRender] = useState(true);
  const [loginPasswordVisible, setLoginPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [selectedTitle, setSelectedTile] = useState(null);
  const [selectedNationality, setSelectedNationality] = useState(null);
  const emailVerify = useSelector((state) => state.user.emailVerify);
  const register = useSelector((state) => state.user.register);
  const [showOTPVerifyModal, setShowOTPVerifyModal] = useState(false);
  const [authenticatorModal, setAuthenticatorModal] = useState(false);

  const options = Object.entries(countryData)
    .filter(([code, name]) => code !== "IN" && code !== "IO")
    .map(([code, name]) => ({
      label: name,
      value: code,
    }));
  const titleOptions = [
    { label: "Mr", value: "Mr" },
    { label: "Mrs", value: "Mrs" },
    {
      label: "Ms",
      value: "Ms",
    },
  ];

  useEffect(() => {
    setStep(1);
  }, []);

  const closeOtpVerifyModal = () => {
    setShowOTPVerifyModal(false);
  };

  useEffect(() => {
    if (
      !skipRender &&
      !register.loading &&
      Object.keys(props.register.data).length > 0
    ) {
      setShowOTPVerifyModal({
        ...register.data,
        type: "email",
      });
    }
    setSkipRender(false);
  }, [register]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t("first_name.required")),
    first_name: Yup.string().required(t("first_name.required")),
    last_name: Yup.string().required(t("last_name.required")),
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
    nationality: Yup.string().required(t("first_name.required")),
    password: Yup.string()
      .required(t("password.required"))
      .matches(/^\S+$/, t("password.space_invalid"))
      // .matches(/[A-Z]/, t("password.invalid"))
      // .matches(/[a-z]/, "One smaller case required")
      // .matches(/[0-9]/, t("password.number_invalid"))
      // .matches(/[\W_]/, t("password.special_character_invalid"))
      .test(
        "complexity",
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
        (value) => {
          if (!value) return false; // Skip validation if value is not provided

          const hasUppercase = /[A-Z]/.test(value);
          const hasLowercase = /[a-z]/.test(value);
          const hasNumber = /[0-9]/.test(value);
          const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);

          return hasUppercase && hasLowercase && hasNumber && hasSpecialChar;
        }
      )
      .min(6, t("password.min_length_invalid")),
    password_confirmation: Yup.string()
      .required(t("password_confirmation.required"))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t("password_confirmation.passwords_do_not_match")
        ),
      }),
    mobile: Yup.string()
      .test("valid-mobile", t("mobile_number.invalid"), (value) => {
        if (!value) {
          return false;
        }
        return isValidPhoneNumber(value);
      })
      .required(t("mobile_number.required"))
      .matches(/^(?=.*[0-9])/, t("mobile_number.space_values_not_valid")),
    // website_url: Yup.string()
    //   .required(t("website_url.required"))
    //   .matches(
    //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //     t("website_url.invalid_url")
    //   ),
  });

  const validateMobileNumber = (value) => {
    if (value) {
      if (isPossiblePhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile_number.invalid"));
        return false;
      } else if (isValidPhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile_number.invalid"));
        return false;
      } else {
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", t("mobile_number.required"));
      return false;
    }
  };

  const HandleRegister = (values) => {
    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");

      props.dispatch(
        registerStart({
          ...values,
          mobile: mobile,
          country_code: countryCode,
        })
      );
    }
  };

  useEffect(() => {
    if (
      (localStorage.getItem("userId") && localStorage.getItem("token")) ||
      (sessionStorage.getItem("userId") && sessionStorage.getItem("token"))
    ) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (
      !skipRender &&
      !emailVerify.loading &&
      Object.keys(emailVerify.data).length > 0
    ) {
      if (emailVerify.data.google2fa_secret) {
        setShowOTPVerifyModal(false);
        setAuthenticatorModal({
          email: formRef.current?.values.email,
          password: formRef.current?.values.password,
        });
      }
    }
    setSkipRender(false);
  }, [emailVerify]);

  return (
    <>
      <div className="login-sec">
        <div className="login-box">
          <Container>
            <Row className="p-0 justify-content-md-center">
              <Col lg={8} className="p-0">
                <div className="signup-form">
                  <Formik
                    initialValues={{
                      title: "",
                      first_name: "",
                      last_name: "",
                      middle_name: "",
                      email: "",
                      mobile: "",
                      password: "",
                      password_confirmation: "",
                      // website_url: "",
                      country_code: "",
                      nationality: "",
                      mobile: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={HandleRegister}
                    innerRef={formRef}
                  >
                    {({ errors, touched, values, setFieldValue }) => (
                      <FORM className="exchange-form">
                        {step == 1 && (
                          <>
                            <h2 className="mt-3">{t("heading")}</h2>
                            <Row>
                              <Col sm={12} lg={12} xl={2}>
                                <Form.Group
                                  delivery_country
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("title")}
                                    <span>*</span>
                                  </Form.Label>
                                  <Select
                                    value={selectedTitle}
                                    options={titleOptions}
                                    onChange={(data) => {
                                      setSelectedTile(data);
                                      setFieldValue("title", data.value);
                                    }}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="title"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={12} xl={5}>
                                <Form.Group
                                  delivery_country
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("first_name.label")} <span>*</span>
                                  </Form.Label>
                                  <Field
                                    type="text"
                                    className="form-control"
                                    name="first_name"
                                    onKeyDown={(e) => {
                                      if (
                                        !/[A-Z]|[a-z]|Backspace|Spacebar|Tab/.test(
                                          e.key
                                        ) &&
                                        !(
                                          e.ctrlKey &&
                                          (e.key === "v" ||
                                            e.key === "c" ||
                                            e.key === "x" ||
                                            e.key === "a")
                                        )
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    placeholder={t("first_name.placeholder")}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="first_name"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={12} xl={5}>
                                <Form.Group
                                  delivery_country
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("middle_name.label")} <span></span>
                                  </Form.Label>
                                  <Field
                                    type="text"
                                    className="form-control"
                                    name="middle_name"
                                    onKeyDown={(e) => {
                                      if (
                                        !/[A-Z]|[a-z]|[ \t]|Backspace|Spacebar|Tab/.test(
                                          e.key
                                        ) &&
                                        !(
                                          e.ctrlKey &&
                                          (e.key === "v" ||
                                            e.key === "c" ||
                                            e.key === "x" ||
                                            e.key === "a")
                                        )
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    placeholder={t("middle_name.placeholder")}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="middle_name"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={12} lg={12} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("last_name.label")} <span>*</span>
                                  </Form.Label>
                                  <Field
                                    type="text"
                                    onKeyDown={(e) => {
                                      if (
                                        !/[A-Z]|[a-z]|Backspace|Spacebar|Tab/.test(
                                          e.key
                                        ) &&
                                        !(
                                          e.ctrlKey &&
                                          (e.key === "v" ||
                                            e.key === "c" ||
                                            e.key === "x" ||
                                            e.key === "a")
                                        )
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    className="form-control"
                                    name="last_name"
                                    placeholder={t("last_name.placeholder")}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="last_name"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={12} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("email.label")} <span>*</span>
                                  </Form.Label>
                                  <Field
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    placeholder={t("email.placeholder")}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="email"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              {/* <Col sm={12} lg={12} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("website_url.label")} <span>*</span>
                                  </Form.Label>
                                  <Field
                                    type="text"
                                    className="form-control"
                                    name="website_url"
                                    placeholder={t("website_url.placeholder")}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="website_url"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col> */}
                              <Col sm={12} lg={12} xl={6}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>
                                    {t("mobile_number.label")} <span>*</span>
                                  </Form.Label>
                                  <PhoneInput
                                    defaultCountry="IN"
                                    placeholder={t("mobile_number.placeholder")}
                                    value={values.mobile}
                                    onChange={(value) =>
                                      setFieldValue("mobile", value)
                                    }
                                    international
                                    countryCallingCodeEditable={false}
                                    className={`${
                                      touched.mobile && errors.mobile
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="mobile"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={12} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("nationality")} <span>*</span>
                                  </Form.Label>
                                  <Select
                                    value={selectedNationality}
                                    options={options}
                                    onChange={(data) => {
                                      setSelectedNationality(data);
                                      setFieldValue("nationality", data.value);
                                    }}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="nationality"
                                    className="text-danger text-right"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={12} lg={12} xl={6}>
                                <Form.Group controlId="formBasicEmail">
                                  <Form.Label>
                                    {t("password.label")} <span>*</span>
                                  </Form.Label>
                                  <InputGroup className="mb-0">
                                    <Field
                                      type={
                                        loginPasswordVisible
                                          ? "text"
                                          : "password"
                                      }
                                      name="password"
                                      placeholder={t("password.placeholder")}
                                      className="form-control"
                                    />
                                    <InputGroup.Text
                                      id="basic-addon2"
                                      onClick={() =>
                                        setLoginPasswordVisible(
                                          !loginPasswordVisible
                                        )
                                      }
                                    >
                                      {loginPasswordVisible ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                        >
                                          <g clipPath="url(#clip0_1_56)">
                                            <path
                                              fill="#3C3C41"
                                              d="M12 6.5c2.76 0 5 2.24 5 5 0 .51-.1 1-.24 1.46l3.06 3.06c1.39-1.23 2.49-2.77 3.18-4.53C21.27 7.11 17 4 12 4c-1.27 0-2.49.2-3.64.57l2.17 2.17c.47-.14.96-.24 1.47-.24zM2.71 3.16a.996.996 0 000 1.41l1.97 1.97A11.892 11.892 0 001 11.5C2.73 15.89 7 19 12 19c1.52 0 2.97-.3 4.31-.82l2.72 2.72a.996.996 0 101.41-1.41L4.13 3.16c-.39-.39-1.03-.39-1.42 0zM12 16.5c-2.76 0-5-2.24-5-5 0-.77.18-1.5.49-2.14l1.57 1.57c-.03.18-.06.37-.06.57 0 1.66 1.34 3 3 3 .2 0 .38-.03.57-.07L14.14 16c-.65.32-1.37.5-2.14.5zm2.97-5.33a2.97 2.97 0 00-2.64-2.64l2.64 2.64z"
                                            ></path>
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_1_56">
                                              <path
                                                fill="#fff"
                                                d="M0 0H24V24H0z"
                                              ></path>
                                            </clipPath>
                                          </defs>
                                        </svg>
                                      ) : (
                                        <svg
                                          width="24"
                                          height="24"
                                          viewBox="0 0 19 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M9.5 1.5C5.86985 1.5 2.57784 3.77968 0.148665 7.4825C-0.0495551 7.78586 -0.0495551 8.20968 0.148665 8.51304C2.57784 12.2203 5.86985 14.5 9.5 14.5C13.1302 14.5 16.4222 12.2203 18.8513 8.5175C19.0496 8.21414 19.0496 7.79032 18.8513 7.48696C16.4222 3.77968 13.1302 1.5 9.5 1.5ZM9.76041 12.5772C7.35067 12.7512 5.36069 10.4715 5.51227 7.7011C5.63665 5.41695 7.24962 3.56555 9.23959 3.42279C11.6493 3.2488 13.6393 5.52848 13.4877 8.2989C13.3595 10.5786 11.7465 12.43 9.76041 12.5772ZM9.63992 10.4626C8.34177 10.5563 7.26905 9.32944 7.35456 7.8394C7.42063 6.6081 8.29124 5.61325 9.36397 5.53294C10.6621 5.43926 11.7348 6.6661 11.6493 8.15614C11.5794 9.3919 10.7088 10.3868 9.63992 10.4626Z"
                                            fill="#3C3C41"
                                          />
                                        </svg>
                                      )}
                                    </InputGroup.Text>
                                  </InputGroup>
                                  <ErrorMessage
                                    component={"div"}
                                    name="password"
                                    className="text-danger text-right"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={12} xl={6}>
                                <Form.Group controlId="formBasicEmail">
                                  <Form.Label>
                                    {t("password_confirmation.label")}{" "}
                                    <span>*</span>
                                  </Form.Label>
                                  <InputGroup className="mb-0">
                                    <Field
                                      type={
                                        confirmPasswordVisible
                                          ? "text"
                                          : "password"
                                      }
                                      name="password_confirmation"
                                      placeholder={t(
                                        "password_confirmation.placeholder"
                                      )}
                                      className="form-control"
                                    />
                                    <InputGroup.Text
                                      id="basic-addon2"
                                      onClick={() =>
                                        setConfirmPasswordVisible(
                                          !confirmPasswordVisible
                                        )
                                      }
                                    >
                                      {confirmPasswordVisible ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                        >
                                          <g clipPath="url(#clip0_1_56)">
                                            <path
                                              fill="#3C3C41"
                                              d="M12 6.5c2.76 0 5 2.24 5 5 0 .51-.1 1-.24 1.46l3.06 3.06c1.39-1.23 2.49-2.77 3.18-4.53C21.27 7.11 17 4 12 4c-1.27 0-2.49.2-3.64.57l2.17 2.17c.47-.14.96-.24 1.47-.24zM2.71 3.16a.996.996 0 000 1.41l1.97 1.97A11.892 11.892 0 001 11.5C2.73 15.89 7 19 12 19c1.52 0 2.97-.3 4.31-.82l2.72 2.72a.996.996 0 101.41-1.41L4.13 3.16c-.39-.39-1.03-.39-1.42 0zM12 16.5c-2.76 0-5-2.24-5-5 0-.77.18-1.5.49-2.14l1.57 1.57c-.03.18-.06.37-.06.57 0 1.66 1.34 3 3 3 .2 0 .38-.03.57-.07L14.14 16c-.65.32-1.37.5-2.14.5zm2.97-5.33a2.97 2.97 0 00-2.64-2.64l2.64 2.64z"
                                            ></path>
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_1_56">
                                              <path
                                                fill="#fff"
                                                d="M0 0H24V24H0z"
                                              ></path>
                                            </clipPath>
                                          </defs>
                                        </svg>
                                      ) : (
                                        <svg
                                          width="24"
                                          height="24"
                                          viewBox="0 0 19 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M9.5 1.5C5.86985 1.5 2.57784 3.77968 0.148665 7.4825C-0.0495551 7.78586 -0.0495551 8.20968 0.148665 8.51304C2.57784 12.2203 5.86985 14.5 9.5 14.5C13.1302 14.5 16.4222 12.2203 18.8513 8.5175C19.0496 8.21414 19.0496 7.79032 18.8513 7.48696C16.4222 3.77968 13.1302 1.5 9.5 1.5ZM9.76041 12.5772C7.35067 12.7512 5.36069 10.4715 5.51227 7.7011C5.63665 5.41695 7.24962 3.56555 9.23959 3.42279C11.6493 3.2488 13.6393 5.52848 13.4877 8.2989C13.3595 10.5786 11.7465 12.43 9.76041 12.5772ZM9.63992 10.4626C8.34177 10.5563 7.26905 9.32944 7.35456 7.8394C7.42063 6.6081 8.29124 5.61325 9.36397 5.53294C10.6621 5.43926 11.7348 6.6661 11.6493 8.15614C11.5794 9.3919 10.7088 10.3868 9.63992 10.4626Z"
                                            fill="#3C3C41"
                                          />
                                        </svg>
                                      )}
                                    </InputGroup.Text>
                                  </InputGroup>
                                  <ErrorMessage
                                    component={"div"}
                                    name="password_confirmation"
                                    className="text-danger text-right"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </>
                        )}
                        <div className="auth-step-btn-sec">
                          <Row className="align-items-center">
                            <Col lg={9}>
                              <div className="auth-login-link">
                                <span>{t("already_have_an_account")}? </span>
                                <Link to="/login">{t("login")}</Link>
                              </div>
                            </Col>
                            <Col lg={3}>
                              <Button
                                className="default-btn"
                                type="submit"
                                disabled={register.buttonDisable}
                              >
                                {register.buttonDisable ? (
                                  <ButtonLoader />
                                ) : (
                                  t("login_btn.text")
                                )}
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {showOTPVerifyModal ? (
        <OtpVerification
          showOtpVerify={showOTPVerifyModal}
          closeOtpVerifyModal={closeOtpVerifyModal}
          type="email"
          password={formRef.current?.values.password}
        />
      ) : null}
      {authenticatorModal ? (
        <Authenticator
          authenticatorModal={authenticatorModal}
          authentication={emailVerify}
          login={0}
          closeAuthenticatorModal={() => setAuthenticatorModal(false)}
        />
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  register: state.user.register,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(RegisterIndex);
