export const countriesData = [
  { code: "AND", name: "Andora", currency: "EUR", type: "P2P" },
  { code: "AUT", name: "Austria", currency: "EUR", type: "P2P" },
  { code: "BEL", name: "Belgium", currency: "EUR", type: "P2P" },
  { code: "BGD", name: "Bangladesh", currency: "BDT", type: "P2P" },
  { code: "BGR", name: "Bulgeria", currency: "EUR", type: "P2P" },
  { code: "CHE", name: "Swizerland", currency: "EUR", type: "P2P" },
  { code: "CYP", name: "Cyprus", currency: "EUR", type: "P2P" },
  { code: "CZE", name: "Czech Republic", currency: "EUR", type: "P2P" },
  { code: "DEU", name: "Germany", currency: "EUR", type: "P2P" },
  { code: "DNK", name: "Denmark", currency: "EUR", type: "P2P" },
  { code: "ESP", name: "Spain", currency: "EUR", type: "P2P" },
  { code: "EST", name: "Estonia", currency: "EUR", type: "P2P" },
  { code: "FIN", name: "Finland", currency: "EUR", type: "P2P" },
  { code: "FRA", name: "France", currency: "EUR", type: "P2P" },
  { code: "GBR", name: "United Kingdom", currency: "EUR", type: "P2P" },
  { code: "GBR", name: "United Kingdom", currency: "GBP", type: "P2P" },
  { code: "GRC", name: "Greece", currency: "EUR", type: "P2P" },
  { code: "HRV", name: "Crotia", currency: "EUR", type: "P2P" },
  { code: "HUN", name: "Hungary", currency: "EUR", type: "P2P" },
  { code: "IDN", name: "Indenosia", currency: "IDR", type: "P2P" },
  { code: "IND", name: "India", currency: "INR", type: "P2P" },
  { code: "IRL", name: "Ireland", currency: "EUR", type: "P2P" },
  { code: "ISL", name: "Iceland", currency: "EUR", type: "P2P" },
  { code: "ITA", name: "Italy", currency: "EUR", type: "P2P" },
  { code: "LTE", name: "Liechtenstein", currency: "EUR", type: "P2P" },
  { code: "LKA", name: "Sri Lanka", currency: "LKR", type: "P2P" },
  { code: "LTU", name: "Lithuania", currency: "EUR", type: "P2P" },
  { code: "LUX", name: "Luxemberg", currency: "EUR", type: "P2P" },
  { code: "MCO", name: "Monaco", currency: "EUR", type: "P2P" },
  { code: "MLT", name: "Malta", currency: "EUR", type: "P2P" },
  { code: "MYS", name: "Malasiya", currency: "MYR", type: "P2P" },
  { code: "NLD", name: "Netherlands", currency: "EUR", type: "P2P" },
  { code: "NOR", name: "Norway", currency: "EUR", type: "P2P" },
  { code: "PHL", name: "Phillipines", currency: "PHP", type: "P2P" },
  { code: "POL", name: "Poland", currency: "EUR", type: "P2P" },
  { code: "PRT", name: "Portugal", currency: "EUR", type: "P2P" },
  { code: "ROU", name: "Romania", currency: "EUR", type: "P2P" },
  { code: "SMR", name: "San Marino", currency: "EUR", type: "P2P" },
  { code: "SVK", name: "Slovakia", currency: "EUR", type: "P2P" },
  { code: "SVN", name: "Slovenia", currency: "EUR", type: "P2P" },
  { code: "SWE", name: "Sweeden", currency: "EUR", type: "P2P" },
  { code: "THA", name: "Thailand", currency: "THB", type: "P2P" },
  { code: "VAT", name: "Vatican City State", currency: "EUR", type: "P2P" },
  { code: "VNM", name: "Vietnam", currency: "VND", type: "P2P" },
  { code: "HKG", name: "Hongkong", currency: "HKD", type: "p2p" },
];
