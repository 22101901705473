import React, { useEffect, useState } from 'react';
import { Modal, Button, Table } from "react-bootstrap";
import { sellTokensViewStart } from '../../store/actions/BuyAction';
import Skeleton from 'react-loading-skeleton';
import CopyToClipboard from 'react-copy-to-clipboard';
import './transaction.css';
import { useTranslation } from 'react-multi-lang';
import { useSelector, useDispatch } from 'react-redux';
import { getSuccessNotificationMessage } from '../helper/ToastNotification';

const SellTransactionModal = (props) => {

  const { transactionId } = props;
  const dispatch = useDispatch();
  const t = useTranslation("transactions");
  const sellTokensView = useSelector(state => state.buy.sellTokensView);

  useEffect(() => {
    dispatch(sellTokensViewStart({
      sell_transaction_id: transactionId
    }));
  }, []);

  return (
    <>
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="new-modal-custome"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      {sellTokensView.loading ?
        <Modal.Body>
          <div className="feature-modal-content">
            <Skeleton count={6} height={60} width={"100%"} />
          </div>
        </Modal.Body>
        :
        Object.keys(sellTokensView.data).length > 0 ?
          <Modal.Body>
            <div className="feature-modal-content">
              <div className="feature-content-header">
                <div className="feature-title-card">
                  <h2> {t("sell_transaction_details")}</h2>
                </div>
                <div className="feature-title-card-info">
                  <h3> {sellTokensView.data.sell_transaction.amount_formatted}</h3>
                  <div className="new-badges-frame">
                    {sellTokensView.data.sell_transaction.status == 1 ?
                      <div className="new-badges-card success">
                        {sellTokensView.data.sell_transaction.status_formatted}
                      </div>
                      :
                      <div className="new-badges-card pending">
                        {sellTokensView.data.sell_transaction.status_formatted}
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div className="feature-content-main">
                <div className="feature-content-grid grid-three-layout">
                  <div className="feature-content-card">
                    <p>{t("buy_transaction.transaction_date")}</p>
                    <h5>{sellTokensView.data.sell_transaction.created_at_formatted}</h5>
                  </div>
                  <div className="feature-content-card">
                    <p>{t("buy_transaction.exchange_value")}</p>
                    <h5>{sellTokensView.data.sell_transaction.adjusted_exchange_rate_formatted}</h5>
                  </div>
                  {/* <div className="feature-content-card">
                    <p>{t("buy_transaction.token")}</p>
                    <h5>{sellTokensView.data.sell_transaction.tokens_formatted}</h5>
                  </div> */}
                  <div className="feature-content-card">
                      <p>{t("buy_transaction.from")}</p>
                      <h5>{sellTokensView.data.sell_transaction.from_amount_formatted}</h5>
                    </div>
                    <div className="feature-content-card">
                      <p>{t("buy_transaction.to")}</p>
                      <h5>{sellTokensView.data.sell_transaction.to_amount_formatted}</h5>
                    </div>
                </div>
                <div className="feature-hr"></div>
                <div className="feature-content-grid grid-one-layout">
                  <div className="feature-content-card">
                    <p>{t("buy_transaction.payment_id")}</p>
                    <h5> {sellTokensView.data.sell_transaction.payment_id.slice(0, 5) + "......" + sellTokensView.data.sell_transaction.payment_id.slice(-5)}</h5>
                  </div>
                  <CopyToClipboard
                    text={sellTokensView.data.sell_transaction.payment_id}
                    onCopy={() => getSuccessNotificationMessage(t("payment_id_was_copied_to_clipboard"))}
                  >
                    <Button className="new-action-btn">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                        <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                      </svg>
                      <span>{t("copy")}</span>
                    </Button>
                  </CopyToClipboard>
                </div>
              </div>
              <div className="feature-content-footer">
                <Button className="new-action-btn" onClick={props.onHide}>
                  <span>{t("close")}</span>
                </Button>
              </div>
            </div>
          </Modal.Body>
          :
          null
      }
    </Modal>
  </>
  )
};


export default SellTransactionModal;