import React from 'react';
import Select, { components } from "react-select";

const CustomSelect = (props) => {
  const { options = [], isSearchable = false, value, onChange = () => { } } = props;

  const { Option } = components;
  const IconOption = props => (
    <Option {...props} className="select-option">
      <img
        src={props.data.icon}
        style={{ width: 30, height: 30 }}
        alt={props.data.label}
      />
      {props.data.label}
    </Option>
  );

  const customStyles = {
    ///.....
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999, left: '0px' }),
    menuList: provided => ({
      ...provided,
      padding: 0,
      minWidth: 100,
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      }
    }),
    container: provided => ({ ...provided, width: '100%' }),
    control: provided =>
    ({
      ...provided,
      backgroundColor: 'transparent',
      borderColor: 'transparent', border: 'none!important',
      boxShadow: 'none',
      paddingLeft: '0'
    }),
    valueContainer: provided => ({
      ...provided,
      paddingLeft: '0'
    }),
    singleValue: provided => ({ ...provided, color: '#111111', fontSize: "1.5em", fontWeight: "700" }),
    indicatorContainer: provided => ({ ...provided, color: '#fff!important' }),
    indicatorContainer: provided => ({ ...provided, fill: '#fff!important' }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      "svg": {
        fill: "#111"
      }
    }),
    ///.....
  }

  // const Control = ({ selectProps, children, ...props }) => (
  //   <components.Control {...props}>
  //     {props.hasValue ? selectProps.value[label] : "Select..."}
  //   </components.Control>
  // );

  return (
    <Select
      options={options}
      components={{ Option: IconOption }}
      menuPortalTarget={document.body}
      menuPosition={'fixed'}
      styles={customStyles}
      isSearchable={isSearchable}
      // menuIsOpen={true} 
      value={value}
      onChange={onChange}
    />
  );

}

export default CustomSelect;