import React, { useEffect, useState } from 'react';
import { Form, Button, Modal, InputGroup } from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import '../Wallet/Wallet.css';
import { useTranslation } from "react-multi-lang";
import { connect } from 'react-redux';
import { deleteAccountStart } from '../../store/actions/UserAction';
import useLogout from '../layouts/Header/useLogout';

const DeleteAccountModal = (props) => {

  const t = useTranslation("delete_profile_account");
  const [showPassword, setShowPassword] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const { logout } = useLogout();

  const validationSchema = Yup.object().shape({
    password: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("password.invalid"))
      .required(t("password.required"))
  });

  const handleSubmit = (values) => {
    props.dispatch(deleteAccountStart(values));
  };

  useEffect(() => {
    if (!skipRender && !props.deleteAccount.loading &&
      Object.keys(props.deleteAccount.data).length > 0) {
      props.onHide();
      logout();
    }
    setSkipRender(false);
  }, [props.deleteAccount])

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="new-modal-custome"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4> {t("heading")}</h4>
          <Formik
            initialValues={{
              password: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <FORM className="new-feature-form">
                <Form.Group className="mb-3" >
                  <Form.Label>{t("password.label")}</Form.Label>
                  <InputGroup>
                    <Field
                      name="password"
                      className="form-control"
                      type={showPassword ? "text" : "password"}
                      autoFocus={true}
                      placeholder={t("password.placeholder")}
                    />
                    <InputGroup.Text id="basic-addon3">
                      <Button className="hide-btn" onClick={() => setShowPassword(!showPassword)}>
                        {!showPassword ? <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye" fill="none" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg> :
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye-off" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" /><path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" /><path d="M3 3l18 18" /></svg>}
                      </Button>
                    </InputGroup.Text>
                  </InputGroup>
                  <ErrorMessage
                    component={"div"}
                    name="password"
                    className="errorMsg"
                  />
                </Form.Group>
                <div className="new-action-frame layout-element">
                  <Button className="new-overlay-btn"
                    onClick={props.onHide}
                    disabled={
                      props.deleteAccount.buttonDisable
                    }
                  >{t("cancel")}
                  </Button>
                  <Button className="new-primary-btn"
                    type="submit"
                    disabled={
                      props.deleteAccount.buttonDisable
                    }
                  >
                    {props.deleteAccount.buttonDisable
                      ? t("delete_btn.loading")
                      : t("delete_btn.text")
                    }
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal >
    </>
  )
};

const mapStateToPros = (state) => ({
  deleteAccount: state.user.deleteAccount,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(
  mapStateToPros,
  mapDispatchToProps
)(DeleteAccountModal);