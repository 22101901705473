import React, { useState, useMemo } from "react";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import Select from "react-select";
import PhoneInput from "react-phone-number-input";
import countryList from 'react-select-country-list';
import { useNavigate, Link } from 'react-router-dom';
import IfscCodeModal from './IFSCModal';


const CreareBusinessRecipient = () => {
  const [country, setCountry] = useState('')
  const countryOption = useMemo(() => countryList().getData(), [])
  const [phoneValue, setPhoneValue] = useState()
  const [modalShow, setModalShow] = React.useState(false);

  const changeHandler = country => {
    setCountry(country)
  }

  const navigate = useNavigate();

  const Currency = [
    { value: '1', label: 'USD' },
    { value: '2', label: 'INR' },
    { value: '3', label: 'EUR' },
    { value: '4', label: 'JPY' },
    { value: '5', label: 'Dirham' },
  ];

  const AccountType = [
    { value: '1', label: 'Current Account' },
    { value: '2', label: 'Savings Account' },
    { value: '3', label: 'Salary Account' },
    { value: '4', label: 'Fixed Deposit Account' },
  ];

  const BankCountry = [
    { value: '1', label: 'Austria' },
    { value: '2', label: 'Andorra' },
    { value: '3', label: 'Australia' },
    { value: '4', label: 'Bangladesh' },
    { value: '5', label: 'China' },
    { value: '6', label: 'Cyprus' },
    { value: '7', label: 'Germany' },
    { value: '8', label: 'Spain' },
    { value: '9', label: 'India' },
  ];

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid  #DDE1E6 !important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "1em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#878E96",
      fontSize: "0.85em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      color: "#878E96",
      fontSize: "0.85em",
      fontWeight: "400",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#32089F!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#32089F!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#111",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  return (
    <div className="create-recipient">
      <Container>
        <div className="create-recipient-from-back">
          <Link onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              fill="none"
              viewBox="0 0 17 16"
            >
              <path
                fill="#000"
                d="M.293 7.293a1 1 0 000 1.414l6.364 6.364a1 1 0 001.414-1.414L2.414 8l5.657-5.657A1 1 0 006.657.93L.293 7.293zM16 9a1 1 0 100-2v2zM1 9h15V7H1v2z"
              ></path>
            </svg>
          </Link>
          <h2>Add Business Recipients</h2>
        </div>
        <Formik>
          <FORM className="create-recipient-from">
            <Row className="mt-3 g-3">
              <Col md={12} lg={6} xl={6}>
                <div className="recipient-from-box">
                  <div className="recipient-from-heading">
                    <h3>Personal Details</h3>
                  </div>
                  <Row >
                    <Col sm={12} lg={12} xl={6}>
                      <Form.Group controlId="formBasicEmail" className="mb-3">
                        <Form.Label>
                          First Name <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          name="middle_name"
                          placeholder="Enter First Name"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="last_name"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={12} xl={6}>
                      <Form.Group controlId="formBasicEmail" className="mb-3">
                        <Form.Label>
                          Last Name <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          name="middle_name"
                          placeholder="Enter Last Name"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="last_name"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12} lg={12} xl={6}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                          Email <span>*</span>
                        </Form.Label>
                        <Field
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder="Enter your email"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="email"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={12} xl={6}>
                      <Form.Group
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Mobile Number <span>*</span>
                        </Form.Label>
                        <PhoneInput
                          defaultCountry="IN"
                          placeholder="Enter Mobile Number"
                          value={phoneValue}
                          onChange={setPhoneValue}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="mobile"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
                <div className="recipient-from-box mt-3">
                  <div className="recipient-from-heading">
                    <h3>Bank Details</h3>
                  </div>
                  <Row>
                    <Col sm={12} lg={12} xl={12}>
                      <Form.Group controlId="formBasicEmail" className="mb-3">
                        <Form.Label>
                          Account Type <span>*</span>
                        </Form.Label>
                        <Select
                          className='fillert-drop'
                          options={AccountType}
                          isClearable
                          styles={customStyles}
                          placeholder="Select Recieveing Currency"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="first_name"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={12} xl={6}>
                      <Form.Group controlId="formBasicEmail" className="mb-3">
                        <Form.Label>
                          Account Number <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          name="middle_name"
                          placeholder="Enter Account Number"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="last_name"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={12} xl={6}>
                      <Form.Group controlId="formBasicEmail" className="mb-3">
                        <Form.Label>
                          Re-enter Account Number <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          name="middle_name"
                          placeholder="Enter Account Number"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="last_name"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={12} xl={6}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                          Bank ID <span>*</span>
                        </Form.Label>
                        <Select
                          className='fillert-drop'
                          options={BankCountry}
                          isClearable
                          styles={customStyles}

                          placeholder="Select Recieveing Currency"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="first_name"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={12} xl={6}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                          Bank Country Code <span>*</span>
                        </Form.Label>
                        <Select options={countryOption} value={country} onChange={changeHandler} />
                        <ErrorMessage
                          component={"div"}
                          name="password"
                          className="text-danger text-right"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={12} lg={6} xl={6}>
                <div className="recipient-from-box ">
                  <div className="recipient-from-heading">
                    <h3>Region Details</h3>
                  </div>
                  <Row>
                    <Col sm={12} lg={12} xl={6}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                          Recieveing Currency <span>*</span>
                        </Form.Label>
                        <Select
                          className='fillert-drop'
                          options={Currency}
                          isClearable
                          styles={customStyles}

                          placeholder="Select Recieveing Currency"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="first_name"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={12} xl={6}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                          Country <span>*</span>
                        </Form.Label>
                        <Select options={countryOption} value={country} onChange={changeHandler} />
                        <ErrorMessage
                          component={"div"}
                          name="password"
                          className="text-danger text-right"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
                <div className="recipient-from-box mt-3">
                  <div className="recipient-from-heading">
                    <h3>Address Details</h3>
                  </div>
                  <Row>
                    <Col sm={12} lg={12} xl={6}>
                      <Form.Group controlId="formBasicEmail" className="mb-3">
                        <Form.Label>
                          Address Line 1 <span>*</span>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Address Line 1"
                          className="auth-signup-adrs"
                          rows={4}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="last_name"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={12} xl={6}>
                      <Form.Group controlId="formBasicEmail" className="mb-3">
                        <Form.Label>
                          Address Line 2 <span>*</span>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Address Line 2"
                          className="auth-signup-adrs"
                          rows={4}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="last_name"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={12} xl={6}>
                      <Form.Group controlId="formBasicEmail" className="mb-3">
                        <Form.Label>
                          State <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          name="first_name"
                          placeholder="State"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="first_name"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={12} xl={6}>
                      <Form.Group controlId="formBasicEmail" className="mb-3">
                        <Form.Label>
                          City <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          name="first_name"
                          placeholder="City"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="first_name"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={12} xl={12}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                          Zip Code <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          name="first_name"
                          placeholder="Zip Code"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="first_name"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={12} lg={12} >
                <div className="recipient-from-box mt-3">
                  <div className="recipient-from-heading">
                    <h3>Business Details</h3>
                  </div>
                  <Row className="mt-3">
                    <Col sm={12} lg={12} xl={6}>
                      <Form.Group controlId="formBasicEmail" className="mb-3">
                        <Form.Label>
                          Name <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          name="first_name"
                          placeholder="Enter First Name"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="first_name"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={12} xl={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Phone <span>*</span>
                        </Form.Label>
                        <PhoneInput
                          defaultCountry="IN"
                          placeholder="Enter Mobile Number"
                          value={phoneValue}
                          onChange={setPhoneValue}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="mobile"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={12} xl={6}>
                      <Form.Group controlId="formBasicEmail" className="mb-3">
                        <Form.Label>
                          Bank Name <span>*</span>
                        </Form.Label>
                        <Select
                          className='fillert-drop'
                          options={BankCountry}
                          isClearable
                          styles={customStyles}

                          placeholder="Select Recieveing Currency"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="first_name"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={12} xl={6}>
                      <Form.Group controlId="formBasicEmail" className="mb-3">
                        <Form.Label>
                          Account Number <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          name="first_name"
                          placeholder="Enter Account Numerb"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="last_name"
                          className="text-danger"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="first_name"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={12} xl={6}>
                      <Form.Group controlId="formBasicEmail" className="mb-3">
                        <Form.Label>
                          Account Type <span>*</span>
                        </Form.Label>
                        <Select
                          className='fillert-drop'
                          options={AccountType}
                          isClearable
                          styles={customStyles}
                          placeholder="Select Recieveing Currency"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="first_name"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={12} lg={12} >
                <div className="create-recipient-btn-sec">
                  <Button
                    className="new-overlay-btn"
                    type="submit"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="default-btn"
                    type="submit"
                    onClick={() => setModalShow(true)}
                  >
                    Add Recipient
                  </Button>
                </div>
              </Col>
            </Row>
          </FORM>
        </Formik>
        <IfscCodeModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      </Container>
    </div>
  )
}

export default CreareBusinessRecipient
