import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Container
} from "react-bootstrap";
import { useTranslation } from 'react-multi-lang';
import { useDispatch, useSelector } from 'react-redux';
import BeneficiaryAccountIndex from './BeneficiaryAccountIndex';
import AddBeneficiaryAccount from './AddBeneficiaryAccount';
import TransferAmountIndex from './TransferAmountIndex';

const BeneficiaryAccountsList = (props) => {

  const t = useTranslation("beneficiary_account");
  const [modalShow, setModalShow] = useState(false);
  const [amountModalShow, setAmountModalShow] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const profile = useSelector(state => state.user.profile);
  const addBeneficiaries = useSelector(state => state.transfer.addBeneficiaries);
  const beneficiariesList = useSelector(state => state.transfer.beneficiariesList);

  useEffect(() => {
    if (!skipRender && !addBeneficiaries.loading &&
      Object.keys(addBeneficiaries.data).length > 0) {
      setModalShow(false)
    }
    setSkipRender(false);
  }, [addBeneficiaries]);

  return (
    <>
      <div className="bank-account-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="bank-account-top-sec">
                <h3>{t("heading")}</h3>
                <div className="beneficiary-account">
                  <Button
                    type="button"
                    className="default-btn accounts"
                    disabled={beneficiariesList.data.total == 0}
                    onClick={() => setAmountModalShow(true)}
                  >
                    {t("transfer_amount")}
                  </Button>
                  <Button
                    type="button"
                    className="default-btn"
                    onClick={() => setModalShow(true)}
                  >
                    {t("add_beneficiary_account")}
                  </Button>
                </div>
              </div>
              {profile.data.kyc_verified ?
                <div className="bank-account-list-box">
                  <BeneficiaryAccountIndex />
                </div>
                :
                null
              }
            </Col>
          </Row>
        </Container>
      </div>
      {modalShow ?
        <AddBeneficiaryAccount
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
        :
        null}
      {amountModalShow ?
        <TransferAmountIndex
          show={amountModalShow}
          onHide={() => setAmountModalShow(false)}
        />
        :
        null
      }
    </>
  );
};


export default BeneficiaryAccountsList;