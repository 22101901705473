import React, { useEffect, useState } from 'react';
import { Form, Button, Modal } from "react-bootstrap";
import '../Wallet/Wallet.css';
import { useTranslation } from "react-multi-lang";
import useLogout from '../layouts/Header/useLogout';

const LogoutModal = (props) => {

  const t = useTranslation("profile");
  const { logout } = useLogout();

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="new-modal-custome"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4> {t("are_you_sure_you_want_to_loout")}</h4>
          <div className="new-action-frame layout-element">
            <Button className="new-overlay-btn"
              onClick={props.onHide}
            >{t("cancel")}
            </Button>
            <Button className="new-primary-btn"
              type="submit"
             onClick={logout}
            >
              {t("logout")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
};

export default LogoutModal;
