import React, { useState, useEffect } from "react";
import { Image, Form, Container, Row, Col, Tab, Nav, Button } from "react-bootstrap";
import "./Exchange.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
const BuyCard = (props) => {
const t = useTranslation("buy_card")

    return (
        <>
            <div className="buy-crypto-sec">
                <Row className="justify-content-md-center">
                    <Col md={5}>
                        <div className="exchange-box">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="buy-crypto">
                                <Nav variant="pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="buy-crypto">{t("buy")}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="swap-crypto">{t("swap")}</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Tab.Container>
                            <Form className="exchange-form">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>{t("cardholder")}</Form.Label>
                                    <Form.Control type="text" placeholder="Name Heres" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>{t("card_number")}</Form.Label>
                                    <Form.Control type="number" placeholder="0000-0000-0000-0000" />
                                </Form.Group>
                                <div className="error-card">
                                    <Image
                                        src={window.location.origin + "/img/warning-icon.svg"}
                                        className="exchange-warning-icon"
                                    />
                                    <span>Please Enter a valid Card Number</span>
                                </div>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>{t("expiry_date")}</Form.Label>
                                            <Form.Control type="text" placeholder="MM/YY" />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>{t("cvv")}</Form.Label>
                                            <Form.Control type="number" placeholder="000" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div className="exchange-btn-sec">
                                    <Button className="default-btn" onClick={() => props.setStep(4)}>
                                      {t("continue")}
                                    </Button>
                                    <p>  {t("by_continuing_you_agree_to_our")} <Link to="#">{t("cookie")}</Link></p>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>  
            </div>
        </>
    );
};

export default BuyCard;
