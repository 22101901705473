import React, { useState } from "react";
// import FooterIndex from "./Footer/FooterIndex";
import HeaderIndex from "./Header/HeaderIndex";
import NewFooterIndex from "../NewLanding/NewFooterIndex";

const MainLayout = (props) => {

  return (
    <>
      <HeaderIndex />
      <div className="main-wrapper">
        {props.children}
      </div>
      {/* <FooterIndex/> */}
      <div className="auth-footer">
        <NewFooterIndex/>
      </div>
    </>
  );
};

export default MainLayout;
