import React, { useState, useMemo, useRef, useEffect } from "react";
import { Form, Button, Image, Row, Col, Container } from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useNavigate, Link } from "react-router-dom";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { useTranslation } from "react-multi-lang";
import * as Yup from "yup";
import { select } from "redux-saga/effects";
import IfscCodeModal from "./IFSCModal";
import { Checkbox, Radio, Switch } from "pretty-checkbox-react";
import "@djthoms/pretty-checkbox";
import {
  bankListStart,
  createRecipientStart,
} from "../../store/actions/RecipientAction";
import { useDispatch, useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import DatePicker from "react-multi-date-picker";
import { countriesData } from "./CountriesList";
import { bankCountry } from "./BankCountryList";
import { ButtonLoader } from "../helper/Loader";

const CreatePersonalRecipient = () => {
  const t = useTranslation("recipient");
  const dispatch = useDispatch();
  const bankList = useSelector((state) => state.recipient.bankList);
  const createRecipient = useSelector(
    (state) => state.recipient.createRecipient
  );
  const formRef = useRef();
  const [isDefault, setIsDefault] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [selectedBankCountry, setSelectedBankCountry] = useState(null);
  const [selectedRecivingCurrency, setSelectedRecivingCurrency] =
    useState(null);
  const [selectedRecivingCountry, setSelectedRecivingCountry] = useState(null);
  const [currencyList, setCurrencyList] = useState(null);
  const [skipRender, setSkipRender] = useState(true);
  const [banks, setBanks] = useState([]);
  const [countryCodeList, setCountryCodeList] = useState(null);
  const navigate = useNavigate();
  const AccountType = [
    { value: "current", label: "Current Account" },
    { value: "savings", label: "Savings Account" },
  ];
  const [selectedDate, setSelectedDate] = useState(null);

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid  #DDE1E6 !important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "1em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#878E96",
      fontSize: "0.85em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      color: "#878E96",
      fontSize: "0.85em",
      fontWeight: "400",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#32089F!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#32089F!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#111",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(t("required")),
    last_name: Yup.string().required(t("required")),
    receiving_currency: Yup.string().required(t("required")),
    recipient_country_code: Yup.string().required(t("required")),
    email: Yup.string().email(t("email.invalid")).required(t("required")),
    state: Yup.string().required(t("required")),
    city: Yup.string().required(t("required")),
    iban: Yup.string().when("receiving_currency", {
      is: (value) => value == "EUR" || value == "GBP",
      then: Yup.string().required(t("required")),
      otherwise: Yup.string(),
    }),
    ifsc_code: Yup.string().when("receiving_currency", {
      is: (value) => value !== "EUR" && value !== "GBP",
      then: Yup.string().required(t("required")),
      otherwise: Yup.string(),
    }),
    address_line_1: Yup.string().required(t("required")),
    address_line_2: Yup.string().required(t("required")),
    bank_id: Yup.string().required(t("required")),
    account_number: Yup.string()
      .required(t("required"))
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("account.invalid")),
    account_confirmation: Yup.string()
      .required(t(".required"))
      .oneOf([Yup.ref("account_number")], t("account.invalid")),
    account_type: Yup.string().required(t("required")),
    mobile: Yup.string()
      .test("valid-mobile", t("mobile.invalid"), (value) => {
        if (!value) {
          return false;
        }
        return isValidPhoneNumber(value);
      })
      .required(t("required"))
      .matches(/^(?=.*[0-9])/, t("mobile_number.space_values_not_valid")),
    dob: Yup.string().when("receiving_currency", {
      is: "BDT",
      then: Yup.string()
        .required(t("required"))
        .matches(/^\d{4}-\d{2}-\d{2}$/, t("dob.invalid_date_format"))
        .test("is-past-date", "date cannot be future", (value) => {
          const dob = new Date(value);
          const currentDate = new Date();
          return dob <= currentDate;
        })
        .test("is-18-years-old", "must be 18 years old", (value) => {
          const dob = new Date(value);
          const currentDate = new Date();
          const age = currentDate.getFullYear() - dob.getFullYear();
          const monthDiff = currentDate.getMonth() - dob.getMonth();
          return (
            age > 18 ||
            (age === 18 && monthDiff > 0) ||
            (age === 18 &&
              monthDiff === 0 &&
              currentDate.getDate() >= dob.getDate())
          );
        }),
    }),
    postal_code: Yup.string()
      .required(t("required"))
      .test("valid-zipcode", "Six digit only allowed", (value) => {
        return value && value.length == 6 ? true : false;
      }),
  });
  const validateMobileNumber = (value) => {
    if (value) {
      if (isPossiblePhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile_number.invalid"));
        return false;
      } else if (isValidPhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile_number.invalid"));
        return false;
      } else {
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", t("mobile_number.required"));
      return false;
    }
  };

  const handleSubmit = (values) => {
    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");
      dispatch(
        createRecipientStart({
          ...values,
          mobile: mobile,
          mobile_country_code: countryCode,
        })
      );
    }
  };

  useEffect(() => {
    setCountryCodeList(
      countriesData.map((country) => ({
        label: `${country.name} (${country.code})`,
        value: country.code,
      }))
    );
    setCurrencyList(
      countriesData.map((data) => ({
        label: data.currency,
        value: data.currency,
      }))
    );
  }, []);

  useEffect(() => {
    dispatch(bankListStart({}));
  }, []);

  const getCountriesByCurrency = (data) => {
    if (data?.value == "EUR" || data?.value == "GBP") {
      const { ifsc_code, dob, ...rest } = formRef.current.values;
      formRef.current.setValues({ ...rest, iban: "" });
    } else if (data?.value == "BDT") {
      formRef.current.setValues({ ...formRef.current.values, dob: "" });
    } else {
      const { iban, dob, ...rest } = formRef.current.values;
      formRef.current.setValues({ ...rest, ifsc_code: "" });
    }

    if (data) {
      formRef.current.setFieldValue("receiving_currency", data.value);
      setSelectedRecivingCurrency(data);
      setCountryCodeList(
        countriesData
          .filter((country) => country.currency == data.value)
          .map((country) => ({
            label: `${country.name} (${country.code})`,
            value: country.code,
          }))
      );
    } else {
      setCountryCodeList(
        countriesData.map((country) => ({
          label: `${country.name} (${country.code})`,
          value: country.code,
        }))
      );
      formRef.current.setFieldValue("receiving_currency", "");
      setSelectedRecivingCurrency(null);
    }
  };

  const getCurrenciesByCountry = (data) => {
    if (data) {
      formRef.current.setFieldValue("recipient_country_code", data.value);
      setSelectedRecivingCountry(data);
      setCurrencyList(
        countriesData
          .filter((country) => country.code == data.value)
          .map((data) => ({
            label: data.currency,
            value: data.currency,
          }))
      );
    } else {
      formRef.current.setFieldValue("recipient_country_code", "");
      setSelectedRecivingCountry(null);
      setCurrencyList(
        countriesData.map((data) => ({
          label: data.currency,
          value: data.currency,
        }))
      );
    }
  };

  useEffect(() => {
    if (
      Object.keys(bankList.data).length > 0 &&
      !bankList.loading &&
      bankList.data.total_banks > 0
    ) {
      setBanks(
        bankList.data.banks.map((bank) => ({
          label: bank.bank_name,
          value: bank.id,
        }))
      );
    }
  }, [bankList]);

  const handleDateChange = (values) => {
    formRef.current.setFieldValue(
      "dob",
      values !== null ? JSON.stringify(new Date(values)).slice(1, 11) : ""
    );
    setSelectedDate(values);
  };

  useEffect(() => {
    if (
      !skipRender &&
      Object.keys(createRecipient.data).length > 0 &&
      !createRecipient.loading
    ) {
      navigate("/recipient-list");
    }
    setSkipRender(false);
  }, [createRecipient]);

  const handleBankListChange = (data) => {
    setSelectedBankCountry(data);
    data !== null &&
      setBanks(
        bankList.data.banks
          .filter((bank) => bank?.country == data.value)
          .map((value) => ({
            label: value.bank_name,
            value: value.id,
          }))
      );
  };

  return (
    <div className="create-recipient">
      <Container>
        <div className="create-recipient-from-back">
          <Link onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              fill="none"
              viewBox="0 0 17 16"
            >
              <path
                fill="#000"
                d="M.293 7.293a1 1 0 000 1.414l6.364 6.364a1 1 0 001.414-1.414L2.414 8l5.657-5.657A1 1 0 006.657.93L.293 7.293zM16 9a1 1 0 100-2v2zM1 9h15V7H1v2z"
              ></path>
            </svg>
          </Link>
          <h2>{t("heading")}</h2>
        </div>
        <Formik
          initialValues={{
            first_name: "",
            last_name: "",
            middle_name: "",
            email: "",
            mobile: "",
            account_number: "",
            account_type: "",
            account_confirmation: "",
            receiving_currency: "",
            recipient_country_code: "",
            address_line_1: "",
            address_line_2: "",
            city: "",
            state: "",
            postal_code: "",
            ifsc_code: "",
            dob: "",
            bank_id: "",
            is_default: 0,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          innerRef={formRef}
        >
          {({ errors, touched, values, setFieldValue, setValues }) => (
            <FORM className="create-recipient-from">
              <Row className="mt-3 g-3">
                <Col md={12} lg={6} xl={6}>
                  <div className="recipient-from-box">
                    <div className="recipient-from-heading">
                      <h3>{t("personal_details")}</h3>
                    </div>
                    <Row>
                      <Col sm={12} lg={12} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            {t("first_name.name")} <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="first_name"
                            placeholder={t("first_name.placeholder")}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="first_name"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={12} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>{t("middle_name.name")}</Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="middle_name"
                            placeholder={t("middle_name.placeholder")}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={12} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            {t("last_name.name")} <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="last_name"
                            placeholder={t("last_name.placeholder")}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="last_name"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12} lg={12} xl={6}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>
                            {t("email.name")} <span>*</span>
                          </Form.Label>
                          <Field
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder={t("email.placeholder")}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="email"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={12} xl={6}>
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label>
                            {t("mobile.name")} <span>*</span>
                          </Form.Label>
                          <PhoneInput
                            defaultCountry="IN"
                            placeholder={t("mobile_number.placeholder")}
                            value={values.mobile}
                            onChange={(value) => setFieldValue("mobile", value)}
                            onBlur={() => formRef.current.submitForm()}
                            international
                            countryCallingCodeEditable={false}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="mobile"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  <div className="recipient-from-box mt-3">
                    <div className="recipient-from-heading">
                      <h3>{t("bank_details")}</h3>
                    </div>

                    <Row>
                      <Col sm={12} lg={12} xl={6}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            {t("account_type.name")} <span>*</span>
                          </Form.Label>
                          <Select
                            value={selectedAccountType}
                            className="fillert-drop"
                            options={AccountType}
                            onChange={(account) => {
                              {
                                setSelectedAccountType(account);
                                account !== null &&
                                  setFieldValue("account_type", account.value);
                              }
                            }}
                            isClearable
                            styles={customStyles}
                            placeholder={t("account_type.placeholder")}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="account_type"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={12} xl={6}>
                        {selectedRecivingCurrency?.value == "EUR" ||
                        selectedRecivingCurrency?.value == "GBR" ? (
                          <Form.Group
                            controlId="formBasicEmail"
                            className="mb-3"
                          >
                            <Form.Label>
                              {t("iban_code.name")} <span>*</span>
                            </Form.Label>
                            <Field
                              type="text"
                              className="form-control"
                              name="iban"
                              placeholder={t("iban_code.placeholder")}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="iban"
                              className="text-danger"
                            />
                          </Form.Group>
                        ) : (
                          <Form.Group
                            controlId="formBasicEmail"
                            className="mb-3"
                          >
                            <Form.Label>
                              {t("ifsc_code.name")} <span>*</span>
                            </Form.Label>
                            <Field
                              type="text"
                              className="form-control"
                              name="ifsc_code"
                              placeholder={t("ifsc_code.placeholder")}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="ifsc_code"
                              className="text-danger"
                            />
                          </Form.Group>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} lg={12} xl={6}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            {t("account_number.name")} <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="account_number"
                            placeholder={t("account_number.placeholder")}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="account_number"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={12} xl={6}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            {t("re_account_number.name")} <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="account_confirmation"
                            placeholder={t("re_account_number.placeholder")}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="account_confirmation"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={12} xl={6}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>
                            {t("bank_country")} <span>*</span>
                          </Form.Label>
                          <Select
                            options={bankCountry}
                            value={selectedBankCountry}
                            onChange={(data) => {
                              {
                                handleBankListChange(data);
                              }
                            }}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="password"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={12} xl={6}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>
                            {t("bank_name.name")} <span>*</span>
                          </Form.Label>
                          {/* <AsyncSelect
                            className="fillert-drop"
                            cacheOptions
                            loadOptions={(data) => {
                              setSearch(data);
                            }}
                            defaultOptions
                            isClearable
                            styles={customStyles}
                            placeholder={
                              bankList.loading
                                ? "Loading..."
                                : "Select Receiving Currency"
                            }
                            onChange={(data) => {
                              if (data !== null) {
                                setFieldValue("bank_id", data.value);
                              }
                            }}
                          /> */}
                          <Select
                            className="fillert-drop"
                            options={banks}
                            isClearable
                            styles={customStyles}
                            placeholder={t("bank_name.placeholder")}
                            onChange={(data) => {
                              {
                                data !== null &&
                                  setFieldValue("bank_id", data.value);
                              }
                            }}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="bank_id"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={12} lg={6} xl={6}>
                  <div className="recipient-from-box ">
                    <div className="recipient-from-heading">
                      <h3>{t("region_details")}</h3>
                    </div>
                    <Row>
                      <Col sm={12} lg={12} xl={6}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            {t("country_code")} <span>*</span>
                          </Form.Label>
                          <Select
                            options={countryCodeList}
                            value={selectedRecivingCountry}
                            isClearable
                            onChange={(data) => {
                              getCurrenciesByCountry(data);
                            }}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="recipient_country_code"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={12} xl={6}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            {t("receiving_currency.name")} <span>*</span>
                          </Form.Label>
                          <Select
                            className="fillert-drop"
                            value={selectedRecivingCurrency}
                            options={currencyList}
                            isClearable
                            styles={customStyles}
                            onChange={(data) => {
                              getCountriesByCurrency(data);
                            }}
                            placeholder={t("receiving_currency.placeholder")}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="receiving_currency"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      {selectedRecivingCurrency?.value == "BDT" && (
                        <Col sm={12} lg={12} xl={12}>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label className="w-100">
                              {t("dob.name")}
                              <span>*</span>
                            </Form.Label>
                            <DatePicker
                              value={selectedDate}
                              maxDate={
                                new Date(new Date().setHours(23, 59, 59, 999))
                              }
                              onChange={handleDateChange}
                              multiple={false}
                              placeholder={t("dob.placeholder")}
                              className="w-100"
                            />
                            <ErrorMessage
                              component={"div"}
                              name="dob"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                      )}
                    </Row>
                  </div>

                  <div className="recipient-from-box mt-3">
                    <div className="recipient-from-heading">
                      <h3>{t("address_details")}</h3>
                    </div>
                    <Row>
                      <Col sm={12} lg={12} xl={6}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            {t("address_line.name")} 1 <span>*</span>
                          </Form.Label>
                          <Field
                            name="address_line_1"
                            type="text"
                            placeholder={`${t("address_line.placeholder")} 1`}
                            className="auth-signup-adrs form-control"
                            rows={3}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="address_line_1"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={12} xl={6}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            {t("address_line.name")} 2 <span>*</span>
                          </Form.Label>
                          <Field
                            name="address_line_2"
                            type="text"
                            placeholder={`${t("address_line.placeholder")} 2`}
                            className="auth-signup-adrs form-control"
                            rows={3}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="address_line_2"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={12} xl={6}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            {t("state.name")} <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder={t("state.placeholder")}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={12} xl={6}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            {t("city.name")} <span>*</span>
                          </Form.Label>
                          <Field
                            name="city"
                            type="text"
                            className="form-control"
                            placeholder={t("city.placeholder")}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="city"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={12} xl={12}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>
                            {t("zip_code.name")} <span>*</span>
                          </Form.Label>
                          <Field
                            type="number"
                            className="form-control"
                            name="postal_code"
                            placeholder={t("zip_code.placeholder")}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="postal_code"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  <Col lg={12}>
                    <div className="mt-3">
                      <Checkbox
                        onClick={() => {
                          setIsDefault(isDefault == 1 ? 0 : 1);
                          setFieldValue("is_default", isDefault == 1 ? 0 : 1);
                        }}
                        checked={isDefault == 1 ? true : false}
                      >
                        {t("set_as_default_account")}
                      </Checkbox>
                    </div>
                  </Col>
                </Col>
                <Col md={12} lg={12}>
                  <div className="create-recipient-btn-sec">
                    <Button
                      className="new-overlay-btn"
                      onClick={() => navigate("/recipient-list")}
                    >
                      {t("cancel")}
                    </Button>
                    <Button className="auth-step-btn" type="submit">
                      {createRecipient.loading ? <ButtonLoader /> : t("create")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </FORM>
          )}
        </Formik>
        {/* <IfscCodeModal show={modalShow} onHide={() => setModalShow(false)} /> */}
      </Container>
    </div>
  );
};

export default CreatePersonalRecipient;
