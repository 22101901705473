import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import CustomLazyLoad from "../helper/CustomLazyLoad";

const OurFeatures = (props) => {

  const t = useTranslation("our_features");

  return (
    <>
      <div className="our-feature-sec sm-padding">
        <Container>
          <Row>
            <Col md={6} xl={4} lg={4} className="resp-marg-btm-xs">
              <div className="our-feature-card" data-aos="fade-up">
                <div className="our-feature-icon-sec">
                  <CustomLazyLoad
                    className="our-feature-icon"
                    src={
                      window.location.origin + "/img/landing/our-features/unbeatable-pricing-structure.svg"
                    }
                    type="image/png"
                  />
                </div>
                <div className="our-feature-info-sec">
                  <h4>{t("unbeatable_pricing_structure")}</h4>
                  <p>{t("maximize_your_savings")}</p>
                </div>
              </div>
            </Col>
            <Col md={6} xl={4} lg={4} className="resp-marg-btm-xs">
              <div className="our-feature-card" data-aos="fade-up">
                <div className="our-feature-icon-sec">
                  <CustomLazyLoad
                    className="our-feature-icon"
                    src={
                      window.location.origin + "/img/landing/our-features/dedicated-client.svg"
                    }
                    type="image/png"
                  />
                </div>
                <div className="our-feature-info-sec">
                  <h4>{t("dedicated_client_advisor")}</h4>
                  <p>{t("unlock_exclusive_support_para")}</p>
                </div>
              </div>
            </Col>
            <Col md={6} xl={4} lg={4}>
              <div className="our-feature-card" data-aos="fade-up">
                <div className="our-feature-icon-sec">
                  <CustomLazyLoad
                    className="our-feature-icon"
                    src={
                      window.location.origin + "/img/landing/our-features/fund-transfer.svg"
                    }
                    type="image/png"
                  />
                </div>
                <div className="our-feature-info-sec">
                  <h4>{t("immediate_fund_transfers")}</h4>
                  <p>{t("enjoy_the_convenience_para")}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default OurFeatures;
