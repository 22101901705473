import React, { useState, useEffect } from "react";
import {
  Image,
  Row,
  Col,
  Tab,
  Nav,
  Button,
  Form,
  Container,
} from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { stylopayPaymentStart } from '../store/actions/UserAction';
import { useTranslation } from "react-multi-lang";

const CardIndex = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation("card_index");
  const buyData = useSelector(state => state.user.buyData);
  const stylopayPayment = useSelector(state => state.user.stylopayPayment);

  const validationSchema = Yup.object().shape({
    card_number: Yup.string().required(t("card_no.required"))
      .matches(/^[0-9]{16}$/, t("card_no.invalid")),
    cvv: Yup.string().required(t("cvv.required"))
      .matches(/^[0-9]{3}$/, t("cvv.invalid")),
    expiry_month: Yup.string()
      .required(t("expire_month.required"))
      .test('valid-month', 'Invalid month', (value) => {
        const intValue = parseInt(value, 10);
        return intValue >= 1 && intValue <= 12;
      })
      .transform((value) => (value.length === 1 ? `0${value}` : value)),
    expiry_year: Yup.number()
      .required(t("expire_year.required"))
      .min(new Date().getFullYear() % 100, t("expire_year.invalid"))
      .max(99, t("expire_year.year_must_be_two_digits"))
      .integer(t("expire_year.year_must_be_a_number")),
  });

  const stylepayPaymentSubmit = (values) => {
    console.log(values, buyData)
    dispatch(stylopayPaymentStart({
      ...values,
      ...buyData,
      type: 'buy',
      tokens: buyData.token,
      token_type: buyData.token_type.symbol,
    }));
  };

  useEffect(() => {
    if (!stylopayPayment.loading && Object.keys(stylopayPayment.data).length > 0) {
      window.location.replace(stylopayPayment.data.redirect_url)
    }
  }, [stylopayPayment])

  return (
    <>
      <Formik
        initialValues={{
          card_number: "",
          cvv: "",
          expiry_month: "",
          expiry_year: "",
        }}
        validationSchema={validationSchema}
        onSubmit={stylepayPaymentSubmit}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <FORM className="exchange-form">
            <div className="exchange-box-1">
              <h2>{t("heading")}</h2>
              <Form.Group className="mb-3" controlId="formCardNumber">
                <Form.Label>{t("card_no.label")}</Form.Label>
                <Field
                  type="number"
                  className="form-control"
                  name="card_number"
                  placeholder={t("card_no.placeholder")}
                />
                <ErrorMessage
                  component={"div"}
                  name="card_number"
                  className="text-danger"
                />
              </Form.Group>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formExpiryMonth">
                    <Form.Label>{t("expire_month.label")}</Form.Label>
                    <Field
                      type="number"
                      className="form-control"
                      name="expiry_month"
                      placeholder={t("expire_month.placeholder")}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length === 1 && value !== '0' && value !== '1') {
                          setFieldValue('expiry_month', `0${value}`);
                        } else {
                          setFieldValue('expiry_month', value);
                        }
                      }}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="expiry_month"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formExpiryYear">
                    <Form.Label>{t("expire_year.label")}</Form.Label>
                    <Field
                      type="number"
                      className="form-control"
                      name="expiry_year"
                      placeholder={t("expire_year.placeholder")}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="expiry_year"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-3" controlId="formCVV">
                <Form.Label>{t("cvv.label")}</Form.Label>
                <Field
                  type="text"
                  className="form-control"
                  name="cvv"
                  placeholder={t("cvv.placeholder")}
                />
                <ErrorMessage
                  component={"div"}
                  name="cvv"
                  className="text-danger"
                />
              </Form.Group>
              <div className="exchange-btn-sec">
                <Button
                  className="default-btn w-100"
                  type="submit"
                  disabled={stylopayPayment.buttonDisable}
                >
                  {stylopayPayment.buttonDisable
                    ?
                    t("card_btn.loading")
                    : t("card_btn.text")}
                </Button>
              </div>
            </div>
          </FORM>
        )}
      </Formik>
    </>
  );
};

export default CardIndex;
