import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "../Wallet/Wallet.css";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  cryptoWithdrawalRequestStart,
  withdrawalRequestStart,
} from "../../store/actions/BuyAction";
import configuration, { set } from "react-global-configuration";
import { useTranslation } from "react-multi-lang";
import { fetchSingleWalletViewStart } from "../../store/actions/WalletAction";
import { useParams } from "react-router-dom";

const WithdrawCryptoModal = (props) => {
  const t = useTranslation("withdraw_modal");
  const dispatch = useDispatch();
  const params = useParams();
  const cryptoWithdrawalRequest = useSelector(
    (state) => state.buy.cryptoWithdrawalRequest
  );
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    wallet_address: Yup.string().required(t("wallet_address.required")),
    amount: Yup.number()
      .required(t("amount.required"))
      .min(0.0000001, t("amount.invalid")),
  });

  const handleSubmit = (values) => {
    dispatch(
      cryptoWithdrawalRequestStart({
        ...values,
        type: 1,
        user_wallet_id: props.user_wallet_id,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !cryptoWithdrawalRequest.loading &&
      Object.keys(cryptoWithdrawalRequest.data).length > 0
    ) {
      dispatch(
        fetchSingleWalletViewStart({
          user_wallet_id: params.user_wallet_id,
        })
      );

      props.onHide();
    }
    setSkipRender(false);
  }, [cryptoWithdrawalRequest]);

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="new-modal-custome"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4> {t("heading")}</h4>
          <Formik
            initialValues={{
              wallet_address: "",
              amount: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ touched, errors, setFieldValue, values }) => (
              <FORM className="new-feature-form">
                <div className="auth-form-box">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("wallet_address.label")}</Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("wallet_address.placeholder")}
                      name="wallet_address"
                    />
                    <ErrorMessage
                      component={"div"}
                      name="wallet_address"
                      className="text-danger text-right"
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail" className="mb-3">
                    <Form.Label>
                      {t("amount.label")} <span>*</span>
                    </Form.Label>
                    <Field
                      type="number"
                      name="amount"
                      placeholder={t("amount.placeholder")}
                      className="form-control"
                    />
                    <ErrorMessage
                      component={"div"}
                      name="amount"
                      className="text-danger text-right"
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail" className="mb-3">
                    <Form.Label>{t("service_fees")}</Form.Label>
                    <Field
                      className="form-control"
                      type="text"
                      name="withdraw_commission"
                      value={
                        values.amount *
                        (configuration.get("configData.withdraw_commission") /
                          100)
                      }
                      disabled
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail" className="mb-3">
                    <Form.Label>{t("user_receive_tokens")}</Form.Label>
                    <Field
                      className="form-control"
                      type="text"
                      name="withdraw_commission"
                      value={
                        values.amount -
                        values.amount *
                          (configuration.get("configData.withdraw_commission") /
                            100)
                      }
                      disabled
                    />
                  </Form.Group>
                </div>
                <div className="new-action-frame layout-element">
                  <Button
                    className="new-overlay-btn"
                    onClick={props.onHide}
                    disabled={cryptoWithdrawalRequest.buttonDisable}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    type="submit"
                    className="new-primary-btn"
                    disabled={cryptoWithdrawalRequest.buttonDisable}
                  >
                    {cryptoWithdrawalRequest.buttonDisable
                      ? t("loading")
                      : t("withdraw")}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WithdrawCryptoModal;
